import React, { useEffect } from 'react'
import { ScrollToTopOnMount} from './utils';
import { animateScroll } from 'react-scroll';

export default ({ignoreScrollChange}) => {
    useEffect(() => {
        ignoreScrollChange();
        animateScroll.scrollToTop({
            duration: 700,
            delay: 0,
            smooth: true,
        });
    }, [ignoreScrollChange]);

    return (
        <div className="login roboto-font photographer-container">
            <ScrollToTopOnMount />

            Terms of Service
        </div>
    );
};