import { createStore, compose, applyMiddleware } from 'redux';
import { rootReducer } from './reducers/root';
import thunk from 'redux-thunk';

let store;
const storeEnhancers = /*window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ||*/ compose;

export default () => {
    if (store)
        return store;

    return createStore(
        rootReducer,
        {},
        storeEnhancers(applyMiddleware(thunk))
    );
}