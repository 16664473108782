import * as actions from '../actions/constants';

const initialState = {
    photosCount: {value: 0},
    photos: {
        available: false,
        error: null,
        data: [],
    },
};

export const shopReducer = (state = initialState, action) => {
    if (action.type === actions.shop.GET_PHOTOS_COUNT) {
        let newState = {...state};
        newState.photosCount = {value: action.payload};
        return newState;
    }

    if (action.type === actions.shop.GET_PHOTOS) {
        let newState = {...state};
        newState.photos.available = false;
        newState.photos.data = [];
        newState.photos.error = null;
        
        return newState;
    }

    if (action.type === actions.shop.GET_PHOTOS_SUCCESS) {
        let newState = {...state};
        newState.photos.available = true;
        newState.photos.data = action.payload;
        newState.photos.error = null;

        return newState;
    }

    if (action.type === actions.shop.GET_PHOTOS_FAILED) {
        let newState = {...state};
        newState.photos.available = false;
        newState.photos.data = [];
        newState.photos.error = action.payload;
        
        return newState;
    }

    return state;
};