/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, Component } from 'react'
import { useParams, Switch, Route, Redirect, useLocation, useHistory } from 'react-router'
import { Link, HashRouter, NavLink } from 'react-router-dom';
import { ScrollToTopOnMount, useQuery, generatePagination, 
    axiosInstance, showError, showLoading, showNetworkError, showSubscriptionError,
    dismissModal, 
    showSuccess,
    ImageGridCol,
    ImageGridRow,
    Loading,
    ReactSwal} from './utils';
import $ from 'jquery';
import Swal from 'sweetalert2';
import * as creators from '../redux/actions/creators';
import { connect } from 'react-redux';
import * as constants from '../redux/actions/constants';
import { CSSTransition } from 'react-transition-group';
import * as utils from './utils';
import { animateScroll } from 'react-scroll';
import ReCAPTCHA from "react-google-recaptcha";
import passwordChecker from 'zxcvbn';

const Authorisation = {
    authenticate(onRespond, history, clearDashboardDispatcher) {
        onRespond(false);

      // Check if there is an existing session
      if (sessionStorage.getItem('photographer_data')) {
        // Validate the session data
        
        const session_data = JSON.parse(sessionStorage.getItem('photographer_data'));

        const axios = axiosInstance();
        axios.post('photographer/session', session_data)
        .then((response) => {
            if (response.status === 200 || response.status === 204) {
                // The session is still valid
                onRespond(true);
            }
            else {
                // Invalid session
                // Clear any session data related to auth
                dismissModal();
                sessionStorage.removeItem('auth');
                clearDashboardDispatcher();
                history.push('/photographer/login');
            }
        })
        .catch((error) => {
            // Clear any session data related to auth
            sessionStorage.removeItem('auth');
            clearDashboardDispatcher();

            Swal.fire({
                title: 'Authentication Error!',
                text: 'We could not validate your current session, please login again',
                icon: 'error',
                confirmButtonText: 'Login',
                timer: 2000,
                onAfterClose: () => {
                    history.push('/photographer/login');
                }
            });
        });
    }
    else {
        // If there is no current valid session, redirect to login page
        // Clear any session data related to auth
        dismissModal();
        sessionStorage.removeItem('auth');
        clearDashboardDispatcher();
        history.push('/photographer/login');
    }
    },
    logout() {
      // Clear the current session
      sessionStorage.removeItem('photographer_data');

      // Clear any session data related to auth
      sessionStorage.removeItem('auth');

      // Make a network request to clear the current sesssion
      const axios = axiosInstance();
      axios.delete('photographer/session');
    }
};

export const Register = ({ignoreScrollChange}) => {
    useEffect(() => ignoreScrollChange(), [ignoreScrollChange]);

    const params = useParams();
    const [plan, setPlan] = useState(params.plan);
    const [avatarFile, setAvatarFile] = useState(null);
    const [tocChecked, setTocChecked] = useState(false);
    const [recaptchaDone, setRecaptchaDone] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState(0);
    const [passwordFeedback, setPasswordFeedback] = useState('Very weak password!');
    const [passwordStrengthColor, setPasswordStrengthColor] = useState('bg-danger');

    const history = useHistory();

    const [data, setData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        username: '',
        bio: '',
        password: '',
    });

    const handlePlanChanged = e => {
        setPlan(e.target.value);
    };

    const handleInputChanged = e => {
        const name = e.target.name;
        const value = e.target.value;
        data[name] = value;
        setData({...data});

        if (name === 'password') {
            const score = passwordChecker(value).score;
            const progress = Math.round(score / 4 * 100);
            setPasswordStrength(progress);

            if (progress < 20) {
                setPasswordFeedback('Very weak password!');
                setPasswordStrengthColor('bg-danger');
            }
            else if (progress < 50) {
                setPasswordFeedback('Weak password!');
                setPasswordStrengthColor('bg-danger');
            }
            else if (progress < 80) {
                setPasswordFeedback('Good password!');
                setPasswordStrengthColor('bg-warning');
            }
            else if (progress < 90) {
                setPasswordFeedback('Strong password!');
                setPasswordStrengthColor('bg-success');
            }
            else {
                setPasswordFeedback('Very Strong password!');
                setPasswordStrengthColor('bg-success');
            }
        }
    };

    const handleFileChanged = e => {
        setAvatarFile(e.target.files[0]);
    };

    const registerPhotographer = (axios, reference = null) => {
        showLoading();
        
        const formData = new FormData();

        formData.append('profile_image', avatarFile)
        formData.append('first_name', data.first_name);
        formData.append('last_name', data.last_name);
        formData.append('email', data.email);
        formData.append('phone', data.phone);
        formData.append('username', data.username);
        formData.append('bio', data.bio);
        formData.append('plan', plan);
        formData.append('password', data.password);

        if (reference)
            formData.append('reference', reference);

        axios.post('photographer/add', formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
        .then((response) => {
            if (response.status === 200) {
                sessionStorage.removeItem('photographer_sub_ref');

                ReactSwal.fire({
                    title: 'Account Created!',
                    text: 'Your account was created successfully. Please check your mail box to activate your account!',
                    icon: 'success',
                    confirmButtonText: 'Log In',
                    onAfterClose: () => history.push('/photographer/login'),
                });
            }
            else if (response.status === 400) { //Validation Error
                ReactSwal.fire({
                    title: 'Validation Error!',
                    html: response.data.messages.error,
                    icon: 'error',
                    confirmButtonText: 'Retry'
                });
            }
            else {
                showNetworkError();
            }
        })
        .catch((error) => {
            showNetworkError();
        });
    };

    const subscribeThenRegister = (axios) => {
        axios.post('photographer/sub/init', {'plan': plan, 'email': data.email})
        .then((reponse) => {
            // eslint-disable-next-line no-undef
            let handler = PaystackPop.setup({
                ...reponse.data,
                onClose: function() {
                    // No transaction recorded because the user closed the popup
                    showSubscriptionError();
                },
                callback: (response) => {
                    // We have a response from paystack, let's verify the transaction before registering the photographer
                    // Save the reference first, in case something goes wrong, we can reuse to avoid repeated payment!
                    sessionStorage.setItem('photographer_sub_ref', response.reference);
                    axios.post('transaction/verify', {reference: response.reference})
                    .then((res) => {
                        // If we get back a positive reponse (code 200), then proceed and register the photographer
                        if (res.status === 200) {
                            registerPhotographer(axios, response.reference);
                        }
                        else {
                            showSubscriptionError();
                        }
                    })
                    .catch((error) => {
                        showSubscriptionError();
                    })
                }
            });

            handler.openIframe();
        })
        .catch((error) => {
            showNetworkError();
        });
    };

    const handleRegisteration = e => {
        e.preventDefault();

        // Make sure the TOC is accepted
        if (!tocChecked) {
            showError('', 'You have to accept the Terms of Service before you can register!');
            return;
        }

        if (!recaptchaDone) {
            showError('', 'Please complete the reCaptcha challenge!');
            return;
        }

        if (passwordStrength < 50) {
            showError('', 'Password is not strong enough!');
            return;
        }


        const axios = axiosInstance();

        if (plan === 'basic')
            registerPhotographer(axios);
        else {
            showLoading();

            // Subscribe the photographer first

            // if there is an existing reference code, let's verify it
            if (sessionStorage.getItem('photographer_sub_ref')) {
                const ref = sessionStorage.getItem('photographer_sub_ref');

                // verify it
                axios.post('transaction/verify', {reference: ref})
                .then((res) => {
                    // If we get back a positive reponse (code 200), then proceed and register the photographer
                    if (res.status === 200) {
                        registerPhotographer(axios, ref);
                    }
                    else {
                        // The reference is invalid, remove it and init subscription process
                        sessionStorage.removeItem('photographer_sub_ref');
                        subscribeThenRegister(axios);
                    }
                })
                .catch((error) => {
                    showNetworkError();
                });
            }
            else {
                subscribeThenRegister(axios);
            }
        }
    };

    const onRecaptcha = status => {
        if (status) {
            setRecaptchaDone(true);
        }
        else {
            setRecaptchaDone(false);
        }
    };

    useEffect(() => {
        // displays the name of the file selected from the file browser
        $(".custom-file-input").on("change", function()
        {
        var fileName = $(this).val().split("\\").pop();
        $(this).siblings(".custom-file-label").addClass("selected").html(fileName);
        });
    });

    return (
        <div className="roboto-font register photographer-container">
            <ScrollToTopOnMount />
            
            <div className="mb-5 login-option-box">
                <h5>Already registered?</h5>
                <Link to="/photographer/login">SIGN IN</Link>
            </div>

            <div className="container text-center shadow-lg px-0">
                <h3 className="header montserrat-font bg-dark text-white p-4 mb-0">Create Photographer Account</h3>

                <div className="form-container jumbotron bg-white">
                    <form onSubmit={handleRegisteration}>
                        <div className="input-group row mb-3">
                            <div className="col-sm mb-3 mb-md-0">
                                <input type="text" placeholder="First name" className="form-control" name="first_name" value={data.first_name} 
                                    onChange={handleInputChanged} maxLength="30" required/>
                            </div>
                            <div className="col-sm">
                                <input type="text" placeholder="Last name" className="form-control" name="last_name" value={data.last_name} 
                                    onChange={handleInputChanged} maxLength="30" required/>
                            </div>
                        </div>

                        <div className="input-group row mb-3">
                            <div className="col-sm mb-3 mb-md-0">
                                <input type="email" placeholder="Email" className="form-control" name="email" value={data.email} 
                                    onChange={handleInputChanged} maxLength="50" required/>
                            </div>
                            <div className="col-sm">
                                <input type="number" placeholder="Phone" className="form-control" name="phone" value={data.phone} 
                                    onChange={handleInputChanged} maxLength="15" required/>
                            </div>
                        </div>

                        <div className="input-group row mb-3">
                            <div className="col-sm mb-3 mb-md-0">
                                <input type="text" placeholder="Username" className="form-control" name="username" value={data.username} 
                                    onChange={handleInputChanged} maxLength="12" required/>
                            </div>
                            <div className="col-sm">
                                <input type="password" placeholder="Password" className="form-control" name="password" value={data.password} 
                                    onChange={handleInputChanged} required/>
                                {data.password ? 
                                <div className="mt-3">
                                    <div className="montserrat-font">{passwordFeedback}</div>
                                    <div className="progress mt-1">
                                        <div className={`progress-bar progress-bar-animated ${passwordStrengthColor}`}
                                            style={{width: `${passwordStrength}%`}}>
                                        </div>
                                    </div>
                                </div> : ''}
                            </div>
                        </div>

                        <div className="input-group row mb-3">
                            <div className="col-sm">
                                <textarea className="form-control" placeholder="Bio" name="bio" value={data.bio} 
                                    onChange={handleInputChanged} maxLength="2000" required/>
                            </div>
                        </div>

                        <div className="input-group row mb-5">
                            <div className="col-sm mb-3 mb-md-0">
                                <select value={plan} className="custom-select" onChange={handlePlanChanged}>
                                    <option value="basic">Basic Plan</option>
                                    <option value="silver">Silver Plan</option>
                                    <option value="gold">Gold Plan</option>
                                </select>
                            </div>
                            <div className="col-sm">
                                <div className="custom-file">
                                    <input type="file" className="custom-file-input" id="customFile" onChange={ handleFileChanged } required/>
                                    <label className="custom-file-label text-left">Choose Profile Photo</label>
                                </div>
                            </div>
                        </div>

                        <div className="input-group row mb-4 text-left">
                            <div className="col">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="toc" name="toc" defaultChecked={tocChecked}
                                        onChange={e => setTocChecked(!tocChecked)}/>
                                    <label className="custom-control-label" htmlFor="toc">
                                        <div className="montserrat-font">Accept the PhotoStock NG Terms of Service</div>
                                        <p className="roboto-font mt-2 text-secondary">
                                            By accessing or using the PhotoStock NG platform as a content provider, you agree to our <span className="text-primary montserrat-font"
                                            onClick={e => showSuccess('Terms of Service not ready!')}>Terms of Use</span>.
                                            Please read and understand all applicable terms and policies before registering an account.
                                        </p>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="input-group row mb-3 text-left">
                            <div className="col">
                                <ReCAPTCHA 
                                sitekey={utils.recaptchaKey}
                                onChange={onRecaptcha}/>
                            </div>
                        </div>

                        <div className="mt-2 text-left">
                            {recaptchaDone ? <button type="submit" className="btn btn-lg btn-dark">Register <span className="fas fa-arrow-right ml-5"></span>
                            </button> :
                            <button type="submit" className="btn btn-lg btn-dark disabled" disabled>Register <span className="fas fa-arrow-right ml-5"></span>
                            </button>}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
};

const Login = ({ignoreScrollChange, authenticateUser, updateDashboard}) => {
    const history = useHistory();
    const [recaptchaDone, setRecaptchaDone] = useState(false);

    useEffect(() => {
        ignoreScrollChange();
    }, [ignoreScrollChange]);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    
    const handleLogin = e => {
        e.preventDefault();

        if (!recaptchaDone) {
            showError('', 'Please complete the reCaptcha challenge!');
            return;
        }

        showLoading();

        const axios = axiosInstance();
        axios.post('photographer/login', {}, {
            auth: {
                username: username,
                password: password,
            }
        })
        .then((response) => {
            if (response.status === 200) {
                const data = JSON.stringify(response.data);
                sessionStorage.setItem('photographer_data', data);
                sessionStorage.removeItem('user_data');
                authenticateUser(true, '/photographer/dashboard', '/photographer/logout');
                updateDashboard(response.data);
                history.push('/photographer/dashboard');
            }
            else if (response.status === 404) { // Inactive account
                ReactSwal.fire({
                    title: 'Activation Error!',
                    text: 'Your account exist but is not activated yet. Please check your mail box for the activation link. We can resend the link if you have not seen it yet.',
                    icon: 'error',
                    confirmButtonText: 'Resend Link',
                    onAfterClose: () => history.push('/photographer/activate'),
                });
            }
            else {
                dismissModal();
                showError('Authentication Error!', 'Wrong username or password');
            }
        })
        .catch((error) => {
            dismissModal();
            showNetworkError();
        });
    };

    const onRecaptcha = status => {
        if (status) {
            setRecaptchaDone(true);
        }
        else {
            setRecaptchaDone(false);
        }
    };

    return (
        <div className="login roboto-font photographer-container">
            <ScrollToTopOnMount />

            <div className="container text-center shadow-lg px-0">
                <h3 className="header montserrat-font bg-dark text-white p-4 mb-0">Photographer Login</h3>

                <div className="form-container jumbotron bg-light">
                    <form onSubmit={handleLogin}>
                        <div className="input-group row mb-3">
                            <div className="col-sm mb-3 mb-md-0">
                                <input type="text" placeholder="Username" className="form-control" value={username} 
                                    onChange={e => setUsername(e.target.value)} maxLength="12" required/>
                            </div>
                            <div className="col-sm">
                                <input type="password" placeholder="Password" className="form-control" 
                                    onChange={e => setPassword(e.target.value)} required/>
                            </div>
                        </div>

                        <div className="input-group row mb-3 text-left">
                            <div className="col">
                                <ReCAPTCHA 
                                sitekey={utils.recaptchaKey}
                                onChange={onRecaptcha}/>
                            </div>
                        </div>

                        <div className="mt-5 text-left">
                            {recaptchaDone ? <button type="submit" className="btn btn-lg btn-dark">Login <span className="fas fa-arrow-right ml-5"></span></button> :
                            <button type="submit" className="btn btn-lg btn-dark disabled" disabled>Login <span className="fas fa-arrow-right ml-5"></span></button>}
                            <Link to="/photographer/reset_password" className="ml-3 ml-md-5">Forgot password?</Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

const Logout = ({ authenticateUser, removeDashboard }) => {
    const history = useHistory();

    useEffect(() => {
        Authorisation.logout();
        authenticateUser(false, '', '');
        removeDashboard();
        history.push('/');
    });

    return null;
};

const Dashboard = ({ignoreScrollChange, dashboard, authenticateUser, removeDashboard, getNavBarHeight}) => {
    const history = useHistory();

    const [photosCategory, setPhotosCategory] = useState('All');
    const [photosSort, setPhotosSort] = useState('ascending');
    const [authenticated, setAuthenticated] = useState(false);
    const [photographerData, setPhotographerData] = useState(null);

    const location = useLocation();

    const onPhotosUpdate = (category, sort) => {
        setPhotosCategory(category);
        setPhotosSort(sort);
    };

    const showDropMenuOnHover = () => {
        const $dropdown = $(".dropdown");
        const $dropdownToggle = $("dropdown-toggle");
        const $dropdownMenu = $(".dropdown-menu");
        const showClass = "show";
        
        if (window.matchMedia("(min-width: 768px)").matches) {
            $dropdown.hover(
                function() {
                    const $this = $(this);
                    $this.addClass(showClass);
                    $this.find($dropdownToggle).attr("aria-expanded", "true");
                    $this.find($dropdownMenu).addClass(showClass);
                },
                function() {
                    const $this = $(this);
                    $this.removeClass(showClass);
                    $this.find($dropdownToggle).attr("aria-expanded", "false");
                    $this.find($dropdownMenu).removeClass(showClass);
                }
            );
        } else {
            $dropdown.off("mouseenter mouseleave");
        }
    };

    useEffect(() => {
        ignoreScrollChange();
    }, [ignoreScrollChange]);

    useEffect(() => {
        const onAuthenticate = (authorized) => {
            if (authorized) {
                dismissModal();
                setPhotographerData(dashboard);
                authenticateUser(true, '/photographer/dashboard', '/photographer/logout');
            }
            else
                showLoading();
    
            setAuthenticated(authorized);
        };

        // Authenticate whenever there is a change in location
        Authorisation.authenticate(onAuthenticate, history, removeDashboard);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    useEffect(() => {
        setPhotographerData(dashboard);
    }, [dashboard]);

    useEffect(() => {
        const navbarHeight = getNavBarHeight();
        $('div.dashboard-wrapper').css('marginTop', `${navbarHeight}px`);

        $('#sidebar-toggle').click(() => {
            $('#sidebar').toggleClass('open');
        });

        showDropMenuOnHover();
    });
    
    return !authenticated ? '' : (
        <HashRouter>
            <div className="roboto-font">
                
                <div className="dashboard-wrapper">
                    {/* Sidebar */}
                    <nav id="sidebar">
                        <div className="header text-white container-fluid">
                            <div className="row">
                                <div className="col-4">
                                    <img src={photographerData.profile_image_url} alt="Avatar" className="img-fluid avatar" />
                                </div>

                                <div className="col pl-0">
                                    <h6 className="mb-0 name">{photographerData.first_name + ' ' + photographerData.last_name}</h6>
                                    <small className="username">@{photographerData.username}</small>
                                    <div className="plan mt-2">{photographerData.plan.toUpperCase()}  PLAN</div>
                                </div>
                            </div>
                        </div>

                        <div className="options">
                            <div className="links-container">
                                <h5 className="group-title">Portfolio</h5>
                                <div className="row link-container">
                                    <div className="col-2"><span className="fas fa-tools"></span></div>
                                    <div className="col"><NavLink to={`photos?category=${photosCategory}&sort=${photosSort}`} className="link">Manage Photos</NavLink></div>
                                </div>
                                <div className="row link-container">
                                    <div className="col-2"><span className="fas fa-plus"></span></div>
                                    <div className="col"><NavLink to="add-photos" className="link">Add Photos</NavLink></div>
                                </div>
                            </div>

                            <div className="links-container">
                                <h5 className="group-title">Account</h5>
                                <div className="row link-container">
                                    <div className="col-2"><span className="fas fa-user"></span></div>
                                    <div className="col"><a href="" className="link" onClick={e => {
                                        e.preventDefault();
                                        history.push(`/@${photographerData.username}`)
                                    }}>My Page</a></div>
                                </div>
                                <div className="row link-container">
                                    <div className="col-2"><span className="fas fa-edit"></span></div>
                                    <div className="col"><NavLink to="edit-profile" className="link">Edit Profile</NavLink></div>
                                </div>
                                <div className="row link-container">
                                    <div className="col-2"><span className="fas fa-users mr-3"></span></div>
                                    <div className="col"><span to="" className="link">Followers</span></div>
                                    <div className="col-3"><span className="badge badge-danger">{photographerData.followers !== 'null' ? photographerData.followers : 0}</span></div>
                                </div>
                                <div className="row link-container">
                                    <div className="col-2"><span className="fas fa-thumbs-up mr-3"></span></div>
                                    <div className="col"><span to="" className="link">Views</span></div>
                                    <div className="col-3"><span className="badge badge-danger">{photographerData.views !== 'null' ? photographerData.views : 0}</span></div>
                                </div>
                            </div>
                            
                            <div className="links-container">
                                <h5 className="group-title">Finance</h5>
                                <div className="row link-container">
                                    <div className="col-2"><span className="fas fa-money-bill-alt mr-3"></span></div>
                                    <div className="col-4"><span to="" className="link">Wallet</span></div>
                                    <div className="col"><span className="badge badge-danger float-right">&#8358; {photographerData.balance}</span></div>
                                </div>
                                <div className="row link-container">
                                    <div className="col-2"><span className="fas fa-money-check-alt"></span></div>
                                    <div className="col"><NavLink to="withdrawl" className="link">Withdrawl</NavLink></div>
                                </div>
                                <div className="row link-container">
                                    <div className="col-2"><span className="fas fa-eye"></span></div>
                                    <div className="col"><NavLink to="banks-code" className="link">View Banks Code</NavLink></div>
                                </div>
                            </div>

                            <div className="links-container">
                                <h5 className="group-title">Subscription</h5>
                                <div className="row link-container">
                                    <div className="col-2"><span className="fas fa-spinner mr-3"></span></div>
                                    <div className="col"><NavLink to="plans" className="link">Plans</NavLink></div>
                                </div>
                                <div className="row link-container">
                                    <div className="col-2"><span className="fas fa-arrow-down mr-3"></span></div>
                                    <div className="col"><NavLink to="downgrade" className="link">Downgrade</NavLink></div>
                                </div>
                            </div>
                        </div>
                    </nav>

                    {/* Dashboard Content */}
                    <div id="content">
                        <div className="title-bar">
                                <button className="sidebar-toggle btn btn-dark" id="sidebar-toggle"><span className="fas fa-list"></span></button>
                        </div>

                        <div className="main pb-5">
                            <Switch>
                                <Route path="/banks-code">
                                    <BanksCode />
                                </Route>

                                <Route path="/downgrade">
                                    <ConnectedDowngrade />
                                </Route>

                                <Route path="/plans">
                                    <ConnectedPlans />
                                </Route>

                                <Route path="/edit-profile">
                                    <ConnectedEditProfile />
                                </Route>

                                <Route path="/add-photos">
                                    <ConnectedAddPhotos />
                                </Route>

                                <Route path="/photos">
                                    <ConnectedPhotos onUpdate={onPhotosUpdate}/>
                                </Route>

                                <Route path="/">
                                    <Redirect to={`photos?category=${photosCategory}&sort=${photosSort}`} />
                                </Route>
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        </HashRouter>
    );
};

const BanksCode = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [banks, setBanks] = useState([]);

    useEffect(() => {
        setIsLoading(true);

        // Get the banks
        utils.axios.get('banks')
        .then((response) => {
            if (response.status === 200) {
                setIsLoading(false);
                setBanks(response.data);
            }
            else {
                setIsLoading(false);
                showNetworkError();
            }
        })
        .catch((error) => {
            setIsLoading(false);
            showNetworkError();
        })
    }, []);

    if (isLoading)
        return <div className="mt-5"><Loading /></div>;

    return (
        <div className="mt-3 px-3">
            <div className="table-responsive">
                <table className="table table-striped table-bordered table-hover">
                    <thead className="thead-dark">
                        <tr>
                            <th>BANK</th>
                            <th>CODE</th>
                        </tr>
                    </thead>

                    <tbody>
                        {banks.map((bank, index) => {
                            return (
                                <tr key={`tr-${index}`}>
                                    <td key={`tr-${index}-td-0`}>
                                        {bank.name}
                                    </td>
                                    <td key={`tr-${index}-td-1`}>
                                        {bank.code}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

const Photos = ({ onUpdate, getPhotos, getPhotosCount, getCategories, product_categories, photos, photosCount }) => {
    const queries = new useQuery();
    const productsPerPage = 30;
    
    const [categories, setCategories] = useState(product_categories);
    const [category, setCategory] = useState(queries.get('category') || 'All');
    const [sort, setSort] = useState(queries.get('sort') || 'ascending');
    const [searchKeyword, setSearchKeyword] = useState('');
    const [products, setProducts] = useState([]);
    const [productsCount, setProductsCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [currentOffset, setCurrentOffset] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // Fetch the categories if it is not availaible
       if (!categories || categories.length === 0) {
           getCategories();
       }
    }, [categories, getCategories]);

    useEffect(() => {
        setCategories(product_categories);
    }, [product_categories]);

    useEffect(() => {
        const newCategory = queries.get('category') || 'All';
        const newSort = queries.get('sort') || 'ascending';
        setCategory(newCategory);
        setSort(newSort);
        onUpdate(newCategory, newSort);
    }, [queries, onUpdate]);

    useEffect(() => {
        setIsLoading(true);
        getPhotosCount(category, sort, searchKeyword);
    }, [category, sort, searchKeyword, getPhotosCount]);

    useEffect(() => {
        if (photosCount.value === constants.flags.ERROR) {
            setIsLoading(false);
            showError('Oops', 'An error occured, please try again');
        }
        else if (photosCount.value === constants.flags.NO_CONTENT) {
            setIsLoading(false);
            setProducts([]);
        }
        else {
            setProductsCount(photosCount.value);
        }
    }, [photosCount]);

    useEffect(() => {
        if (productsCount > 0) {
            getPhotos(category, sort, searchKeyword, currentOffset, productsPerPage);
        }
    }, [productsCount, isLoading, category, sort, searchKeyword, currentOffset, productsPerPage, getPhotos]);

    useEffect(() => {
        setProducts(photos.data);
        if (photos.data.length > 0)
            setIsLoading(false);
    }, [photos.data]);

    const paginatorClicked = data => {
        const selected = data.selected;
        const offset = Math.ceil(selected * productsPerPage);
        const currentPage = selected;

        setCurrentPage(currentPage);
        setCurrentOffset(offset);

        setIsLoading(true);
        getPhotos(category, sort, searchKeyword, currentOffset, productsPerPage);

        $('html, body').animate({
            scrollTop: 0
        }, 800);
    };

    const withCategory = theCategory => {
        return `?category=${theCategory}&sort=${sort}`;
    };

    const withSort = theSort => {
        return `?category=${category}&sort=${theSort}`;
    };

    const generateCategoryLink = (title) => {
        const uri = withCategory(title);
        return (
            <NavLink to={uri} key={`cat-link-${Math.random() + Math.random()}`} className="dropdown-item" isActive={
                (match, location) => {
                    if (location.search === uri.replace(location.pathname, ''))
                        return true;

                    return false;
                }
            }>{title}</NavLink>
        );
    };

    const generateSortLink = (link, title) => {
        const uri = withSort(link);
        return (
            <NavLink to={uri} className="dropdown-item" isActive={
                (match, location) => {
                    if (location.search === uri.replace(location.pathname, ''))
                        return true;

                    return false;
                }
            }>{title}</NavLink>
        );
    };

    const handleSearchKeywordChanged = e => {
        setSearchKeyword(e.target.value ? e.target.value : '');
    };

    const searchPhotos = e => {
        e.preventDefault();
        
        setIsLoading(true);
        getPhotosCount(category, sort, searchKeyword);
    };

    const onCardClicked = (currentIndex) => {
        if (products.length > 0) {
            utils.showProductsModalPhotographer(products, currentIndex, editProduct);
        }
    };

    const doDelete = (product) => {
        utils.deleteProductFromPortfolio(product,
            () => {
                utils.ReactSwal.fire({
                    icon: 'success',
                    title: 'Photo deleted!',
                    timer: 2000,
                    onAfterClose: () => {
                        setIsLoading(true);
                        getPhotosCount(category, sort, searchKeyword);
                    }
                });
            },
            () => showError('Oops!', 'The photo was not deleted, please try again')
        );
    };

    const onDelete = (product) => {
        utils.ReactSwal.fire({
            icon: 'question',
            showConfirmButton: false,
            showCloseButton: true,
            html: (
                <div className="montserrat-font">
                    Do you really want to delete <span className="text-warning">{product.name}</span> from your portfolio?<br/><br/>
                    This cannot be reversed!

                    <button className="btn btn-block btn-danger mt-5" onClick={e => doDelete(product)}>Delete</button>
                </div>
            )
        });
    };

    const editProduct = (product) => {
        utils.ReactSwal.fire({
            padding: "0",
            showConfirmButton: false,
            showCloseButton: true,
            customClass: {
                content: 'p-0',
                popup: 'products-popup',
            },
            html: <EditComponent product={product} />
        });
    };

    const EditComponent = ({product}) => {
        const [category, setCategory] = useState(product.category);
        
        const [data, setData] = useState({
            name: product.name,
            description: product.description,
            price: product.price,
        });

        const handleCategoryChanged = e => {
            setCategory(e.target.value);
        };

        const handleInputChanged = e => {
            const name = e.target.name;
            const value = e.target.value;
            data[name] = value;
            setData({...data});
        };

        const handleEditPhoto = e => {
            e.preventDefault();

            showLoading();
            
            const axios = axiosInstance();

            let changed = false;

            const formData = new FormData();
            
            if (data.name !== product.name) {
                formData.append('name', data.name);
                changed = true;
            }
            if (data.description !== product.description) {
                formData.append('description', data.description);
                changed = true;
            }
            if (data.price !== product.price) {
                formData.append('price', data.price);
                changed = true;
            }
            if (category !== product.category) {
                formData.append('category', category);
                changed = true;
            }
            
            if (!changed) {
                dismissModal();
                return;
            }

            axios.post('photographer/photo/' + product.id, formData, {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            })
            .then((response) => {
                dismissModal();
                if (response.status === 204) {
                    showSuccess('Photo updated!');
                    setIsLoading(true);
                    getPhotosCount(category, sort, searchKeyword);
                }
                else if (response.status === 400) { //Validation Error
                    Swal.fire({
                        title: 'Validation Error!',
                        html: response.data.messages.error,
                        icon: 'error',
                        confirmButtonText: 'Retry'
                    });
                }
            })
            .catch((error) => {
                dismissModal();
                showNetworkError();
            });
        };

        return (
            <div className="container mt-5">
                {categories.length === 0 ? <Loading />: 
                    <div className="shadow-lg">
                        <h4 className="bg-dark text-white montserrat-font text-center p-3 mb-5">Edit Photo</h4>

                        <form className="m-4 pb-5" onSubmit={handleEditPhoto}>
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Photo title" name="name" value={data.name} 
                                    onChange={handleInputChanged} maxLength="50" required/>
                            </div>
                            <div className="input-group mt-3">
                                <input type="text" className="form-control" placeholder="Description" name="description" value={data.description} 
                                    onChange={handleInputChanged} maxLength="500" required/>
                            </div>
                            <div className="input-group mt-3">
                                <input type="number" className="form-control" placeholder="Price (in naira. 0 means free)" name="price" value={data.price} 
                                    onChange={handleInputChanged} required/>
                            </div>
                            <div className="input-group mt-3">
                                <select className="custom-select" onChange={handleCategoryChanged} defaultValue={category}>
                                    {categories.map((category, index) => {
                                        return <option key={index} value={category.category}>{category.category}</option>
                                    })}
                                </select>
                            </div>
                            
                            <div className="mt-5 text-left">
                                <button type="submit" className="btn btn-lg btn-dark">Update Photo <span className="fas fa-arrow-right ml-3"></span></button>
                            </div>
                        </form>
                    </div>    
                }
            </div>
        );
    };

    return (
        <div>
            <div>
                <div className="jumbotron p-0">
                    <div className="filter-container container">
                        <div className="row">
                            <div className="col">
                                <div className="dropdown w-100 category">
                                        <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                            Category <span className="fas fa-chevron-down float-right"></span>
                                        </a>

                                        <div className="dropdown-menu roboto-font">
                                            {generateCategoryLink('All')}
                                            {!categories ? '' : categories.map((category) => generateCategoryLink(category.category))}
                                        </div>
                                    </div>
                            </div>

                            <div className="col">
                                <div className="dropdown w-100">
                                    <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                        Sort <span className="fas fa-chevron-down float-right"></span>
                                    </a>

                                    <div className="dropdown-menu roboto-font">
                                        {generateSortLink('featured', 'Featured')}
                                        {generateSortLink('price-ascending', 'Price, low to high')}
                                        {generateSortLink('price-descending', 'Price, high to low')}
                                        {generateSortLink('ascending', 'A - Z')}
                                        {generateSortLink('descending', 'Z - A')}
                                        {generateSortLink('date-descending', 'Date, new to old')}
                                        {generateSortLink('date-ascending', 'Date, old to new')}
                                        {generateSortLink('popular', 'Popular')}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <form onSubmit={searchPhotos}>
                            <div className="row search-bar">
                                <div className="col">
                                    <div className="input-group">
                                        <input type="search" className="form-control" placeholder="Search your photos" value={searchKeyword}
                                            onChange={handleSearchKeywordChanged}/>
                                        <button type="submit" className="btn btn-dark"><span className="fas fa-search"></span></button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="my-5 px-1">
                    {isLoading ? <Loading /> : <PhotosGrid onCardClicked={onCardClicked} key="shop-grid" products={products}
                        eventHandlers={{'onDelete': onDelete, 'onEdit': editProduct}}/>}
                    { (isLoading || productsCount === 0 || productsCount <= productsPerPage) ? '' :  
                        generatePagination(Math.ceil(productsCount / productsPerPage), paginatorClicked,
                            2, 2, <span className="fas fa-chevron-left"></span>, <span className="fas fa-chevron-right"></span>,
                            currentPage)
                    }
                </div>
            </div> 
        </div>
    );
};

const AddPhotos = ({ getCategories, product_categories }) => {
    const [categories, setCategories] = useState(product_categories);
    const [category, setCategory] = useState(null);
    const [photoFile, setPhotoFile] = useState(null);
    
    const history = useHistory();

    const [data, setData] = useState({
        name: '',
        description: '',
        price: 0,
    });

    const handleCategoryChanged = e => {
        setCategory(e.target.value);
    };

    const handleInputChanged = e => {
        const name = e.target.name;
        const value = e.target.value;
        data[name] = value;
        setData({...data});
    };

    const handleFileChanged = e => {
        setPhotoFile(e.target.files[0]);
    };

    const handleAddPhoto = e => {
        e.preventDefault();
        
        const uploadTask = (updateProgress) => {
            const axios = axiosInstance();

            const formData = new FormData();

            formData.append('photo', photoFile)
            formData.append('name', data.name);
            formData.append('description', data.description);
            formData.append('price', data.price);
            formData.append('category', category);
            
            axios.post('photographer/photo', formData, {
                headers: {
                    'content-type': 'multipart/form-data'
                },
                onUploadProgress: (ev) => {
                    const progress = ev.loaded / ev.total * 100;
                    updateProgress(Math.round(progress));
                },
            })
            .then((response) => {
                dismissModal();
                if (response.status === 204) {
                    showSuccess('Photo uploaded!');
                }
                else if (response.status === 400) { //Validation Error
                    ReactSwal.fire({
                        title: 'Validation Error!',
                        html: response.data.messages.error,
                        icon: 'error',
                        confirmButtonText: 'Retry'
                    });
                }
                else if (response.status === 401) {
                    ReactSwal.fire({
                        title: 'Authentication Error!',
                        html: 'Your session timed out, please login again',
                        icon: 'error',
                        confirmButtonText: 'Login',
                        timer: 3000,
                        onAfterClose: () => history.push('/photographer/login'),
                    });
                }
                else {
                    showNetworkError();
                }
            })
            .catch((error) => {
                dismissModal();
                showNetworkError();
            });
        };

        ReactSwal.fire({
            title: 'Uploading...',
            html: <utils.ProgressModal task={uploadTask}/>,
            allowOutsideClick: false,
            allowEnterKey: false,
            allowEscapeKey: false,
            showConfirmButton: false,
        });
    };

    useEffect(() => {
        // Fetch the categories if it is not availaible
       if (!categories || categories.length === 0) {
           getCategories();
       }
    }, [categories, getCategories]);

    useEffect(() => {
        setCategories(product_categories);
        setCategory(product_categories[0].category);
    }, [product_categories]);

    
    useEffect(() => {
        // displays the name of the file selected from the file browser
        $(".custom-file-input").on("change", function()
        {
        var fileName = $(this).val().split("\\").pop();
        $(this).siblings(".custom-file-label").addClass("selected").html(fileName);
        });
    });

    return (
        <div className="container mt-5">
            <ScrollToTopOnMount />

            {categories.length === 0 ? <Loading />: 
                <div className="shadow-lg">
                    <h4 className="bg-dark text-white montserrat-font text-center p-3 mb-5">Upload Photo</h4>

                    <form className="m-4 pb-5" onSubmit={handleAddPhoto}>
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="Photo title" name="name" value={data.name} 
                                onChange={handleInputChanged} maxLength="50" required/>
                        </div>
                        <div className="input-group mt-3">
                            <input type="text" className="form-control" placeholder="Description" name="description" value={data.description} 
                                onChange={handleInputChanged} maxLength="500" required/>
                        </div>
                        <div className="input-group mt-3">
                            <input type="number" className="form-control" placeholder="Price (in naira. 0 means free)" name="price" value={data.price} 
                                onChange={handleInputChanged} required/>
                        </div>
                        <div className="input-group mt-3">
                            <select className="custom-select" onChange={handleCategoryChanged}>
                                {categories.map((category, index) => {
                                    return <option key={index} value={category.category}>{category.category}</option>
                                })}
                            </select>
                        </div>
                        <div className="input-group mt-3">
                            <div className="custom-file">
                                <input type="file" className="custom-file-input" id="customFile" onChange={handleFileChanged} required/>
                                <label className="custom-file-label text-left">Choose Photo File</label>
                            </div>
                        </div>

                        <div className="mt-5 text-left">
                            <button type="submit" className="btn btn-lg btn-dark">Upload <span className="fas fa-arrow-right ml-5"></span></button>
                        </div>
                    </form>
                </div>    
            }
        </div>
    );
};

const EditProfile = ({dashboard, updateDashboard}) => {
    const [avatarFile, setAvatarFile] = useState(null);
    const [data, setData] = useState({
        first_name: dashboard.first_name,
        last_name: dashboard.last_name,
        email: dashboard.email,
        phone: dashboard.phone,
        bio: dashboard.bio,
    });

    const [passwordData, setPasswordData] = useState({
        old_password: '',
        new_password: '',
        confirm_password: '',
    });

    const handleInputChanged = e => {
        const name = e.target.name;
        const value = e.target.value;
        data[name] = value;
        setData({...data});
    };

    const handlePasswordInputChanged = e => {
        const name = e.target.name;
        const value = e.target.value;
        passwordData[name] = value;
        setPasswordData({...passwordData});
    };

    const handleFileChanged = e => {
        setAvatarFile(e.target.files[0]);
    };

    const handleEdit = e => {
        e.preventDefault();

        showLoading();
        
        const axios = axiosInstance();

        const formData = new FormData();

        if (avatarFile) // Change of profile image is optional
            formData.append('profile_image', avatarFile)

        formData.append('first_name', data.first_name);
        formData.append('last_name', data.last_name);
        formData.append('email', data.email);
        formData.append('phone', data.phone);
        formData.append('username', data.username);
        formData.append('bio', data.bio);

        axios.post('photographer/update', formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
        .then((response) => {
            dismissModal();
            if (response.status === 200) {
                // Update this session
                const update = response.data;
                sessionStorage.setItem('photographer_data', JSON.stringify(update));
                updateDashboard(update);

                Swal.fire({
                    title: 'Profile Updated!',
                    icon: 'success',
                    timer: 2000,
                });
            }
            else if (response.status === 400) { //Validation Error
                Swal.fire({
                    title: 'Validation Error!',
                    html: response.data.messages.error,
                    icon: 'error',
                    confirmButtonText: 'Retry'
                });
            }
        })
        .catch((error) => {
            dismissModal();
            Swal.fire({
                title: 'Connection Error!',
                text: 'A connection error occured, please try again',
                icon: 'error',
                confirmButtonText: 'Retry'
            });
        });
    };

    const handleChangePassword = e => {
        e.preventDefault();
        
        if (passwordData.new_password !== passwordData.confirm_password) {
            showError('Validation Error!', 'Passwords do not match!');
            return;
        }

        showLoading();

        const axios = axiosInstance();

        const formData = new FormData();

        formData.append('old_password', passwordData.old_password);
        formData.append('new_password', passwordData.new_password);
        formData.append('confirm_password', passwordData.confirm_password);
        
        axios.post('photographer/password', formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
        .then((response) => {
            dismissModal();
            if (response.status === 204) {
                Swal.fire({
                    title: 'Password Updated!',
                    icon: 'success',
                    timer: 2000,
                });
            }
            else if (response.status === 400) { //Validation Error
                Swal.fire({
                    title: 'Validation Error!',
                    html: response.data.messages.error,
                    icon: 'error',
                    confirmButtonText: 'Retry'
                });
            }
        })
        .catch((error) => {
            dismissModal();
            showNetworkError();
        });
    };

    return (
        <div className="container">
            <ScrollToTopOnMount />

            <div className="form-container p-0 mt-5 shadow-lg">
                <h4 className="bg-dark text-white montserrat-font text-center p-3 mb-5">Edit Profile</h4>

                <form onSubmit={handleEdit} className="p-4">
                        <div className="input-group row mb-3">
                            <div className="col-sm mb-3 mb-md-0">
                                <input type="text" placeholder="First name" className="form-control" name="first_name" value={data.first_name} 
                                    onChange={handleInputChanged} maxLength="30" required/>
                            </div>
                            <div className="col-sm">
                                <input type="text" placeholder="Last name" className="form-control" name="last_name" value={data.last_name} 
                                    onChange={handleInputChanged} maxLength="30" required/>
                            </div>
                        </div>

                        <div className="input-group row mb-3">
                            <div className="col-sm mb-3 mb-md-0">
                                <input type="email" placeholder="Email" className="form-control" name="email" value={data.email} 
                                    onChange={handleInputChanged} maxLength="50" required/>
                            </div>
                            <div className="col-sm">
                                <input type="number" placeholder="Phone" className="form-control" name="phone" value={data.phone} 
                                    onChange={handleInputChanged} maxLength="15" required/>
                            </div>
                        </div>

                        <div className="input-group row mb-3">
                            <div className="col-sm">
                                <textarea className="form-control" placeholder="Bio" name="bio" value={data.bio} 
                                    onChange={handleInputChanged} maxLength="2000" required/>
                            </div>
                        </div>

                        <div className="input-group row mb-5">
                            <div className="col-sm">
                                <div className="custom-file">
                                    <input type="file" className="custom-file-input" id="customFile" onChange={ handleFileChanged }/>
                                    <label className="custom-file-label text-left">Choose Profile Photo</label>
                                </div>
                            </div>
                        </div>

                        <div className="mt-5 text-left">
                            <button type="submit" className="btn btn-lg btn-dark">Update <span className="fas fa-arrow-right ml-5"></span></button>
                        </div>
                    </form>
            </div>
        
            <div className="shadow-lg mt-5">
                <h4 className="bg-dark text-white montserrat-font text-center p-3 mb-5">Change Password</h4>

                <form className="m-4 pb-5" onSubmit={handleChangePassword}>
                    <div className="input-group">
                        <input type="password" className="form-control" placeholder="Old password" name="old_password" onChange={handlePasswordInputChanged} required/>
                    </div>
                    <div className="input-group mt-3">
                        <input type="password" className="form-control" placeholder="New password" name="new_password" onChange={handlePasswordInputChanged} required/>
                    </div>
                    <div className="input-group mt-3">
                        <input type="password" className="form-control" placeholder="Confirm password" name="confirm_password" onChange={handlePasswordInputChanged} required/>
                    </div>

                    <div className="mt-5 text-left">
                        <button type="submit" className="btn btn-lg btn-dark">Replace Password <span className="fas fa-arrow-right ml-5"></span></button>
                    </div>
                </form>
            </div>
        </div>
    );
};

const Plans = ({updateDashboard, photographer_plans, getPhotographerPlans}) => {
    let photographerData = JSON.parse(sessionStorage.getItem('photographer_data'));

    const upgradePhotographer = (axios, plan, reference) => {
        axios.post('photographer/sub/up/' + plan, {}, {
            params: {
                reference: reference,
            }
        })
        .then((response) => {
            dismissModal();
            if (response.status === 200) {
                // Update this session
                const update = response.data;
                sessionStorage.setItem('photographer_data', JSON.stringify(update));
                updateDashboard(update);
                sessionStorage.removeItem('photographer_up_sub_ref');

                Swal.fire({
                    title: 'Subscription Upgraded!',
                    icon: 'success',
                    timer: 2000,
                });
            }
            else { //Unknown Error
                showError('Error', 'An unknown error occured');
            }
        })
        .catch((error) => {
            dismissModal();
            showNetworkError();
        });
    };

    const handleUpgrade = (plan) => {
        photographerData = JSON.parse(sessionStorage.getItem('photographer_data'));

        if (photographerData.plan === plan) {
            showError('Oops!', 'You are already using the ' + plan + ' plan');
            return;
        }

        showLoading();

        const axios = axiosInstance();

        // Subscribe the photographer first

        // if there is an existing reference code, let's verify it
        if (sessionStorage.getItem('photographer_up_sub_ref')) {
            const ref = sessionStorage.getItem('photographer_up_sub_ref');

            // verify it
            axios.post('transaction/verify', {reference: ref})
            .then((res) => {
                // If we get back a positive reponse (code 200), then proceed and upgrade the photographer
                if (res.status === 200) {
                    upgradePhotographer(axios, plan, ref);
                }
                else {
                    // The reference is invalid, remove it and init subscription process
                    sessionStorage.removeItem('photographer_up_sub_ref');
                    subscribeThenUpgrade(axios, plan);
                }
            })
            .catch((error) => {
                showNetworkError();
            });
        }
        else {
            subscribeThenUpgrade(axios, plan);
        }
    };

    const subscribeThenUpgrade = (axios, plan) => {
        axios.post('photographer/sub/init', {'plan': plan, 'email': photographerData.email})
        .then((reponse) => {
            // eslint-disable-next-line no-undef
            let handler = PaystackPop.setup({
                ...reponse.data,
                onClose: function() {
                    // No transaction recorded because the user closed the popup
                    showSubscriptionError();
                },
                callback: (response) => {
                    // We have a response from paystack, let's verify the transaction before updating the photographer
                    // Save the reference first, in case something goes wrong, we can reuse to avoid repeated payment!
                    sessionStorage.setItem('photographer_up_sub_ref', response.reference);
                    axios.post('transaction/verify', {reference: response.reference})
                    .then((res) => {
                        // If we get back a positive reponse (code 200), then proceed and register the photographer
                        if (res.status === 200) {
                            upgradePhotographer(axios, plan, response.reference);
                        }
                        else {
                            showSubscriptionError();
                        }
                    })
                    .catch((error) => {
                        showSubscriptionError();
                    })
                }
            });

            handler.openIframe();
        })
        .catch((error) => {
            showNetworkError();
        });
    };

    const handleSilverUpgrade = e => {
        e.preventDefault();
        handleUpgrade('silver');
    };

    const handleGoldUpgrade = e => {
        e.preventDefault();
        handleUpgrade('gold');
    };

    useEffect(() => {
        if (photographer_plans.length === 0)
            getPhotographerPlans();
    });

    let basicDeal, silverDeal, goldDeal, silverPrice, goldPrice;
    if (photographer_plans.length === 3) {
        const plans = photographer_plans;
        basicDeal = parseFloat(plans[0].rate) * 100;
        silverDeal = parseFloat(plans[1].rate) * 100;
        goldDeal = parseFloat(plans[2].rate) * 100;

        silverPrice = plans[1].price;
        goldPrice = plans[2].price;
    }

    return (
        <div className="container">
            <ScrollToTopOnMount />

            <div className="plan-container jumbotron container-fluid text-center">
                {photographer_plans.length === 0 ? <Loading /> :
                    <div>
                        <h1 className="text-center montserrat-font title">Are you a Photographer?</h1>
                        <h4 className="text-center roboto-font sub-title">Photography sales made easy & affordable!</h4>

                        <div className="card-deck text-white">
                            <div className="card">
                                <h3 className="plan p-3 text-center mb-0 montserrat-font"><span>Basic</span> Plan</h3>
                                <h4 className="text-center p-3 discount mb-0 roboto-font">Now FREE!</h4>
                                
                                <div className="card-body text-dark">
                                    <h4 className="text-center roboto-font deal">You get back <span className="montserrat-font">{basicDeal}%</span> per sale!
                                    </h4>
                                </div>

                                <div className="card-footer">
                                    <Link to="downgrade" className="sub-link stretched-link">Downgrade</Link>
                                </div>
                            </div>

                            <div className="card">
                                <h3 className="plan p-3 text-center mb-0 montserrat-font"><span>Silver</span> Plan</h3>
                                <h4 className="text-center p-3 discount mb-0 roboto-font">Starting At<strong className="ml-2">&#8358;{silverPrice}/mo*</strong></h4>

                                <div className="card-body text-dark">
                                    <h4 className="text-center roboto-font deal">You get back <span className="montserrat-font">{silverDeal}%</span> per sale!</h4>
                                </div>

                                <div className="card-footer">
                                    <a href="upgrade?plan=silver" className="sub-link stretched-link" onClick={handleSilverUpgrade}>Upgrade</a>
                                </div>
                            </div>

                            <div className="card">
                                <h3 className="plan p-3 text-center mb-0 montserrat-font"><span>Gold</span> Plan</h3>
                                <h4 className="text-center p-3 discount mb-0 roboto-font">Starting At<strong className="ml-2">&#8358;{goldPrice}/mo*</strong></h4>

                                <div className="card-body text-dark">
                                    <h4 className="text-center roboto-font deal">You get back <span className="montserrat-font">{goldDeal}%</span> per sale!</h4>
                                </div>

                                <div className="card-footer">
                                    <a href="upgrade?plan=gold" className="sub-link stretched-link" onClick={handleGoldUpgrade}>Upgrade</a>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

const Downgrade = ({updateDashboard}) => {
    const handleDowngrade = e => {
        const photographerData = JSON.parse(sessionStorage.getItem('photographer_data'));
        if (photographerData.plan === 'basic') {
            showError('Oops!', 'You are already using the basic plan!');
            return;
        }

        showLoading();

        const axios = axiosInstance();

        axios.patch('photographer/sub/down')
        .then((response) => {
            dismissModal();
            if (response.status === 200) {
                // Update this session
                const update = response.data;
                updateDashboard(update);
                sessionStorage.setItem('photographer_data', JSON.stringify(update));

                Swal.fire({
                    title: 'Subscription Downgraded!',
                    icon: 'success',
                    timer: 2000,
                });
            }
            else { //Unknown Error
                showError('Error', 'An unknown error occured');
            }
        })
        .catch((error) => {
            dismissModal();
            showNetworkError();
        });
    };

    return (
        <div className="container mt-5">
            <ScrollToTopOnMount />

            <div className="shadow-lg">
                <h4 className="bg-dark text-white montserrat-font text-center p-3 mb-0">Downgrade Plan</h4>

                <div className="jumbotron bg-light pb-3 text-center">
                    <h5>Do you really want to downgrade your plan? This will unsubscribe you from any paid subscription immediately!</h5>
                    <button className="btn btn-dark btn-lg mt-5" onClick={handleDowngrade}>Yes, Downgrade</button>
                </div>
            </div>
        </div>
    );
};

export const PasswordReset = ({ignoreScrollChange}) => {
    const history = useHistory();
    const queries = useQuery();
    const verification_code = queries.get('code') || null;
    
    const [email, setEmail] = useState('');
    const [passwordData, setPasswordData] = useState({
        new_password: '',
        confirm_password: '',
    });

    useEffect(() => {
        ignoreScrollChange();
        animateScroll.scrollToTop({
            duration: 700,
            delay: 0,
            smooth: true,
        });
    }, [ignoreScrollChange]);

    const handlePasswordInputChanged = e => {
        const name = e.target.name;
        const value = e.target.value;
        passwordData[name] = value;
        setPasswordData({...passwordData});
    };
    
    const resendCode = e => {
        dismissModal();
        
        if (email === '') {
            showError('Please enter a valid registered email');
            return;
        }

        showLoading();
        
        utils.axios.post('photographer/password_reset/resend', {
            email: email,
        })
        .then((response) => {
            if (response.status === 204) {
                utils.showSuccess('Verification code resent!');
            }
            else if (response.status === 400) { //Validation Error
                showError('Validation Error', 'There is no Photographer password reset request associated with this email!');
            }
            else {
                showNetworkError();
            }
        })
        .catch((error) => {
            showNetworkError();
        });
    };

    const handleReset = e => {
        e.preventDefault();

        showLoading();

        utils.axios.post('photographer/password_reset/init', {
            email: email,
        })
        .then((response) => {
            if (response.status === 204) {
                utils.showSuccess('Verification code sent!');
            }
            else if (response.status === 409) {
                ReactSwal.fire({
                    title: 'Conflict Error!',
                    html: (
                        <div className="roboto-font">
                            A verification code has already been sent to your email. We can still resend the code if you have not seen it yet.

                            <button className="btn btn-info btn-block mt-4" onClick={resendCode}>Resend Code</button>
                        </div>
                    ),
                    icon: 'error',
                    showCloseButton: true,
                    showConfirmButton: false,
                });
            }
            else if (response.status === 400) { //Validation Error
                showError('Validation Error', response.data.messages.error);
            }
            else {
                showNetworkError();
            }
        })
        .catch((error) => {
            showNetworkError();
        });
    };

    const handleUpdatePassword = e => {
        e.preventDefault();

        if (passwordData.new_password !== passwordData.confirm_password) {
            showError('Validation Error!', 'Passwords do not match!');
            return;
        }

        showLoading();
        
        utils.axios.post('photographer/password_reset/finalize', {
            email: email,
            new_password: passwordData.new_password,
            confirm_password: passwordData.confirm_password,
            verification_code: verification_code,
        })
        .then((response) => {
            if (response.status === 204) {
                ReactSwal.fire({
                    title: 'Password updated successfully!',
                    icon: 'success',
                    timer: 3000,
                    onAfterClose: () => history.push('login')
                });
            }
            else if (response.status === 400) { //Validation Error
                showError('Validation Error', response.data.messages.error);
            }
            else {
                showNetworkError();
            }
        })
        .catch((error) => {
            showNetworkError();
        });
    };

    if (verification_code === null) {
        return (
            <div className="login roboto-font photographer-container">
                <ScrollToTopOnMount />
    
                <div className="mb-5">
                    <Link to="/photographer/login" className="btn btn-lg btn-info"><span className="fas fa-arrow-left mr-4"></span>Login</Link>
                </div>
    
                <div className="container text-center shadow-lg px-0">
                    <h3 className="header montserrat-font bg-dark text-white p-4 mb-0">Photographer Password Reset</h3>
                    
                    <div className="form-container jumbotron bg-light">
                        <form onSubmit={handleReset}>
                            <div className="col-sm mb-3 mb-md-0">
                                <input type="email" placeholder="Enter registered email" className="form-control bg-light" value={email} 
                                    onChange={e => setEmail(e.target.value)} maxLength="50" required/>
                            </div>
    
                            <div className="mt-5 row">
                                <div className="col-sm mb-2">
                                    <button type="submit" className="btn btn-lg btn-dark btn-block">Reset Password<span className="fas fa-arrow-right ml-2 float-right mt-1"></span></button>
                                </div>
                                <div className="col-sm">
                                <button type="button" className="btn btn-lg btn-dark btn-block" onClick={resendCode}>Resend Code <span className="fas fa-spinner ml-2 float-right mt-1"></span></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
    else {
        return (
            <div className="login roboto-font photographer-container">
                <ScrollToTopOnMount />
    
                <div className="mb-5">
                    <Link to="/photographer/login" className="btn btn-lg btn-info"><span className="fas fa-arrow-left mr-4"></span>Login</Link>
                </div>
    
                <div className="container text-center shadow-lg px-0">
                    <h3 className="header montserrat-font bg-dark text-white p-4 mb-0">Photographer Password Reset</h3>
                    
                    <div className="form-container jumbotron bg-light">
                        <form onSubmit={handleUpdatePassword}>
                            <div className="input-group">
                                <input type="email" placeholder="Enter registered email" className="form-control bg-light" value={email} 
                                    onChange={e => setEmail(e.target.value)} maxLength="50" required/>
                            </div>
                            <div className="input-group mt-3">
                                <input type="password" className="form-control" placeholder="New password" name="new_password" onChange={handlePasswordInputChanged} required/>
                            </div>
                            <div className="input-group mt-3">
                                <input type="password" className="form-control" placeholder="Confirm password" name="confirm_password" onChange={handlePasswordInputChanged} required/>
                            </div>

                            <div className="mt-5 row">
                                <div className="col-sm mb-2">
                                    <button type="submit" className="btn btn-lg btn-dark btn-block">Update Password<span className="fas fa-arrow-right ml-2 float-right mt-1"></span></button>
                                </div>
                                <div className="col-sm">
                                <button type="button" className="btn btn-lg btn-dark btn-block" onClick={resendCode}>Resend Code <span className="fas fa-spinner ml-2 float-right mt-1"></span></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
};

export const Activate = ({ignoreScrollChange}) => {
    const history = useHistory();
    const queries = useQuery();
    const verification_code = queries.get('code') || null;
    
    const [email, setEmail] = useState('');
    const [newEmail, setNewEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [recaptchaDone, setRecaptchaDone] = useState(false);

    useEffect(() => {
        ignoreScrollChange();
        animateScroll.scrollToTop({
            duration: 700,
            delay: 0,
            smooth: true,
        });
    }, [ignoreScrollChange]);
    
    useEffect(() => {
        if (verification_code) {
            // Finalize account activation

            showLoading();

            utils.axios.post('photographer/activate/finalize', {
                verification_code: verification_code,
            })
            .then((response) => {
                if (response.status === 204) {
                    ReactSwal.fire({
                        title: 'Account Activated!',
                        text: 'Your account was activated successfully. You can login now!',
                        icon: 'success',
                        confirmButtonText: 'Log In',
                        onAfterClose: () => history.push('/photographer/login'),
                    });
                }
                else if (response.status === 400) { //Validation Error
                    showError('Validation Error', 'Invalid activation code!');
                }
                else {
                    showNetworkError();
                }
            })
            .catch((error) => {
                showNetworkError();
            });
        }
    }, [verification_code, history]);
    
    const resendCode = e => {
        e.preventDefault();

        dismissModal();
        
        if (email === '') {
            showError('Please enter a valid registered email');
            return;
        }

        showLoading();
        
        utils.axios.post('photographer/activate/resend', {
            email: email,
        })
        .then((response) => {
            if (response.status === 204) {
                utils.showSuccess('Activation message resent!');
            }
            else if (response.status === 400) { //Validation Error
                showError('Validation Error', 'There is no Photographer account activation request associated with this email!');
            }
            else {
                showNetworkError();
            }
        })
        .catch((error) => {
            showNetworkError();
        });
    };

    const handleChangeEmail = e => {
        e.preventDefault();

        if (!recaptchaDone) {
            showError('', 'Please complete the reCaptcha challenge!');
            return;
        }

        showLoading();

        const axios = axiosInstance();
        axios.post('photographer/activate/modify', {
            email: newEmail,
        }, {
            auth: {
                username: username,
                password: password,
            }
        })
        .then((response) => {
            if (response.status === 204) {
                utils.showSuccess('Activation message sent to new email!');
            }
            else if (response.status === 400) { //Validation Error
                ReactSwal.fire({
                    title: 'Validation Error!',
                    html: response.data.messages.error,
                    icon: 'error',
                    confirmButtonText: 'Retry'
                });
            }
            else {
                showError('Authentication Error!', 'Wrong username or password');
            }
        })
        .catch((error) => {
            dismissModal();
            showNetworkError();
        });
    };

    const onRecaptcha = status => {
        if (status) {
            setRecaptchaDone(true);
        }
        else {
            setRecaptchaDone(false);
        }
    };

    if (verification_code === null) {
        return (
            <div className="login roboto-font photographer-container">
                <ScrollToTopOnMount />
    
                <div className="mb-5">
                    <Link to="/photographer/login" className="btn btn-lg btn-info"><span className="fas fa-arrow-left mr-4"></span>Login</Link>
                </div>
    
                <div className="container text-center shadow-lg px-0">
                    <h3 className="header montserrat-font bg-dark text-white p-4 mb-0">Resend Activation Message</h3>
                    
                    <div className="form-container jumbotron bg-light">
                        <form onSubmit={resendCode}>
                            <div className="col-sm mb-3 mb-md-0">
                                <input type="email" placeholder="Enter registered email" className="form-control bg-light" value={email} 
                                    onChange={e => setEmail(e.target.value)} maxLength="50" required/>
                            </div>
    
                            <div className="mt-5 text-left">
                                <button type="submit" className="btn btn-lg btn-dark">Resend Message<span className="fas fa-arrow-right ml-2 float-right mt-1"></span></button>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="container text-center shadow-lg px-0">
                <h3 className="header montserrat-font bg-dark text-white p-4 mb-0">Change Email</h3>
                
                <div className="form-container jumbotron bg-light">
                    <form onSubmit={handleChangeEmail}>
                        <div className="input-group row mb-3 text-left">
                            <div className="col-sm mb-3 mb-md-0">
                                <input type="email" placeholder="Enter new email" className="form-control bg-light" value={newEmail} 
                                    onChange={e => setNewEmail(e.target.value)} maxLength="50" required/>
                            </div>
                        </div>

                        <div className="input-group row mb-3">
                            <div className="col-sm mb-3 mb-md-0">
                                <input type="text" placeholder="Username" className="form-control" value={username} 
                                    onChange={e => setUsername(e.target.value)} maxLength="12" required/>
                            </div>

                            <div className="col-sm">
                                <input type="password" placeholder="Password" className="form-control" 
                                    onChange={e => setPassword(e.target.value)} required/>
                            </div>
                        </div>

                        <div className="input-group row mb-3 text-left">
                            <div className="col">
                                <ReCAPTCHA 
                                sitekey={utils.recaptchaKey}
                                onChange={onRecaptcha}/>
                            </div>
                        </div>

                        <div className="mt-5 text-left">
                            {recaptchaDone ? <button type="submit" className="btn btn-lg btn-dark">Update Email <span className="fas fa-arrow-right ml-5"></span></button> :
                            <button type="submit" className="btn btn-lg btn-dark disabled" disabled>Update Email <span className="fas fa-arrow-right ml-5"></span></button>}
                        </div>
                    </form>
                </div>
            </div>
            </div>
        );
    }
    else {
        return null;
    }
};

class PhotosGrid extends Component {
    constructor(props) {
        super(props);

        this.state = {transition: false};
    }

    componentDidMount() {
        this.setState({transition: true})
    }

    render() {
        const products = this.props.products;
        if (!products || products.length === 0)
            return <h2 className="m-4 roboto-font text-center">Your portfolio is empty!</h2>;

        const perRow = 3;

        let remainder = products.length % perRow;

        let makeCards;

        if (products.length < perRow)
        {
            makeCards = () => {
                let cols = [];
                
                for (let i = 0; i < products.length; i++) {
                    const product = products[i];
                    cols.push(<ImageGridCol key={`prod-col-${i}`}><PhotoCard index={i} 
                        onCardClicked={this.props.onCardClicked} product={product} 
                            {...this.props.eventHandlers}/></ImageGridCol>)
                }

                return cols;
            };
        }
        else if (remainder === 0)
        {
            makeCards = () => {
                let cols = [[], [], []];
                
                for (let i = 0; i < products.length; i += perRow) {
                    
                    for (let j = 0; j < perRow; j++) {
                        const product = products[i+j];
                        cols[j].push(<PhotoCard index={i+j} 
                            onCardClicked={this.props.onCardClicked} product={product} {...this.props.eventHandlers}
                            key={`prod-${i+j}`}/>);
                    }
                }

                let rows = [];
                for (let i = 0; i < perRow; i++)
                    rows.push(<ImageGridCol key={`prod-col-${i}`}>{cols[i]}</ImageGridCol>);

                return rows;
            };
        }
        else
        {
            makeCards = () => {
                let cols = [[], [], []];
                const evenSize = products.length - remainder;
                
                for (let i = 0; i < evenSize; i += perRow) {
                    for (let j = 0; j < perRow; j++) {
                        const product = products[i+j];
                        cols[j].push(<PhotoCard index={i+j} 
                            onCardClicked={this.props.onCardClicked} product={product} {...this.props.eventHandlers}
                            key={`prod-${i+j}`}/>);
                    }
                }

                let rows = [];
                for (let i = 0; i < perRow; i++)
                    rows.push(<ImageGridCol key={`prod-col-${i}`}>{cols[i]}</ImageGridCol>);

                for (let i = evenSize; i < products.length; i++) {
                    const product = products[i];
                    rows.push(<ImageGridCol key={`prod-col-${i}`}><PhotoCard index={i} 
                        onCardClicked={this.props.onCardClicked} product={product} 
                        {...this.props.eventHandlers} /></ImageGridCol>)
                }

                return rows;
            };
        }

        return (
            <CSSTransition
                in={this.state.transition}
                timeout={1100}
                classNames="products-grid-transition">
                <ImageGridRow>{makeCards()}</ImageGridRow>
            </CSSTransition>
        )
    }
}

class PagePhotosGrid extends Component {
    constructor(props) {
        super(props);

        this.state = {transition: false};
    }

    componentDidMount() {
        this.setState({transition: true})
    }

    render() {
        const products = this.props.products;
        if (!products || products.length === 0)
            return <h2 className="m-4 roboto-font text-center">No photo matched the provided parameters!</h2>;

        const perRow = 3;

        let remainder = products.length % perRow;

        let makeCards;

        if (products.length < perRow)
        {
            makeCards = () => {
                let cols = [];
                
                for (let i = 0; i < products.length; i++) {
                    const product = products[i];
                    cols.push(<ImageGridCol key={`prod-col-${i}`}><PhotoCard index={i} 
                        onCardClicked={this.props.onCardClicked} product={product} 
                            {...this.props.eventHandlers}/></ImageGridCol>)
                }

                return cols;
            };
        }
        else if (remainder === 0)
        {
            makeCards = () => {
                let cols = [];
                const colsShare = products.length / perRow;

                for (let i = 0; i < products.length; i += colsShare) {
                    let cards = [];

                    for (let j = 0; j < colsShare; j++) {
                        const product = products[i+j];
                        cards.push(<PhotoCard index={i+j} 
                            onCardClicked={this.props.onCardClicked} product={product} {...this.props.eventHandlers}
                            key={`prod-${i+j}`}/>)
                    }

                    cols.push(<ImageGridCol key={`prod-col-${i}`}>{cards}</ImageGridCol>)
                }

                return cols;
            };
        }
        else
        {
            makeCards = () => {
                let cols = [];
                const evenSize = products.length - remainder;
                const colsShare = evenSize / perRow;

                for (let i = 0; i < evenSize; i += colsShare) {
                    let cards = [];

                    for (let j = 0; j < colsShare; j++) {
                        const product = products[i+j];
                        cards.push(<PhotoCard index={i+j} 
                            onCardClicked={this.props.onCardClicked} product={product} 
                            {...this.props.eventHandlers}  key={`prod-${i+j}`}/>)
                    }

                    cols.push(<ImageGridCol key={`prod-col-${i}`}>{cards}</ImageGridCol>)
                }

                for (let i = evenSize; i < products.length; i++) {
                    const product = products[i];
                    cols.push(<ImageGridCol key={`prod-col-${i}`}><PhotoCard index={i} 
                        onCardClicked={this.props.onCardClicked} product={product} 
                        {...this.props.eventHandlers} /></ImageGridCol>)
                }

                return cols;
            };
        }

        return (
            <CSSTransition
                in={this.state.transition}
                timeout={1100}
                classNames="products-grid-transition">
                <ImageGridRow>{makeCards()}</ImageGridRow>
            </CSSTransition>
        )
    }
}

const PhotoCard = (props) => {
    const handleClick = (e) => {
        props.onCardClicked(props.index);
    };

    const onEdit = (e) => {
        e.preventDefault();
        if (props.onEdit)
            props.onEdit(props.product);
    };

    const onDelete = (e) => {
        e.preventDefault();
        if (props.onDelete)
            props.onDelete(props.product);
    };

    return (
        <div className="card product-card p-0">
            <img src={props.product.img_url} className="card-img-top image" alt="Product" />

            <div className="card-img-overlay text-center">
                <div className="w-100 h-100" onClick={handleClick}></div>
                <div className="options">
                    <span className="actions float-right text-white">
                        <a onClick={onEdit}><span className="fas fa-edit align-bottom"></span></a>
                        <a onClick={onDelete}><span className="fas fa-trash ml-3 align-bottom"></span></a>
                    </span>
                </div>
            </div>
        </div>
    );
}

const PhotographerPage = ({ getNavBarHeight, ignoreScrollChange, photographerData, getPhotographer, getPhotographerPhotos, 
    getPhotographerPhotosCount, getCategories, product_categories, photos, photosCount,
    cart, addToCart, removeFromCart, authenticateUser }) => {
    const queries = new useQuery();
    const {photographer} = useParams();

    const productsPerPage = 30;
    
    const [categories, setCategories] = useState(product_categories);
    const [category, setCategory] = useState(queries.get('category') || 'All');
    const [sort, setSort] = useState(queries.get('sort') || 'featured');
    const [searchKeyword, setSearchKeyword] = useState(queries.get('keyword') || '');
    const [products, setProducts] = useState([]);
    const [productsCount, setProductsCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [currentOffset, setCurrentOffset] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [verified, setVerified] = useState(false);

    const history = useHistory();

    useEffect(() => {
        ignoreScrollChange();

        // Fetch the categories if it is not availaible
       if (!categories || categories.length === 0) {
           getCategories();
       }
    }, [categories, getCategories, getPhotographer, ignoreScrollChange, photographer]);

    useEffect(() => {
        if (!verified)
            getPhotographer(photographer);
    }, [photographer, verified, getPhotographer]);

    useEffect(() => {
        if (photographerData.available === true && !verified) {
            if (photographerData.data === null) {
                ReactSwal.fire({
                    icon: 'error',
                    html: <div><span className="montserrat-font">{photographer}</span> is not an existing photographer!</div>,
                    timer: 3000,
                    onAfterClose: () => history.push('/'),
                });
            }
    
            else {
                dismissModal();
                setVerified(true);
                
                if (!isLoading) {
                    setIsLoading(true);
                    getPhotographerPhotosCount(photographer, category, sort, searchKeyword);
                }
            }
        }

    }, [photographerData, history, photographer, verified, isLoading, category, 
            sort, searchKeyword, getPhotographerPhotosCount]);

    useEffect(() => {
        setCategories(product_categories);
    }, [product_categories]);

    useEffect(() => {
        const newCategory = queries.get('category') || 'All';
        const newSort = queries.get('sort') || 'featured';
        setCategory(newCategory);
        setSort(newSort);
        
    }, [queries]);

    useEffect(() => {
        setIsLoading(true);
        getPhotographerPhotosCount(photographer, category, sort, searchKeyword);
    }, [category, sort, searchKeyword, getPhotographerPhotosCount, photographer]);

    useEffect(() => {
        if (photosCount.value === constants.flags.ERROR) {
            setIsLoading(false);
            showError('Oops', 'An error occured, please try again');
        }
        else if (photosCount.value === constants.flags.NO_CONTENT) {
            setIsLoading(false);
            setProducts([]);
        }
        else {
            setProductsCount(photosCount.value);
        }
    }, [photosCount]);

    useEffect(() => {
        if (productsCount > 0) {
            getPhotographerPhotos(photographer, category, sort, searchKeyword, currentOffset, productsPerPage);
        }
    }, [productsCount, isLoading, category, sort, searchKeyword, currentOffset, productsPerPage, getPhotographerPhotos, photographer]);

    useEffect(() => {
        setProducts(photos.data);
        if (photos.data.length > 0)
            setIsLoading(false);
    }, [photos.data]);

    useEffect(() => {
        const navbarHeight = getNavBarHeight();
        $('.shop-container').css('marginTop', `${navbarHeight}px`);

        showDropMenuOnHover();
    });

    const showDropMenuOnHover = () => {
        const $dropdown = $(".dropdown");
        const $dropdownToggle = $("dropdown-toggle");
        const $dropdownMenu = $(".dropdown-menu");
        const showClass = "show";
        
        if (window.matchMedia("(min-width: 768px)").matches) {
            $dropdown.hover(
                function() {
                    const $this = $(this);
                    $this.addClass(showClass);
                    $this.find($dropdownToggle).attr("aria-expanded", "true");
                    $this.find($dropdownMenu).addClass(showClass);
                },
                function() {
                    const $this = $(this);
                    $this.removeClass(showClass);
                    $this.find($dropdownToggle).attr("aria-expanded", "false");
                    $this.find($dropdownMenu).removeClass(showClass);
                }
            );
        } else {
            $dropdown.off("mouseenter mouseleave");
        }
    };

    const paginatorClicked = data => {
        const selected = data.selected;
        const offset = Math.ceil(selected * productsPerPage);
        const currentPage = selected;

        setCurrentPage(currentPage);
        setCurrentOffset(offset);

        setIsLoading(true);
        getPhotographerPhotos(photographer, category, sort, searchKeyword, currentOffset, productsPerPage);

        $('html, body').animate({
            scrollTop: 0
        }, 800);
    };

    const withCategory = theCategory => {
        return `?category=${theCategory}&sort=${sort}&keyword=${searchKeyword}`;
    };

    const withSort = theSort => {
        return `?category=${category}&sort=${theSort}&keyword=${searchKeyword}`;
    };

    const generateCategoryLink = (title) => {
        const uri = withCategory(title);
        return (
            <NavLink to={uri} key={`cat-link-${Math.random() + Math.random()}`} className="dropdown-item" isActive={
                (match, location) => {
                    if (location.search === uri.replace(location.pathname, ''))
                        return true;

                    return false;
                }
            }>{title}</NavLink>
        );
    };

    const generateSortLink = (link, title) => {
        const uri = withSort(link);
        return (
            <NavLink to={uri} className="dropdown-item" isActive={
                (match, location) => {
                    if (location.search === uri)
                        return true;

                    return false;
                }
            }>{title}</NavLink>
        );
    };

    const handleSearchKeywordChanged = e => {
        setSearchKeyword(e.target.value ? e.target.value : '');
    };

    const searchPhotos = e => {
        e.preventDefault();
        
        setIsLoading(true);
        getPhotographerPhotosCount(photographer, category, sort, searchKeyword);
    };

    const onCardClicked = (currentIndex) => {
        if (products.length > 0) {
            utils.showProductsModal(products, currentIndex,
                doDownloadAlt, collectProduct, followPhotographer);
        }
    };

    const addProductToCart = (product) => {
        if (!isInCart(product)) {
            utils.verifyUserSession(authenticateUser, 
                () => {
                    utils.verifyProductInCollection(product,
                        () => {
                            utils.showInfo('This product is already in your collection!', '', 3000);
                        },
                        () => {
                            addToCart(product);
                            utils.showAddedToCart();
                        });
                },
                () => {
                    ReactSwal.fire({
                        title: 'You need to login first!',
                        icon: 'info',
                        showConfirmButton: false,
                        html: <utils.AuthComponent onLogin={redirectToLogin} 
                            onCreateAccount={redirectToCreateAccount}/>
                    });
                });
        }
        else {
            utils.showAlreadyInCart();
        }
    };

    const downloadProduct = (product) => {
        utils.verifyUserSession(authenticateUser, 
            () => {
                utils.initiatePurchase(product, 
                    (collection_id) => {
                        doDownload(product, collection_id);
                    },
                    (collection_id) => {
                        doDownload(product, collection_id);
                    });
            },
            () => {
                ReactSwal.fire({
                    title: 'You need to login first!',
                    icon: 'info',
                    showConfirmButton: false,
                    html: <utils.AuthComponent onLogin={redirectToLogin} 
                        onCreateAccount={redirectToCreateAccount}/>
                });
            });
    };

    const doDownload = (product, collection_id, width = 0, height = 0) => {
        const img = product.img_url;
        const filename = product.name + img.substring(img.indexOf('.'), img.length);
        utils.download(collection_id, filename, width, height);

        // Remove this product from cart if it is there
        removeFromCart(product);
    };

    const doDownloadAlt = (product, width, height) => {
        utils.verifyUserSession(authenticateUser, 
            () => {
                utils.initiatePurchase(product, 
                    (collection_id) => {
                        doDownload(product, collection_id, width, height);
                    },
                    (collection_id) => {
                        doDownload(product, collection_id, width, height);
                    });
            },
            () => {
                ReactSwal.fire({
                    title: 'You need to login first!',
                    icon: 'info',
                    showConfirmButton: false,
                    html: <utils.AuthComponent onLogin={redirectToLogin} 
                        onCreateAccount={redirectToCreateAccount}/>
                });
            });
    };

    const collectProduct = (product) => {
        utils.verifyUserSession(authenticateUser, 
            () => {
                utils.initiatePurchase(product, 
                    (collection_id) => {
                        utils.showSuccess('Photo added to collection!', '', 3000);

                        // Remove this product from cart if it is there
                        removeFromCart(product);
                    },
                    (collection_id) => {
                        utils.showInfo('This photo is already in your collection!', '', 3000);
                    });
            },
            () => {
                ReactSwal.fire({
                    title: 'You need to login first!',
                    icon: 'info',
                    showConfirmButton: false,
                    html: <utils.AuthComponent onLogin={redirectToLogin} 
                        onCreateAccount={redirectToCreateAccount}/>
                });
            });
    };

    const followPhotographer = (photographer_username) => {
        utils.verifyUserSession(authenticateUser, 
            () => {
                utils.followPhotographer(photographer_username,
                    () => {
                        utils.showSuccess('You are now following ' + photographer_username, '', 3000);
                    },
                    () => {
                        utils.showInfo('You are already following ' + photographer_username, '', 3000);
                    });
            },
            () => {
                ReactSwal.fire({
                    title: 'You need to login first!',
                    icon: 'info',
                    showConfirmButton: false,
                    html: <utils.AuthComponent onLogin={redirectToLogin} 
                        onCreateAccount={redirectToCreateAccount}/>
                });
            });
    };

    const viewPhotographer = (username) => {
        history.push('/@' + username);
    };

    const redirectToLogin = () => {
        ReactSwal.close();
        history.push('/user/login');
    };

    const redirectToCreateAccount = () => {
        ReactSwal.close();
        history.push('/user/register');
    };

    const isInCart = (product) => {
        for (let i = 0; i < cart.length; i++) {
            const p = cart[i];
            if (p.name === product.name)
                return true;
        }

        return false;
    }

    if (!verified) {
        if (!ReactSwal.isVisible())
            showLoading();
        return null;
    }

    return (
        <div className="shop-container">
            <ScrollToTopOnMount />

            <div>
                <div className="container-fluid p-4 pb-5">
                    <div className="row photographer-details-container shadow-sm">
                        <div className="col-md-4 p-0 flex-grow-1">
                            <img src={photographerData.data.photographer_avatar} 
                                alt="Photographer" className="img-fluid rounded-left"/>
                        </div>

                        <div className="col-md p-0 flex-grow-1">
                            <h5 className="montserrat-font text-left p-3 bg-dark text-light">
                                {photographerData.data.first_name} {photographerData.data.last_name} 
                                <small className="roboto-font text-secondary ml-2">@{photographer}</small>
                            </h5>
                            
                            <div className="mx-3 p-3 nanum-gothic-font mt-4 photographer-bio shadow-sm">
                                <p>
                                    {photographerData.data.bio}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="shadow-lg mx-n3">
                        <div className="photographer-stats jumbotron p-3 pb-2 m-0 roboto-font">
                            <div className="row">
                                <div className="col-sm">
                                    <button className="btn btn-dark rounded-lg btn-block" onClick={e => followPhotographer(photographer)}>
                                        <span className="far fa-user mr-1"></span> Follow <span className="ml-3 badge badge-pill badge-info">
                                            {photographerData.data.followers}
                                        </span>
                                    </button>
                                </div>

                                <div className="col-sm mt-2 mt-md-0">
                                    <button className="btn btn-dark rounded-lg btn-block"
                                        onClick={e => {
                                            utils.Scroll.scroller.scrollTo('photographs-section', {
                                                duration: 500,
                                                delay: 0,
                                                smooth: true,
                                            });
                                        }}>
                                        <span className="far fa-eye mr-1"></span> Views <span className="ml-3 badge badge-pill badge-info">
                                            {photographerData.data.views}
                                        </span>
                                    </button>
                                </div>

                                <div className="col-sm-9 d-none d-md-block">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="jumbotron p-4 custom-bg-light">
                    <utils.Scroll.Element name="photographs-section"/>
                    <h4 className="text-center montserrat-font bg-light p-3">Photographs</h4>

                    <div className="filter-container container mx-auto">
                        <div className="row">
                            <div className="col-sm">
                                <div className="dropdown w-100">
                                        <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                            Category <span className="fas fa-chevron-down float-right"></span>
                                        </a>

                                        <div className="dropdown-menu roboto-font">
                                            {generateCategoryLink('All')}
                                            {!categories ? '' : categories.map((category) => generateCategoryLink(category.category))}
                                        </div>
                                    </div>
                            </div>

                            <div className="col-sm mt-4 mt-md-0">
                                <div className="dropdown w-100">
                                    <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                        Sort <span className="fas fa-chevron-down float-right"></span>
                                    </a>

                                    <div className="dropdown-menu roboto-font">
                                        {generateSortLink('featured', 'Featured')}
                                        {generateSortLink('price-ascending', 'Price, low to high')}
                                        {generateSortLink('price-descending', 'Price, high to low')}
                                        {generateSortLink('ascending', 'A - Z')}
                                        {generateSortLink('descending', 'Z - A')}
                                        {generateSortLink('date-descending', 'Date, new to old')}
                                        {generateSortLink('date-ascending', 'Date, old to new')}
                                        {generateSortLink('popular', 'Popular')}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <form onSubmit={searchPhotos}>
                            <div className="row search-bar">
                                <div className="col">
                                    <div className="input-group">
                                        <input type="search" className="form-control bg-light shadow-lg" 
                                            placeholder={`Search ${photographer} photos`}
                                            onChange={handleSearchKeywordChanged}/>
                                        <button type="submit" className="btn btn-dark"><span className="fas fa-search"></span></button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="my-5 px-md-3">
                    {isLoading ? <Loading /> : <PagePhotosGrid onCardClicked={onCardClicked}  
                        eventHandlers={{'addToCart': addProductToCart, 'download': downloadProduct, 
                            'viewPhotographer': viewPhotographer}} key="shop-grid" products={products}/>}
                    { (isLoading || productsCount === 0 || productsCount <= productsPerPage) ? '' :  
                        generatePagination(Math.ceil(productsCount / productsPerPage), paginatorClicked,
                            2, 2, <span className="fas fa-chevron-left"></span>, <span className="fas fa-chevron-right"></span>,
                            currentPage)
                    }
                </div>
            </div> 
        </div>
    );
};

/* Map States and Dispatchs To Props */
const getPhotographerPlans = creators.misc.getPhotographerPlans;
const getPhotos = creators.photographer.getPhotos;
const getPhotosCount = creators.photographer.getPhotosCount;
const getCategories = creators.misc.getCategories;
const authenticateUser = creators.app.authenticate;
const addToCart = creators.app.addToCart;
const removeFromCart = creators.app.removeFromCart;
const getPhotographer = creators.photographer.getPhotographer;
const getPhotographerPhotosCount = creators.photographer.getPhotographerPhotosCount;
const getPhotographerPhotos = creators.photographer.getPhotographerPhotos;
const updateDashboard = creators.photographer.updateDashboard;
const removeDashboard = creators.photographer.removeDashboard;

// Dashboard
const mapStateToDashboardProps = (state) => {
    return {
        dashboard: {...state.photographerReducer.dashboard},
    };
};

const mapDispatchToDashboardProps = {
    authenticateUser, removeDashboard,
};

// Photos
const mapStateToPhotosProps = (state) => {
    return {
        product_categories: [...state.miscReducer.categories],
        photos: {...state.photographerReducer.photos},
        photosCount: {...state.photographerReducer.photosCount},
    };
};

const mapDispatchToPhotosProps = {
    getPhotos, getPhotosCount, getCategories,
};

// AddPhotos
const mapStateToAddPhotosProps = (state) => {
    return {
        product_categories: [...state.miscReducer.categories],
    };
};

const mapDispatchToAddPhotosProps = {
    getCategories,
};

// Login
const mapDispatchToLoginProps = {
    authenticateUser, updateDashboard, removeDashboard
};

// EditProfile
const mapStateToEditProfileProps = (state) => {
    return {
        dashboard: {...state.photographerReducer.dashboard},
    };
};

const mapDispatchToEditProfileProps = {
    updateDashboard,
};

// Page
const mapStateToPageProps = (state) => {
    return {
        product_categories: [...state.miscReducer.categories],
        photos: {...state.photographerReducer.photographer.photos},
        photosCount: {...state.photographerReducer.photographer.photosCount},
        photographerData: {...state.photographerReducer.photographer},
        cart: [...state.appReducer.cart],
    };
};

const mapDispatchToPageProps = {
    getCategories, addToCart, removeFromCart, authenticateUser,
    getPhotographer, getPhotographerPhotosCount, getPhotographerPhotos
};

const mapStateToProps = (state) => {
    return {
        photographer_plans: [...state.miscReducer.photographer_plans],
    };
};

const ConnectedPlans = connect(mapStateToProps, {...mapDispatchToLoginProps, getPhotographerPlans})(Plans);
const ConnectedDowngrade = connect(null, mapDispatchToLoginProps)(Downgrade);
const ConnectedPhotos = connect(mapStateToPhotosProps, mapDispatchToPhotosProps)(Photos);
const ConnectedAddPhotos = connect(mapStateToAddPhotosProps, mapDispatchToAddPhotosProps)(AddPhotos);
const ConnectedEditProfile = connect(mapStateToEditProfileProps, mapDispatchToEditProfileProps)(EditProfile);
export const ConnectedLogin = connect(null, mapDispatchToLoginProps)(Login);
export const ConnectedLogout = connect(null, mapDispatchToLoginProps)(Logout);
export const ConnectedDashboard = connect(mapStateToDashboardProps, mapDispatchToDashboardProps)(Dashboard);
export const ConnectedPhotographerPage = connect(mapStateToPageProps, mapDispatchToPageProps)(PhotographerPage);