import * as actions from '../actions/constants';

const initialState = {
    productsCount: 0,
    
    featuredProducts: [],
    latestProducts: [],
    popularProducts: [],
};

export function indexReducer(state = initialState, action) {
    if (action.type === actions.index.GET_PRODUCTS_COUNT) {
        const newState = {...state};
        newState.productsCount = action.payload;
        return newState;
    }

    if (action.type === actions.index.LOAD_FEATURED_PRODUCTS) {
        const newState = {...state};
        newState.featuredProducts = action.payload;
        return newState;
    }

    if (action.type === actions.index.LOAD_LATEST_PRODUCTS) {
        const newState = {...state};
        newState.latestProducts = action.payload;
        return newState;
    }

    if (action.type === actions.index.LOAD_POPULAR_PRODUCTS) {
        const newState = {...state};
        newState.popularProducts = action.payload;
        return newState;
    }

    return state;
};