/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, Component } from 'react'
import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router'
import { HashRouter, NavLink } from 'react-router-dom';
import { ScrollToTopOnMount, generatePagination, 
    axiosInstance, showError, showLoading, showNetworkError,
    dismissModal, 
    showSuccess,
    ImageGridCol,
    ImageGridRow,
    Loading,
    ReactSwal} from './utils';
import $ from 'jquery';
import Swal from 'sweetalert2';
import * as creators from '../redux/actions/creators';
import { connect } from 'react-redux';
import * as constants from '../redux/actions/constants';
import { CSSTransition } from 'react-transition-group';
import * as utils from './utils';
import ReCAPTCHA from "react-google-recaptcha";

const Authorisation = {
    authenticate(onRespond, history, clearDashboardDispatcher) {
        onRespond(false);

      // Check if there is an existing session
      if (sessionStorage.getItem('admin_data')) {
        // Validate the session data
        
        const session_data = JSON.parse(sessionStorage.getItem('admin_data'));

        const axios = axiosInstance();
        axios.post('admin/session', session_data)
        .then((response) => {
            if (response.status === 200 || response.status === 204) {
                // The session is still valid
                onRespond(true);
            }
            else {
                // Invalid session
                // Clear any session data related to auth
                dismissModal();
                sessionStorage.removeItem('auth');
                clearDashboardDispatcher();
                history.push('/admin/login');
            }
        })
        .catch((error) => {
            // Clear any session data related to auth
            sessionStorage.removeItem('auth');
            clearDashboardDispatcher();

            Swal.fire({
                title: 'Authentication Error!',
                text: 'We could not validate your current session, please login again',
                icon: 'error',
                confirmButtonText: 'Login',
                timer: 2000,
                onAfterClose: () => {
                    history.push('/admin/login');
                }
            });
        });
    }
    else {
        // If there is no current valid session, redirect to login page
        // Clear any session data related to auth
        dismissModal();
        sessionStorage.removeItem('auth');
        clearDashboardDispatcher();
        history.push('/admin/login');
    }
    },
    logout() {
      // Clear the current session
      sessionStorage.removeItem('admin_data');

      // Clear any session data related to auth
      sessionStorage.removeItem('auth');

      // Make a network request to clear the current sesssion
      const axios = axiosInstance();
      axios.delete('admin/session');
    }
};

const Login = ({ignoreScrollChange, authenticateUser, updateDashboard}) => {
    const history = useHistory();
    const [recaptchaDone, setRecaptchaDone] = useState(false);

    useEffect(() => {
        ignoreScrollChange();
    }, [ignoreScrollChange]);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    
    const handleLogin = e => {
        e.preventDefault();

        if (!recaptchaDone) {
            showError('', 'Please complete the reCaptcha challenge!');
            return;
        }

        showLoading();

        const axios = axiosInstance();
        axios.post('admin/login', {}, {
            auth: {
                username: username,
                password: password,
            }
        })
        .then((response) => {
            if (response.status === 200) {
                const data = JSON.stringify(response.data);
                sessionStorage.setItem('admin_data', data);
                sessionStorage.removeItem('user_data');
                sessionStorage.removeItem('photographer_data');
                authenticateUser(true, '/admin/dashboard', '/admin/logout');
                updateDashboard(response.data);
                history.push('/admin/dashboard');
            }
            else {
                dismissModal();
                showError('Authentication Error!', 'Wrong username or password');
            }
        })
        .catch((error) => {
            dismissModal();
            showNetworkError();
        });
    };

    const onRecaptcha = status => {
        if (status) {
            setRecaptchaDone(true);
        }
        else {
            setRecaptchaDone(false);
        }
    };

    return (
        <div className="login roboto-font photographer-container">
            <ScrollToTopOnMount />

            <div className="container text-center shadow-lg px-0">
                <h3 className="header montserrat-font bg-dark text-white p-4 mb-0">Admin Login</h3>

                <div className="form-container jumbotron bg-light">
                    <form onSubmit={handleLogin}>
                        <div className="input-group row mb-3">
                            <div className="col-sm mb-3 mb-md-0">
                                <input type="text" placeholder="Username" className="form-control" value={username} 
                                    onChange={e => setUsername(e.target.value)} maxLength="12" required/>
                            </div>
                            <div className="col-sm">
                                <input type="password" placeholder="Password" className="form-control" 
                                    onChange={e => setPassword(e.target.value)} required/>
                            </div>
                        </div>

                        <div className="input-group row mb-3 text-left">
                            <div className="col">
                                <ReCAPTCHA 
                                sitekey={utils.recaptchaKey}
                                onChange={onRecaptcha}/>
                            </div>
                        </div>

                        <div className="mt-5 text-left">
                            {recaptchaDone ? <button type="submit" className="btn btn-lg btn-dark">Login <span className="fas fa-arrow-right ml-5"></span></button> :
                            <button type="submit" className="btn btn-lg btn-dark disabled" disabled>Login <span className="fas fa-arrow-right ml-5"></span></button>}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

const Logout = ({ authenticateUser, removeDashboard }) => {
    const history = useHistory();

    useEffect(() => {
        Authorisation.logout();
        authenticateUser(false, '', '');
        removeDashboard();
        history.push('/');
    });

    return null;
};

const Dashboard = ({ignoreScrollChange, dashboard, authenticateUser, removeDashboard, getNavBarHeight}) => {
    const history = useHistory();

    const [authenticated, setAuthenticated] = useState(false);
    const [adminData, setAdminData] = useState(null);

    const location = useLocation();

    const showDropMenuOnHover = () => {
        const $dropdown = $(".dropdown");
        const $dropdownToggle = $("dropdown-toggle");
        const $dropdownMenu = $(".dropdown-menu");
        const showClass = "show";
        
        if (window.matchMedia("(min-width: 768px)").matches) {
            $dropdown.hover(
                function() {
                    const $this = $(this);
                    $this.addClass(showClass);
                    $this.find($dropdownToggle).attr("aria-expanded", "true");
                    $this.find($dropdownMenu).addClass(showClass);
                },
                function() {
                    const $this = $(this);
                    $this.removeClass(showClass);
                    $this.find($dropdownToggle).attr("aria-expanded", "false");
                    $this.find($dropdownMenu).removeClass(showClass);
                }
            );
        } else {
            $dropdown.off("mouseenter mouseleave");
        }
    };

    useEffect(() => {
        ignoreScrollChange();
    }, [ignoreScrollChange]);

    useEffect(() => {
        const onAuthenticate = (authorized) => {
            if (authorized) {
                dismissModal();
                setAdminData(dashboard);
                authenticateUser(true, '/admin/dashboard', '/admin/logout');
            }
            else
                showLoading();
    
            setAuthenticated(authorized);
        };

        // Authenticate whenever there is a change in location
        Authorisation.authenticate(onAuthenticate, history, removeDashboard);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    useEffect(() => {
        setAdminData(dashboard);
    }, [dashboard]);

    useEffect(() => {
        const navbarHeight = getNavBarHeight();
        $('div.dashboard-wrapper').css('marginTop', `${navbarHeight}px`);

        $('#sidebar-toggle').click(() => {
            $('#sidebar').toggleClass('open');
        });

        showDropMenuOnHover();
    });
    
    return !authenticated ? '' : (
        <HashRouter>
            <div className="roboto-font">
                
                <div className="dashboard-wrapper">
                    {/* Sidebar */}
                    <nav id="sidebar">
                        <div className="header text-white container-fluid">
                            <div className="row">
                                <div className="col">
                                    <h6 className="mb-0 name">PhotoStock NG</h6>
                                    <small className="username">@{adminData.username}</small>
                                </div>
                            </div>
                        </div>

                        <div className="options">
                            <div className="links-container">
                                <h5 className="group-title">Management</h5>
                                <div className="row link-container">
                                    <div className="col-2"><span className="fas fa-tools"></span></div>
                                    <div className="col"><NavLink to="unapproved-photos" className="link">Manage Photos</NavLink></div>
                                </div>
                                <div className="row link-container">
                                    <div className="col-2"><span className="fas fa-plus"></span></div>
                                    <div className="col"><NavLink to="add-category" className="link">Add Category</NavLink></div>
                                </div>
                            </div>
                        </div>
                    </nav>

                    {/* Dashboard Content */}
                    <div id="content">
                        <div className="title-bar">
                                <button className="sidebar-toggle btn btn-dark" id="sidebar-toggle"><span className="fas fa-list"></span></button>
                        </div>

                        <div className="main pb-5">
                            <Switch>
                                <Route path="/unapproved-photos">
                                    <ConnectedUnapprovedPhotos />
                                </Route>

                                <Route path="/add-category">
                                    <AddCategory />
                                </Route>

                                <Route path="/">
                                    <Redirect to="unapproved-photos" />
                                </Route>
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        </HashRouter>
    );
};

const UnapprovedPhotos = ({ getPhotos, getPhotosCount, photos, photosCount }) => {
    const productsPerPage = 100;
    
    const [products, setProducts] = useState([]);
    const [productsCount, setProductsCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [currentOffset, setCurrentOffset] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const history = useHistory();

    useEffect(() => {
        setIsLoading(true);
        getPhotosCount();
    }, [getPhotosCount]);

    useEffect(() => {
        if (photosCount.value === constants.flags.ERROR) {
            setIsLoading(false);
            showError('Oops', 'An error occured, please try again');
        }
        else if (photosCount.value === constants.flags.NO_CONTENT) {
            setIsLoading(false);
            setProducts([]);
        }
        else {
            setProductsCount(photosCount.value);
        }
    }, [photosCount]);

    useEffect(() => {
        if (productsCount > 0) {
            getPhotos();
        }
    }, [productsCount, getPhotos]);

    useEffect(() => {
        setProducts(photos.data);
        if (photos.data.length > 0)
            setIsLoading(false);
    }, [photos.data]);

    const paginatorClicked = data => {
        const selected = data.selected;
        const offset = Math.ceil(selected * productsPerPage);
        const currentPage = selected;

        setCurrentPage(currentPage);
        setCurrentOffset(offset);

        setIsLoading(true);
        getPhotos();

        $('html, body').animate({
            scrollTop: 0
        }, 800);
    };

    const onCardClicked = (currentIndex) => {
    };

    const doDelete = (product) => {
        utils.deleteProductFromUnapproved(product,
            () => {
                utils.ReactSwal.fire({
                    icon: 'success',
                    title: 'Photo deleted!',
                    timer: 2000,
                    onAfterClose: () => {
                        setIsLoading(true);
                        getPhotosCount();
                    }
                });
            },
            () => showError('Oops!', 'The photo was not deleted, please try again')
        );
    };

    const onDelete = (product) => {
        utils.ReactSwal.fire({
            icon: 'question',
            showConfirmButton: false,
            showCloseButton: true,
            html: (
                <div className="montserrat-font">
                    Do you really want to delete <span className="text-warning">{product.name}</span> from the list?<br/><br/>
                    This cannot be reversed!

                    <button className="btn btn-block btn-danger mt-5" onClick={e => doDelete(product)}>Delete</button>
                </div>
            )
        });
    };

    const approveProduct = (product) => {
        showLoading();

        utils.axios.post('admin/unapproved_photo/' + product.id)
        .then((response) => {
            dismissModal();
            if (response.status === 204) {
                utils.ReactSwal.fire({
                    icon: 'success',
                    title: 'Photo approved!',
                    timer: 2000,
                    onAfterClose: () => {
                        setIsLoading(true);
                        getPhotosCount();
                    }
                });
            }
            else if (response.status === 400) { //Validation Error
                ReactSwal.fire({
                    title: 'Validation Error!',
                    html: response.data.messages.error,
                    icon: 'error',
                    confirmButtonText: 'Retry'
                });
            }
            else if (response.status === 401) {
                ReactSwal.fire({
                    title: 'Authentication Error!',
                    html: 'Your session timed out, please login again',
                    icon: 'error',
                    confirmButtonText: 'Login',
                    timer: 3000,
                    onAfterClose: () => history.push('/admin/login'),
                });
            }
            else {
                showNetworkError();
            }
        })
        .catch((error) => {
            showNetworkError();
        })
    };

    return (
        <div>
            <div>
                <div className="my-5 px-1">
                    {isLoading ? <Loading /> : <PhotosGrid onCardClicked={onCardClicked} key="shop-grid" products={products}
                        eventHandlers={{'onDelete': onDelete, 'onApprove': approveProduct}}/>}
                    { (isLoading || productsCount === 0 || productsCount <= productsPerPage) ? '' :  
                        generatePagination(Math.ceil(productsCount / productsPerPage), paginatorClicked,
                            2, 2, <span className="fas fa-chevron-left"></span>, <span className="fas fa-chevron-right"></span>,
                            currentPage)
                    }
                </div>
            </div> 
        </div>
    );
};

const AddCategory = () => {
    const [photoFile, setPhotoFile] = useState(null);
    
    const history = useHistory();

    const [data, setData] = useState({
        title: '',
    });

    const handleInputChanged = e => {
        const name = e.target.name;
        const value = e.target.value;
        data[name] = value;
        setData({...data});
    };

    const handleFileChanged = e => {
        setPhotoFile(e.target.files[0]);
    };

    const handleAddCategory = e => {
        e.preventDefault();
        
        const uploadTask = (updateProgress) => {
            const axios = axiosInstance();

            const formData = new FormData();

            formData.append('photo', photoFile)
            formData.append('category', data.title);
            
            axios.post('admin/category', formData, {
                headers: {
                    'content-type': 'multipart/form-data'
                },
                onUploadProgress: (ev) => {
                    const progress = ev.loaded / ev.total * 100;
                    updateProgress(Math.round(progress));
                },
            })
            .then((response) => {
                dismissModal();
                if (response.status === 204) {
                    showSuccess('Category added!');
                }
                else if (response.status === 400) { //Validation Error
                    ReactSwal.fire({
                        title: 'Validation Error!',
                        html: response.data.messages.error,
                        icon: 'error',
                        confirmButtonText: 'Retry'
                    });
                }
                else if (response.status === 401) {
                    ReactSwal.fire({
                        title: 'Authentication Error!',
                        html: 'Your session timed out, please login again',
                        icon: 'error',
                        confirmButtonText: 'Login',
                        timer: 3000,
                        onAfterClose: () => history.push('/admin/login'),
                    });
                }
                else {
                    showNetworkError();
                }
            })
            .catch((error) => {
                dismissModal();
                showNetworkError();
            });
        };

        ReactSwal.fire({
            title: 'Uploading...',
            html: <utils.ProgressModal task={uploadTask}/>,
            allowOutsideClick: false,
            allowEnterKey: false,
            allowEscapeKey: false,
            showConfirmButton: false,
        });
    };

    useEffect(() => {
        // displays the name of the file selected from the file browser
        $(".custom-file-input").on("change", function()
        {
        var fileName = $(this).val().split("\\").pop();
        $(this).siblings(".custom-file-label").addClass("selected").html(fileName);
        });
    });

    return (
        <div className="container mt-5">
            <ScrollToTopOnMount />

            <div className="shadow-lg">
                <h4 className="bg-dark text-white montserrat-font text-center p-3 mb-5">Add Category</h4>

                <form className="m-4 pb-5" onSubmit={handleAddCategory}>
                    <div className="input-group">
                        <input type="text" className="form-control" placeholder="Category title" name="title" value={data.title} 
                            onChange={handleInputChanged} maxLength="60" required/>
                    </div>
                    
                    <div className="input-group mt-3">
                        <div className="custom-file">
                            <input type="file" className="custom-file-input" id="customFile" onChange={handleFileChanged} required/>
                            <label className="custom-file-label text-left">Choose Photo File</label>
                        </div>
                    </div>

                    <div className="mt-5 text-left">
                        <button type="submit" className="btn btn-lg btn-dark">Upload <span className="fas fa-arrow-right ml-5"></span></button>
                    </div>
                </form>
            </div>    
        </div>
    );
};

class PhotosGrid extends Component {
    constructor(props) {
        super(props);

        this.state = {transition: false};
    }

    componentDidMount() {
        this.setState({transition: true})
    }

    render() {
        const products = this.props.products;
        if (!products || products.length === 0)
            return <h2 className="m-4 roboto-font text-center">No unapproved photos found!</h2>;

        const perRow = 3;

        let remainder = products.length % perRow;

        let makeCards;

        if (products.length < perRow)
        {
            makeCards = () => {
                let cols = [];
                
                for (let i = 0; i < products.length; i++) {
                    const product = products[i];
                    cols.push(<ImageGridCol key={`prod-col-${i}`}><PhotoCard index={i} 
                        onCardClicked={this.props.onCardClicked} product={product} 
                            {...this.props.eventHandlers}/></ImageGridCol>)
                }

                return cols;
            };
        }
        else if (remainder === 0)
        {
            makeCards = () => {
                let cols = [[], [], []];
                
                for (let i = 0; i < products.length; i += perRow) {
                    
                    for (let j = 0; j < perRow; j++) {
                        const product = products[i+j];
                        cols[j].push(<PhotoCard index={i+j} 
                            onCardClicked={this.props.onCardClicked} product={product} {...this.props.eventHandlers}
                            key={`prod-${i+j}`}/>);
                    }
                }

                let rows = [];
                for (let i = 0; i < perRow; i++)
                    rows.push(<ImageGridCol key={`prod-col-${i}`}>{cols[i]}</ImageGridCol>);

                return rows;
            };
        }
        else
        {
            makeCards = () => {
                let cols = [[], [], []];
                const evenSize = products.length - remainder;
                
                for (let i = 0; i < evenSize; i += perRow) {
                    for (let j = 0; j < perRow; j++) {
                        const product = products[i+j];
                        cols[j].push(<PhotoCard index={i+j} 
                            onCardClicked={this.props.onCardClicked} product={product} {...this.props.eventHandlers}
                            key={`prod-${i+j}`}/>);
                    }
                }

                let rows = [];
                for (let i = 0; i < perRow; i++)
                    rows.push(<ImageGridCol key={`prod-col-${i}`}>{cols[i]}</ImageGridCol>);

                for (let i = evenSize; i < products.length; i++) {
                    const product = products[i];
                    rows.push(<ImageGridCol key={`prod-col-${i}`}><PhotoCard index={i} 
                        onCardClicked={this.props.onCardClicked} product={product} 
                        {...this.props.eventHandlers} /></ImageGridCol>)
                }

                return rows;
            };
        }

        return (
            <CSSTransition
                in={this.state.transition}
                timeout={1100}
                classNames="products-grid-transition">
                <ImageGridRow>{makeCards()}</ImageGridRow>
            </CSSTransition>
        )
    }
}

const PhotoCard = (props) => {
    const handleClick = (e) => {
        props.onCardClicked(props.index);
    };

    const onApprove = (e) => {
        e.preventDefault();
        if (props.onApprove)
            props.onApprove(props.product);
    };

    const onDelete = (e) => {
        e.preventDefault();
        if (props.onDelete)
            props.onDelete(props.product);
    };

    return (
        <div className="card product-card p-0">
            <img src={props.product.img_url} className="card-img-top image" alt="Product" />

            <div className="card-img-overlay text-center">
                <div className="w-100 h-100" onClick={handleClick}></div>
                <div className="options">
                    <span className="actions float-right text-white">
                        <a onClick={onApprove}><span className="fas fa-thumbs-up align-bottom"></span></a>
                        <a onClick={onDelete}><span className="fas fa-trash ml-3 align-bottom"></span></a>
                    </span>
                </div>
            </div>
        </div>
    );
}

const getPhotos = creators.admin.getPhotos;
const getPhotosCount = creators.admin.getPhotosCount;
const authenticateUser = creators.app.authenticate;
const updateDashboard = creators.admin.updateDashboard;
const removeDashboard = creators.admin.removeDashboard;

// Dashboard
const mapStateToDashboardProps = (state) => {
    return {
        dashboard: {...state.adminReducer.dashboard},
    };
};

const mapDispatchToDashboardProps = {
    authenticateUser, removeDashboard,
};

const mapDispatchToLoginProps = {
    authenticateUser, updateDashboard, removeDashboard
};

// Photos
const mapStateToPhotosProps = (state) => {
    return {
        photos: {...state.adminReducer.photos},
        photosCount: {...state.adminReducer.photosCount},
    };
};

const mapDispatchToPhotosProps = {
    getPhotos, getPhotosCount
};

const ConnectedUnapprovedPhotos = connect(mapStateToPhotosProps, mapDispatchToPhotosProps)(UnapprovedPhotos);
export const ConnectedLogin = connect(null, mapDispatchToLoginProps)(Login);
export const ConnectedLogout = connect(null, mapDispatchToLoginProps)(Logout);
export const ConnectedDashboard = connect(mapStateToDashboardProps, mapDispatchToDashboardProps)(Dashboard);