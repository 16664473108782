import * as constants from '../actions/constants';

const initialState = {
    photosCount: {value: 0},
    photos: {
        available: false,
        error: false,
        data: [],
    },

    photographer: {
        available: false,
        error: false,
        data: [],

        photosCount: {value: 0},

        photos: {
            available: false,
            error: false,
            data: [],
        },
    },

    dashboard: {},
};

// Check if there is any existing dashboard data in session
if (sessionStorage.getItem('photographer_data')) {
    const session_data = JSON.parse(sessionStorage.getItem('photographer_data'));
    initialState.dashboard = {...session_data};
}

export const photographerReducer = (state = initialState, action) => {
    if (action.type === constants.photographer.UPDATE_DASHBOARD) {
        let newState = {...state};
        newState.dashboard = action.payload;
        return newState;
    }

    if (action.type === constants.photographer.REMOVE_DASHBOARD) {
        let newState = {...state};
        newState.dashboard = {};
        return newState;
    }

    if (action.type === constants.photographer.GET_PHOTOS_COUNT) {
        let newState = {...state};
        newState.photosCount = {value: action.payload};
        return newState;
    }

    if (action.type === constants.photographer.GET_PHOTOS_SUCCESS) {
        let newState = {...state};
        newState.photos.available = true;
        newState.photos.data = action.payload;
        newState.photos.error = null;

        return newState;
    }

    if (action.type === constants.photographer.GET_PHOTOS_FAILED) {
        let newState = {...state};
        newState.photos.available = false;
        newState.photos.data = [];
        newState.photos.error = action.payload;
        
        return newState;
    }

    if (action.type === constants.photographer.GET_PHOTOGRAPHER_PHOTOS_COUNT) {
        let newState = {...state};
        newState.photographer.photosCount = {value: action.payload};
        return newState;
    }

    if (action.type === constants.photographer.GET_PHOTOGRAPHER_PHOTOS_SUCCESS) {
        let newState = {...state};
        newState.photographer.photos.available = true;
        newState.photographer.photos.data = action.payload;
        newState.photographer.photos.error = null;

        return newState;
    }

    if (action.type === constants.photographer.GET_PHOTOGRAPHER_PHOTOS_FAILED) {
        let newState = {...state};
        newState.photographer.photos.available = false;
        newState.photographer.photos.data = [];
        newState.photographer.photos.error = action.payload;
        
        return newState;
    }

    if (action.type === constants.photographer.GET_PHOTOGRAPHER) {
        let newState = {...state};
        newState.photographer.available = false;
        newState.photographer.data = [];
        return newState;
    }

    if (action.type === constants.photographer.GET_PHOTOGRAPHER_SUCCESS) {
        let newState = {...state};
        newState.photographer.available = true;
        newState.photographer.data = action.payload;
        newState.photographer.error = null;

        return newState;
    }

    if (action.type === constants.photographer.GET_PHOTOGRAPHER_FAILED) {
        let newState = {...state};
        newState.photographer.available = true;
        newState.photographer.data = null;
        newState.photographer.error = action.payload;
        
        return newState;
    }

    return state;
};