/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, useState } from 'react'
import $ from 'jquery';
import { Switch, Route, Link, NavLink, Redirect, withRouter } from 'react-router-dom';

import {GridRow, Col, ColSM, ColMD} from './components/bootstrap-grid';
import Index from './components/index';
import TOS from './components/tos';
import Shop from './components/shop';
import * as Photographer from './components/photographer';
import * as  User from './components/user';
import * as  Admin from './components/admin';
import { connect } from 'react-redux';
import * as creators from './redux/actions/creators';
import { ReactSwal, dismissModal } from './components/utils';
import * as utils from './components/utils';

class App extends Component {
  constructor(props) {
    super(props);

    this.navBar = undefined;

    this.state = {
        navClasses: '', 
        auth: this.props.auth, 
        categories: this.props.product_categories,
        search: '',
        loading: true,
    };

    this.ignoreScrollChange = this.ignoreScrollChange.bind(this);
    this.getNavBarHeight = this.getNavBarHeight.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
    this.handleSearchChanged = this.handleSearchChanged.bind(this);
    this.doSearch = this.doSearch.bind(this);
    this.showCart = this.showCart.bind(this);

    this.redirectToLogin = this.redirectToLogin.bind(this);
    this.redirectToCreateAccount = this.redirectToCreateAccount.bind(this);
    this.redirectToDashboard = this.redirectToDashboard.bind(this);
    this.removeFromCart = this.removeFromCart.bind(this);
    this.checkout = this.checkout.bind(this);

    // fetch the categories and plans
    this.props.getCategories();
    this.props.getUserPlans();
    this.props.getPhotographerPlans();
  }

  render() {
    if (this.state.loading)
        return null;

    let navClasses = this.state.navClasses;
    navClasses = "navbar navbar-dark bg-dark text-white navbar-expand-xl px-3 shadow shadow-lg nav-transparent fixed-top " + navClasses;

    return (
      <div>
        <header>
          <nav ref={(el) => {
              this.navBar = el;
              }} className={navClasses} 
              id="generic-navbar">

              <GridRow classes="nav-grid">
                  <ColSM>
                      <button className="navbar-toggler text-light border border-light" type="button" data-toggle="collapse"
                          data-target="#nav-menu">
                          <span className="fas fa-align-right"></span>
                      </button>

                      <Link to="/" className="navbar-brand">
                          <span className="typcn typcn-camera-outline mr-2"></span><h4 className="d-inline title-font logo-text">Photo</h4><h4 className="logo-text-2">Stock NG</h4>
                      </Link>
                  </ColSM>

                  <ColSM size="6" classes="">
                      <GridRow classes="util-bar-sm d-xl-none">
                          <ColMD size="9">
                              <form>
                                  <div className="input-group">
                                      <input placeholder="Search photos" type="search" className="form-control" name="search" value={this.state.search}
                                            onChange={this.handleSearchChanged} required/>
                                      <div className="input-group-append">
                                          <span className="input-group-text fas fa-search align-bottom pt-2"></span>
                                      </div>
                                  </div>
                              </form>
                          </ColMD>

                          <ColMD classes="pt-3 text-right mr-3">
                              <GridRow>
                                  <Col classes="account-col-whitespace" size="8" />

                                  <Col classes="account-col" size="2">
                                      <div className="dropdown account-dropdown">
                                          <a className="dropdown-toggle" href="" data-toggle="dropdown"><span className="far fa-user fa-2x"></span></a>
                                          <div className="dropdown-menu">
                                              { this.state.auth.authenticated ?
                                                <div>
                                                    <Link to={this.state.auth.logout} className="dropdown-item"><span className="fas fa-lock mr-2"></span>Logout</Link>
                                                    <hr className="w-10 bg-black"/>
                                                    <Link to={this.state.auth.dashboard} className="dropdown-item mt-3 my-account-link">My Account <span className="fas fa-chevron-right ml-2"></span></Link>
                                                </div>

                                                :

                                                (
                                                    <div>
                                                        <Link to="/user/login" className="dropdown-item"><span className="fas fa-lock mr-2"></span>Login</Link>
                                                        <Link to="/user/plan" className="dropdown-item"><span className="fas fa-user mr-2"></span>Create Account</Link>
                                                    </div>
                                                )
                                            }
                                          </div>
                                      </div>
                                  </Col>

                                  <Col classes="" size="2">
                                      <a className="cart" onClick={e => this.showCart()}><span className="typcn typcn-shopping-cart font-2x"></span><span className="badge badge-danger">{this.props.cart.length}</span></a>
                                  </Col>
                              </GridRow>
                          </ColMD>
                      </GridRow>
                  </ColSM>
              </GridRow>

              <div id="nav-menu" className="collapse navbar-collapse justify-content-start w-100">
                <ul className="navbar-nav text-white nanum-gothic-font pt-lg-3">
                    <li className="nav-item"><NavLink to="/" className="nav-link" isActive={
                            (match, location) => {
                                if (location.pathname === '/')
                                    return true;

                                return match && match.isExact;
                            }
                        }>Home</NavLink></li>
                    <li className="nav-item"><NavLink to="/shop?category=All&sort=featured&keyword=" className="nav-link">Shop</NavLink></li>

                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" data-toggle="dropdown">Explore <span className="fas fa-chevron-down"></span></a>
                        <div className="dropdown-menu">
                            <Link to="/#/tab-featured" className="dropdown-item mt-2"><span className="fas fa-star"></span>Featured</Link>
                            <Link to="/#/tab-popular" className="dropdown-item mt-2"><span className="fas fa-trophy"></span>Popular</Link>
                            <Link to="/#/tab-latest" className="dropdown-item mt-2"><span className="fas fa-clock"></span>Latest</Link>
                        </div>
                    </li>

                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" data-toggle="dropdown">Categories <span className="fas fa-chevron-down"></span></a>
                        <div className="dropdown-menu">
                            {this.state.categories.map((category) => this.generateCategoryLinkDropdown(category.category))}
                            <Link to="/shop?category=All&sort=featured&keyword=" className="dropdown-item more-link mt-4">More <span className="fas fa-chevron-right ml-1 mt-1"></span></Link>
                        </div>
                    </li>

                    <li className="nav-item"><a className="nav-link" href="" onClick={e => { e.preventDefault(); utils.showContactForm(); }}>Contact</a></li>
                    <li className="nav-item"><Link to="/help" className="nav-link">Help</Link></li>
                </ul>

                <ul className="navbar-nav text-white justify-content-end w-100 mr-3 util-bar-md ml-5">
                    <li className="nav-item w-100">
                        <GridRow>
                            <ColSM classes="p-0" size="9">
                                <form onSubmit={this.doSearch} className="w-100">
                                    <div className="input-group w-100">
                                        <input placeholder="Search photos" type="search" className="form-control" name="search" value={this.state.search}
                                            onChange={this.handleSearchChanged} required/>
                                        <div className="input-group-append">
                                            <span className="input-group-text fas fa-search align-bottom pt-2"></span>
                                        </div>
                                    </div>
                                </form>
                            </ColSM>

                            <ColSM classes="p-0 pt-2 pl-5">
                                <GridRow>
                                    <ColSM classes="account-col" size="5">
                                        <div className="dropdown account-dropdown">
                                            <a className="dropdown-toggle" href="#" data-toggle="dropdown"><span className="far fa-user fa-2x"></span></a>
                                            <div className="dropdown-menu">
                                                { this.state.auth.authenticated ?
                                                    <div>
                                                        <Link to={this.state.auth.logout} className="dropdown-item"><span className="fas fa-lock mr-2"></span>Logout</Link>
                                                        <hr className="w-10 bg-black"/>
                                                        <Link to={this.state.auth.dashboard} className="dropdown-item mt-3 my-account-link">My Account <span className="fas fa-chevron-right ml-2"></span></Link>
                                                    </div>

                                                    :

                                                    (
                                                        <div>
                                                            <Link to="/user/login" className="dropdown-item"><span className="fas fa-lock mr-2"></span>Login</Link>
                                                            <Link to="/user/plan" className="dropdown-item"><span className="fas fa-user mr-2"></span>Create Account</Link>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </ColSM>

                                    <ColSM classes="p-0 pl-3">
                                            <a className="cart" onClick={e => this.showCart()}><span className="typcn typcn-shopping-cart font-2x"></span><span className="badge badge-danger">{this.props.cart.length}</span></a>
                                    </ColSM>
                                </GridRow>
                            </ColSM>
                        </GridRow>
                    </li>
                </ul>
            </div>
          </nav>
        </header>

        <div id="page-contents">
          <main id="content-wrap" className="container-fluid px-0 pt-0 alegreya-r">
              <Switch>
                <Route path="/user/register/:plan">
                    <User.Register ignoreScrollChange={this.ignoreScrollChange}/>
                </Route>

                <Route path="/user/dashboard">
                    <User.ConnectedDashboard getNavBarHeight={this.getNavBarHeight} ignoreScrollChange={this.ignoreScrollChange}/>
                </Route>

                <Route path="/user/register">
                    <Redirect to="/user/register/basic"/>
                </Route>

                <Route path="/user/plan">
                    <User.ConnectedPlan ignoreScrollChange={this.ignoreScrollChange} />
                </Route>

                <Route path="/user/login">
                    <User.ConnectedLogin ignoreScrollChange={this.ignoreScrollChange} />
                </Route>

                <Route path="/user/logout">
                    <User.ConnectedLogout ignoreScrollChange={this.ignoreScrollChange} />
                </Route>

                <Route path="/user/reset_password">
                    <User.PasswordReset ignoreScrollChange={this.ignoreScrollChange} />
                </Route>

                <Route path="/user/activate">
                    <User.Activate ignoreScrollChange={this.ignoreScrollChange} />
                </Route>

                <Route path="/user/unfollow@:photographer">
                    <User.ConnectedUnfollowPhotographer/>
                </Route>

                <Route path="/photographer/register/:plan">
                    <Photographer.Register ignoreScrollChange={this.ignoreScrollChange}/>
                </Route>

                <Route path="/photographer/dashboard">
                    <Photographer.ConnectedDashboard getNavBarHeight={this.getNavBarHeight} ignoreScrollChange={this.ignoreScrollChange}/>
                </Route>

                <Route path="/photographer/register">
                    <Redirect to="/photographer/register/basic"/>
                </Route>
                
                <Route path="/photographer/logout">
                    <Photographer.ConnectedLogout ignoreScrollChange={this.ignoreScrollChange}/>
                </Route>

                <Route path="/photographer/login">
                    <Photographer.ConnectedLogin ignoreScrollChange={this.ignoreScrollChange}/>
                </Route>

                <Route path="/photographer/reset_password">
                    <Photographer.PasswordReset ignoreScrollChange={this.ignoreScrollChange} />
                </Route>

                <Route path="/photographer/activate">
                    <Photographer.Activate ignoreScrollChange={this.ignoreScrollChange} />
                </Route>


                <Route path="/admin/dashboard">
                    <Admin.ConnectedDashboard getNavBarHeight={this.getNavBarHeight} ignoreScrollChange={this.ignoreScrollChange}/>
                </Route>

                <Route path="/admin/logout">
                    <Admin.ConnectedLogout ignoreScrollChange={this.ignoreScrollChange}/>
                </Route>

                <Route path="/admin/login">
                    <Admin.ConnectedLogin ignoreScrollChange={this.ignoreScrollChange}/>
                </Route>


                <Route path="/shop">
                    <Shop getNavBarHeight={this.getNavBarHeight} ignoreScrollChange={this.ignoreScrollChange}/>
                </Route>

                <Route path="/tos">
                    <TOS getNavBarHeight={this.getNavBarHeight} ignoreScrollChange={this.ignoreScrollChange}/>
                </Route>

                <Route path="/@:photographer">
                    <Photographer.ConnectedPhotographerPage ignoreScrollChange={this.ignoreScrollChange} getNavBarHeight={this.getNavBarHeight}/>
                </Route>

                <Route path="/">
                  <Index getNavBarHeight={this.getNavBarHeight}/>
                </Route>

              </Switch>
          </main>

          <footer id="generic-footer">
              <span className="fas fa-chevron-up scroll-to-top scroll-to-top-invisible" onClick={this.scrollToTop}></span>

              <div className="container-fluid">
                <div className="row roboto-font">
                  <div className="col-sm mb-5">
                    <h6 className="mb-3 montserrat-font">CONTACT US</h6>
                    <div className="contents-container">
                      <div><span className="typcn typcn-location-outline mr-2"></span>Mutlivate Inc.</div>
                      <div><span className="typcn typcn-phone-outline mr-2"></span>+234 703 733 5854</div>
                      <div><span className="typcn typcn-mail mr-2"></span>info@photostock.com.ng</div>
                      <div className="social mt-3 px-3">
                        <span className="fab fa-twitter mr-3"></span>
                        <span className="fab fa-facebook mr-3"></span>
                        <span className="fab fa-pinterest mr-3"></span>
                        <span className="fab fa-instagram"></span>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm mb-5">
                    <h6 className="mb-4 montserrat-font">CATEGORIES</h6>
                    <div className="contents-container">
                        {this.state.categories.map((category) => this.generateCategoryLink(category.category))}
                        <Link to="/shop?category=All&sort=featured&keyword=">All Categories</Link>
                    </div>
                  </div>

                  <div className="col-sm mb-5">
                    <h6 className="mb-4 montserrat-font">SERVICES</h6>
                    <div className="contents-container">
                      <Link to="/about">About Us</Link>
                      <Link to="" onClick={e => { e.preventDefault(); utils.showContactForm(); }}>Contact</Link>
                      <Link to="/tos">Terms of Service</Link>
                    </div>
                  </div>
                </div>

                <hr className="divider mb-3" />

                <div className="roboto-font mb-3">
                  Copyright &copy; {new Date().getFullYear()}, PhotoStock NG
                </div>
              </div>
          </footer>
        </div>
      </div>
    );
  }

  componentDidMount() {
        $(window).scroll(() => {
          const scrollHeight = $(window).scrollTop();
          const windowHeight = $(window).height();

          const nav = $(this.navBar);

          if (scrollHeight > 200) {
              if (!nav.hasClass('ignore-scroll-change')) {
                  nav.addClass('nav-opaque');
                  nav.removeClass('nav-transparent');
              }
          }
          else {
              if (!nav.hasClass('ignore-scroll-change')) {
                  nav.removeClass('nav-opaque');
                  nav.addClass('nav-transparent');
              }
          }

          if (scrollHeight < windowHeight) {
            $('.scroll-to-top').addClass('scroll-to-top-invisible');
          }
          else
          {
            $('.scroll-to-top').removeClass('scroll-to-top-invisible');
          }
        });

        $('#sidebar-toggle').click(() => {
            $('#sidebar').toggleClass('open');
        });

        // displays the name of the file selected from the file browser
        $(".custom-file-input").on("change", function()
        {
        var fileName = $(this).val().split("\\").pop();
        $(this).siblings(".custom-file-label").addClass("selected").html(fileName);
        });

        this.showDropMenuOnHover();

        const showEndSarsModal = () => {
            ReactSwal.fire({
                showConfirmButton: false,
                showCloseButton: true,
                position: 'center-start',
                grow: 'row',
                customClass: {
                    popup: 'p-0 text-white endsars-modal',
                    content: 'pt-3 text-white',
                },
                html: (
                    <div className="w-100 py-5 px-md-4 montserrat-font">
                        <p className="text-left">
                            <span className="color-theme">PhotoStock</span> supports the fight against Police Brutality in Nigeria by making sure all the iconic pictures that capture important moments in the struggle are properly archived!
                        </p>

                        <div className="mt-4 text-justify">
                            <span className="badge badge-pill badge-danger p-2">#EndSars</span>
                            <span className="badge badge-pill badge-warning p-2 ml-2">#EndPoliceBrutality</span>
                        </div>
                    </div>
                ),
            });
        };

        setTimeout(showEndSarsModal, 3000);
  }

  componentDidUpdate(oldProps) {
    this.showDropMenuOnHover();
    $('#sidebar-toggle').click(() => {
        $('#sidebar').toggleClass('open');
    });

    if (this.state.categories.length === 0 && this.props.product_categories.length > 0) {
        const cats = this.props.product_categories;

        if (cats.length <= 5)
            this.setState({categories: cats, loading: false});
        else
            this.setState({categories: cats.slice(0, 5), loading: false});
    }

    if (this.state.auth.dashboard !== oldProps.auth.dashboard)
        this.setState({auth: this.props.auth});
  }

  generateCategoryLinkDropdown (title) {
    const uri = `/shop?category=${title}&sort=featured&keyword=`;
    return (
        <Link to={uri} key={`cat-link-${Math.random() + Math.random()}`} className="dropdown-item mt-2">{title}</Link>
    );
  }

  generateCategoryLink (title) {
    const uri = `/shop?category=${title}&sort=featured&keyword=`;
    return (
        <Link to={uri} key={`cat-link-${Math.random() + Math.random()}`}>{title}</Link>
    );
  }

  showDropMenuOnHover() {
      const $dropdown = $(".dropdown");
      const $dropdownToggle = $("dropdown-toggle");
      const $dropdownMenu = $(".dropdown-menu");
      const showClass = "show";
      
      if (window.matchMedia("(min-width: 768px)").matches) {
          $dropdown.hover(
              function() {
                  const $this = $(this);
                  $this.addClass(showClass);
                  $this.find($dropdownToggle).attr("aria-expanded", "true");
                  $this.find($dropdownMenu).addClass(showClass);
              },
              function() {
                  const $this = $(this);
                  $this.removeClass(showClass);
                  $this.find($dropdownToggle).attr("aria-expanded", "false");
                  $this.find($dropdownMenu).removeClass(showClass);
              }
          );
      } else {
          $dropdown.off("mouseenter mouseleave");
      }
  }

  ignoreScrollChange() {
      this.setState(() => {
          return (
              {'navClasses': 'ignore-scroll-change nav-opaque'}
          );
      });
  }

  getNavBarHeight() {
    return $(this.navBar).outerHeight();
  }

  handleSearchChanged(e) {
    this.setState({search: e.target.value});
  }

  doSearch(e) {
      e.preventDefault();
      this.props.history.push(`/shop?category=All&sort=featured&keyword=${this.state.search}`)
  }

  scrollToTop(e) {
    $('html, body').animate({
      scrollTop: 0
    }, 800)
  }

  showCart() {
      if (this.props.cart.length === 0) {
        ReactSwal.fire({
            showConfirmButton: false,
            position: 'top-end',
            customClass: {
                popup: 'jumbotron p-0',
                content: 'p-0 jumbotron p-3',
            },
            html: (
                <div>
                    <div className="montserrat-font mt-2">Your cart is empty!</div>
                    <button className="btn btn-danger btn-block mt-5" onClick={e => dismissModal()}>Close</button>
                </div>
            ),
        });
      }
      else {
        // Calculate the total price
        let totalPrice = 0;
        let discount = 0;
        let charge = 0;
        this.props.cart.forEach(product => totalPrice += parseInt(product.price));

        // If the price is more than 0, we have to retrieve the discounted charge data
        if (totalPrice > 0) {
            utils.showCartLoading();

            // Verify the session first
            utils.verifyUserSession(this.props.authenticateUser, 
                () => {
                    // Get the charge
                    utils.axios.get('user/charge/' + totalPrice)
                    .then((res) => {
                        if (res.status === 200) {
                            const chargeData = res.data;
                            charge = chargeData.charge;
                            discount = parseFloat(chargeData.discount) * 100;

                            dismissModal();

                            // Show the cart
                            ReactSwal.fire({
                                showConfirmButton: false,
                                position: 'top-end',
                                customClass: {
                                    popup: 'jumbotron p-0',
                                    content: 'p-0 jumbotron p-3',
                                },
                                html: <Cart cartItems={this.props.cart} discount={discount} charge={charge} totalPrice={totalPrice} 
                                    onRemoveFromCart={this.removeFromCart} onCheckout={this.checkout}/>
                            });
                        }
                        else {
                            utils.showNetworkError();
                        }
                    })
                    .catch((error) => utils.showNetworkError())
                },
                () => {
                    ReactSwal.fire({
                        title: 'You need to login first!',
                        icon: 'info',
                        showConfirmButton: false,
                        html: <utils.AuthComponent onLogin={this.redirectToLogin} 
                            onCreateAccount={this.redirectToCreateAccount} onCheckout={this.checkout}/>
                    });
                },
                false);
        }
        else {
            // No payment required, just show the cart
            ReactSwal.fire({
                showConfirmButton: false,
                position: 'top-end',
                customClass: {
                    popup: 'jumbotron p-0',
                    content: 'p-0 jumbotron p-3',
                },
                html: <Cart cartItems={this.props.cart} discount={discount} charge={charge} totalPrice={totalPrice} 
                    onRemoveFromCart={this.removeFromCart} onCheckout={this.checkout}/>
            });
        }
      }
  }

  removeFromCart(product) {
      dismissModal();

      // Remove this product from cart if it is there
      this.props.removeFromCart(product);

      setTimeout(() => {
        // Reload cart
        this.showCart();
      }, 100);
  }

  redirectToLogin() {
    ReactSwal.close();
    this.props.history.push('/user/login');
  };

  redirectToCreateAccount() {
    ReactSwal.close();
    this.props.history.push('/user/register');
  };

  redirectToDashboard() {
    ReactSwal.close();
    this.props.history.push('/user/dashboard');
  };

  checkout(products, totalPrice) {
      // Generate an object of product ids
      const ids = {};
      products.forEach((product, index) => ids['prod_' + index] = product.id);

      // State the payment process
      utils.initiateBulkPurchase(ids, totalPrice,
        () => {
            // clear the cart
            this.props.clearCart();

            ReactSwal.fire({
                showConfirmButton: false,
                icon: 'success',
                title: 'Products added to collecton!',
                showCloseButton: true,
                timer: 4000,
                html: (
                    <div className="mt-4">
                        <button className="btn btn-block btn-success" onClick={e => this.redirectToDashboard()}>View Collection</button>
                    </div>
                )
            });
        });
  }
}

const Cart = ({cartItems, charge, discount, totalPrice, onRemoveFromCart, onCheckout}) => {
    const [products] = useState(cartItems);

    return (
        <div>
            {products.length === 0 ? 
                <div>
                    <div className="montserrat-font mt-2">Your cart is empty!</div>
                    <button className="btn btn-danger btn-block mt-5" onClick={e => dismissModal()}>Close</button>
                </div>
            : 
                <div>
                    <ul className="list-group list-group-flush">
                        {products.map((product, index) => {
                            return (
                                <li className="list-group-item" key={index}>
                                    <div className="row">
                                        <div className="col-sm-3 p-0">
                                            <img src={product.img_url} className="img-fluid rounded" alt="Product" />
                                        </div>

                                        <div className="col-sm text-left roboto-font mt-3 mt-md-0">
                                            
                                            {product.price > 0 ? <div className="montserrat-font">&#8358;{product.price}</div> 
                                            : <div className="montserrat-font">FREE</div>}

                                            <button className="btn btn-dark btn-sm mt-4" onClick={e => onRemoveFromCart(product)}>Remove</button>
                                        </div>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>

                    <div className="mt-4 jumbotron p-2 m-0 montserrat-font text-left">
                        <div className="row">
                            <div className="col-5">Total:</div>
                            <div className="col">&#8358;{totalPrice}</div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-5">Discount:</div>
                            <div className="col">{parseInt(discount)}%</div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-5">Charge:</div>
                            <div className="col">&#8358;{charge}</div>
                        </div>

                        <div className="row mt-5">
                            <div className="col-sm mb-2 mb-md-0"><button className="btn btn-danger btn-block" 
                                onClick={e => dismissModal()}>Close</button>
                            </div>
                            <div className="col-sm"><button className="btn btn-success btn-block" 
                                onClick={e => onCheckout(products, totalPrice)}>Checkout</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

/* Map state and dispatch */
const mapStateToProps = (state) => {
    return {
        auth: {...state.appReducer.auth},
        cart: [...state.appReducer.cart],
        product_categories: [...state.miscReducer.categories]
    };
};

const getCategories = creators.misc.getCategories;
const getUserPlans = creators.misc.getUserPlans;
const getPhotographerPlans = creators.misc.getPhotographerPlans;
const removeFromCart = creators.app.removeFromCart;
const clearCart = creators.app.clearCart;
const authenticateUser = creators.app.authenticate;

const mapDispatchToProps = {
    getCategories, getUserPlans, getPhotographerPlans, removeFromCart, authenticateUser, clearCart
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));