export const app = {
    AUTHENTICATE: 'PHOTOSTOCK/APP/AUTHENTICATE',
    ADD_TO_CART: 'PHOTOSTOCK/APP/ADD_TO_CART',
    REMOVE_FROM_CART: 'PHOTOSTOCK/APP/REMOVE_FROM_CART',
    CLEAR_CART: 'PHOTOSTOCK/APP/CLEAR_CART',
};

export const index = {
    GET_PRODUCTS_COUNT: 'PHOTOSTOCK/INDEX/GET_PRODUCTS_COUNT',
    LOAD_FEATURED_PRODUCTS: 'PHOTOSTOCK/INDEX/LOAD_FEATURED_PRODUCTS',
    LOAD_LATEST_PRODUCTS: 'PHOTOSTOCK/INDEX/LOAD_LATEST_PRODUCTS',
    LOAD_POPULAR_PRODUCTS: 'PHOTOSTOCK/INDEX/LOAD_POPULAR_PRODUCTS',
};

export const shop = {
    GET_PHOTOS_COUNT: 'PHOTOSTOCK/SHOP/GET_PHOTOS_COUNT',
    GET_PHOTOS: 'PHOTOSTOCK/SHOP/GET_PHOTOS',
    GET_PHOTOS_SUCCESS: 'PHOTOSTOCK/SHOP/GET_PHOTOS_SUCCESS',
    GET_PHOTOS_FAILED: 'PHOTOSTOCK/SHOP/GET_PHOTOS_FAILED',
};

export const photographer = {
    GET_PHOTOS_COUNT: 'PHOTOSTOCK/PHOTOGRAPHER/GET_PHOTOS_COUNT',
    GET_PHOTOS: 'PHOTOSTOCK/PHOTOGRAPHER/GET_PHOTOS',
    GET_PHOTOS_SUCCESS: 'PHOTOSTOCK/PHOTOGRAPHER/GET_PHOTOS_SUCCESS',
    GET_PHOTOS_FAILED: 'PHOTOSTOCK/PHOTOGRAPHER/GET_PHOTOS_FAILED',

    GET_PHOTOGRAPHER: 'PHOTOSTOCK/PHOTOGRAPHER/GET_PHOTOGRAPHER',
    GET_PHOTOGRAPHER_SUCCESS: 'PHOTOSTOCK/PHOTOGRAPHER/GET_PHOTOGRAPHER_SUCCESS',
    GET_PHOTOGRAPHER_FAILED: 'PHOTOSTOCK/PHOTOGRAPHER/GET_PHOTOGRAPHER_FAILED',
    GET_PHOTOGRAPHER_PHOTOS_COUNT: 'PHOTOSTOCK/PHOTOGRAPHER/GET_PHOTOGRAPHER_PHOTOS_COUNT',
    GET_PHOTOGRAPHER_PHOTOS: 'PHOTOSTOCK/PHOTOGRAPHER/GET_PHOTOGRAPHER_PHOTOS',
    GET_PHOTOGRAPHER_PHOTOS_SUCCESS: 'PHOTOSTOCK/PHOTOGRAPHER/GET_PHOTOGRAPHER_PHOTOS_SUCCESS',
    GET_PHOTOGRAPHER_PHOTOS_FAILED: 'PHOTOSTOCK/PHOTOGRAPHER/GET_PHOTOGRAPHER_PHOTOS_FAILED',

    UPDATE_DASHBOARD: 'PHOTOSTOCK/PHOTOGRAPHER/UPDATE_DASHBOARD',
    REMOVE_DASHBOARD: 'PHOTOSTOCK/PHOTOGRAPHER/REMOVE_DASHBOARD',
};

export const user = {
    GET_PHOTOS_COUNT: 'PHOTOSTOCK/USER/GET_PHOTOS_COUNT',
    GET_PHOTOS: 'PHOTOSTOCK/USER/GET_PHOTOS',
    GET_PHOTOS_SUCCESS: 'PHOTOSTOCK/USER/GET_PHOTOS_SUCCESS',
    GET_PHOTOS_FAILED: 'PHOTOSTOCK/USER/GET_PHOTOS_FAILED',

    UPDATE_DASHBOARD: 'PHOTOSTOCK/USER/UPDATE_DASHBOARD',
    REMOVE_DASHBOARD: 'PHOTOSTOCK/USER/REMOVE_DASHBOARD',
};

export const admin = {
    GET_PHOTOS_COUNT: 'PHOTOSTOCK/ADMIN/GET_PHOTOS_COUNT',
    GET_PHOTOS: 'PHOTOSTOCK/ADMIN/GET_PHOTOS',
    GET_PHOTOS_SUCCESS: 'PHOTOSTOCK/ADMIN/GET_PHOTOS_SUCCESS',
    GET_PHOTOS_FAILED: 'PHOTOSTOCK/ADMIN/GET_PHOTOS_FAILED',

    UPDATE_DASHBOARD: 'PHOTOSTOCK/ADMIN/UPDATE_DASHBOARD',
    REMOVE_DASHBOARD: 'PHOTOSTOCK/ADMIN/REMOVE_DASHBOARD',
};

export const misc = {
    GET_CATEGORIES: 'PHOTOSTOCK/MISC/GET_CATEGORIES',
    GET_CATEGORIES_SUCCESS: 'PHOTOSTOCK/MISC/GET_CATEGORIES_SUCCESS',
    GET_CATEGORIES_FAILED: 'PHOTOSTOCK/MISC/GET_CATEGORIES_FAILED',

    GET_USER_PLANS: 'PHOTOSTOCK/MISC/GET_USER_PLANS',
    GET_USER_PLANS_SUCCESS: 'PHOTOSTOCK/MISC/GET_USER_PLANS_SUCCESS',
    GET_PHOTOGRAPHER_PLANS_SUCCESS: 'PHOTOSTOCK/MISC/GET_PHOTOGRAPHER_PLANS_SUCCESS',
};

export const flags = {
    NO_CONTENT: -1,
    ERROR: -5,
};