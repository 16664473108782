import * as constants from './constants';
import { axiosInstance } from '../../components/utils';

const axios = axiosInstance();

export const app = {
    authenticate: (authenticated, dashboard, logout) => {
        return {
            type: constants.app.AUTHENTICATE, 
            payload: {
                authenticated: authenticated,
                dashboard: dashboard,
                logout: logout,
            }
        }
    },

    addToCart: (product) => ({type: constants.app.ADD_TO_CART, payload: product}),

    removeFromCart: (product) => ({type: constants.app.REMOVE_FROM_CART, payload: product}),

    clearCart: () => ({type: constants.app.CLEAR_CART}),
};

export const index = {
    getProductsCount: (limit = 100) => {
        return (dispatch) => {
            return axios.get('products', {
                params: {
                    perPage: limit,
                }
            })
            .then((response) => {
                // Dispatch a SUCESS action
                if (response.status === 200) {
                    dispatch({type: constants.index.GET_PRODUCTS_COUNT, payload: response.data.length});
                }
                else {
                    // Dispatch an action with empty payload to signify that no products were found!
                    dispatch({type: constants.index.GET_PRODUCTS_COUNT, payload: 0});
                }
            })
            .catch((error) => {
                dispatch({type: constants.index.GET_PRODUCTS_COUNT, payload: 0});
            });
        }
    },

    getFeaturedProducts: (offset = 0, limit = 50) => {
        return (dispatch) => {
            return axios.get('products', {
                params: {
                    sort: 'featured',
                    offset: offset,
                    perPage: limit,
                }
            })
            .then((response) => {
                // Dispatch a SUCESS action
                if (response.status === 200) {
                    dispatch({type: constants.index.LOAD_FEATURED_PRODUCTS, payload: response.data});
                }
                else {
                    // Dispatch an action with empty payload to signify that no products were found!
                    dispatch({type: constants.index.LOAD_FEATURED_PRODUCTS, payload: []});
                }
            })
            .catch((error) => {
                dispatch({type: constants.index.LOAD_FEATURED_PRODUCTS, payload: []});
            });
        }
    },

    getLatestProducts: (offset = 0, limit = 50) => {
        return (dispatch) => {
            return axios.get('products', {
                params: {
                    sort: 'date-descending',
                    offset: offset,
                    perPage: limit,
                }
            })
            .then((response) => {
                // Dispatch a SUCESS action
                if (response.status === 200) {
                    dispatch({type: constants.index.LOAD_LATEST_PRODUCTS, payload: response.data});
                }
                else {
                    // Dispatch an action with empty payload to signify that no products were found!
                    dispatch({type: constants.index.LOAD_LATEST_PRODUCTS, payload: []});
                }
            })
            .catch((error) => {
                dispatch({type: constants.index.LOAD_LATEST_PRODUCTS, payload: []});
            });
        }
    },

    getPopularProducts: (offset = 0, limit = 50) => {
        return (dispatch) => {
            return axios.get('products', {
                params: {
                    sort: 'popular',
                    offset: offset,
                    perPage: limit,
                }
            })
            .then((response) => {
                // Dispatch a SUCESS action
                if (response.status === 200) {
                    dispatch({type: constants.index.LOAD_POPULAR_PRODUCTS, payload: response.data});
                }
                else {
                    // Dispatch an action with empty payload to signify that no products were found!
                    dispatch({type: constants.index.LOAD_POPULAR_PRODUCTS, payload: []});
                }
            })
            .catch((error) => {
                dispatch({type: constants.index.LOAD_POPULAR_PRODUCTS, payload: []});
            });
        }
    },
};

export const shop = {
    getPhotosCount: (category = '', sort = 'ascending', keyword = '') => {
        return (dispatch) => {
            return axios.get('products', {
                params: {
                    category: category,
                    sort: sort,
                    keyword: keyword,
                },
            })
            .then((response) => {
                // Dispatch a SUCESS action
                if (response.status === 200) {
                    dispatch({type: constants.shop.GET_PHOTOS_COUNT, payload: response.data.length});
                }
                else {
                    // Dispatch an action with empty payload to signify that no products were found!
                    dispatch({type: constants.shop.GET_PHOTOS_COUNT, payload: constants.flags.NO_CONTENT});
                }
            })
            .catch((error) => {
                dispatch({type: constants.shop.GET_PHOTOS_COUNT, payload: constants.flags.ERROR});
            });
        }
    },

    getPhotos: (category = '', sort = 'ascending', keyword = '', offset = 0, limit = 0) => {
        return (dispatch) => {
            return axios.get('products', {
                params: {
                    category: category,
                    sort: sort,
                    keyword: keyword,
                    offset: offset,
                    perPage: limit,
                },
            })
            .then((response) => {
                // Dispatch a SUCESS action
                if (response.status === 200) {
                    dispatch({type: constants.shop.GET_PHOTOS_SUCCESS, payload: response.data});
                }
                else {
                    // Dispatch an action with empty payload to signify that no products were found!
                    dispatch({type: constants.shop.GET_PHOTOS_SUCCESS, payload: []});
                }
            })
            .catch((error) => {
                dispatch({type: constants.shop.GET_PHOTOS_FAILED, payload: error});
            });
        }
    },
};

export const photographer = {
    getPhotosCount: (category = '', sort = 'ascending', keyword = '') => {
        return (dispatch) => {
            return axios.get('photographer/photos', {
                params: {
                    category: category,
                    sort: sort,
                    keyword: keyword,
                }
            })
            .then((response) => {
                // Dispatch a SUCESS action
                if (response.status === 200) {
                    dispatch({type: constants.photographer.GET_PHOTOS_COUNT, payload: response.data.length});
                }
                else {
                    // Dispatch an action with empty payload to signify that no products were found!
                    dispatch({type: constants.photographer.GET_PHOTOS_COUNT, payload: constants.flags.NO_CONTENT});
                }
            })
            .catch((error) => {
                // -1 signifies an error
                dispatch({type: constants.photographer.GET_PHOTOS_COUNT, payload: constants.flags.ERROR});
            });
        }
    },

    getPhotos: (category = '', sort = 'ascending', keyword = '', offset = 0, limit = 0) => {
        return (dispatch) => {
            return axios.get('photographer/photos', {
                params: {
                    category: category,
                    sort: sort,
                    keyword: keyword,
                    offset: offset,
                    perPage: limit,
                }
            })
            .then((response) => {
                // Dispatch a SUCESS action
                if (response.status === 200) {
                    dispatch({type: constants.photographer.GET_PHOTOS_SUCCESS, payload: response.data});
                }
                else {
                    // Dispatch an action with empty payload to signify that no products were found!
                    dispatch({type: constants.photographer.GET_PHOTOS_SUCCESS, payload: []});
                }
            })
            .catch((error) => {
                dispatch({type: constants.photographer.GET_PHOTOS_FAILED, payload: error});
            });
        }
    },

    getPhotographerPhotosCount: (username, category = '', sort = 'ascending', keyword = '') => {
        return (dispatch) => {
            return axios.get('showPhotographerProducts/' + username, {
                params: {
                    category: category,
                    sort: sort,
                    keyword: keyword,
                }
            })
            .then((response) => {
                // Dispatch a SUCESS action
                if (response.status === 200) {
                    dispatch({type: constants.photographer.GET_PHOTOGRAPHER_PHOTOS_COUNT, payload: response.data.length});
                }
                else {
                    // Dispatch an action with empty payload to signify that no products were found!
                    dispatch({type: constants.photographer.GET_PHOTOGRAPHER_PHOTOS_COUNT, payload: constants.flags.NO_CONTENT});
                }
            })
            .catch((error) => {
                // -1 signifies an error
                dispatch({type: constants.photographer.GET_PHOTOGRAPHER_PHOTOS_COUNT, payload: constants.flags.ERROR});
            });
        }
    },

    getPhotographerPhotos: (username, category = '', sort = 'ascending', keyword = '', offset = 0, limit = 0) => {
        return (dispatch) => {
            return axios.get('showPhotographerProducts/' + username, {
                params: {
                    category: category,
                    sort: sort,
                    keyword: keyword,
                    offset: offset,
                    perPage: limit,
                }
            })
            .then((response) => {
                // Dispatch a SUCESS action
                if (response.status === 200) {
                    dispatch({type: constants.photographer.GET_PHOTOGRAPHER_PHOTOS_SUCCESS, payload: response.data});
                }
                else {
                    // Dispatch an action with empty payload to signify that no products were found!
                    dispatch({type: constants.photographer.GET_PHOTOGRAPHER_PHOTOS_SUCCESS, payload: []});
                }
            })
            .catch((error) => {
                dispatch({type: constants.photographer.GET_PHOTOGRAPHER_PHOTOS_FAILED, payload: error});
            });
        }
    },

    getPhotographer: (username) => {
        return (dispatch) => {
            dispatch({type: constants.photographer.GET_PHOTOGRAPHER});

            return axios.get('showPhotographer/' + username)
            .then((response) => {
                // Dispatch a SUCESS action
                if (response.status === 200) {
                    dispatch({type: constants.photographer.GET_PHOTOGRAPHER_SUCCESS, payload: response.data});
                }
                else {
                    // Dispatch an action to signify that no photograper was found!
                    dispatch({type: constants.photographer.GET_PHOTOGRAPHER_FAILED, payload: null});
                }
            })
            .catch((error) => {
                dispatch({type: constants.photographer.GET_PHOTOGRAPHER_FAILED, payload: error});
            });
        }
    },

    updateDashboard: (data) => ({type: constants.photographer.UPDATE_DASHBOARD, payload: data}),

    removeDashboard: () => ({type: constants.photographer.REMOVE_DASHBOARD}),
};

export const user = {
    getPhotosCount: (category = '', sort = 'ascending', keyword = '') => {
        return (dispatch) => {
            return axios.get('user/collection', {
                params: {
                    category: category,
                    sort: sort,
                    keyword: keyword,
                }
            })
            .then((response) => {
                // Dispatch a SUCESS action
                if (response.status === 200) {
                    dispatch({type: constants.user.GET_PHOTOS_COUNT, payload: response.data.length});
                }
                else {
                    // Dispatch an action with empty payload to signify that no products were found!
                    dispatch({type: constants.user.GET_PHOTOS_COUNT, payload: constants.flags.NO_CONTENT});
                }
            })
            .catch((error) => {
                dispatch({type: constants.user.GET_PHOTOS_COUNT, payload: constants.flags.NO_CONTENT});
            });
        }
    },

    getPhotos: (category = '', sort = 'ascending', keyword = '', offset = 0, limit = 0) => {
    
        return (dispatch) => {
            return axios.get('user/collection', {
                params: {
                    category: category,
                    sort: sort,
                    keyword: keyword,
                    offset: offset,
                    perPage: limit,
                }
            })
            .then((response) => {
                // Dispatch a SUCESS action
                if (response.status === 200) {
                    dispatch({type: constants.user.GET_PHOTOS_SUCCESS, payload: response.data});
                }
                else {
                    // Dispatch an action with empty payload to signify that no products were found!
                    dispatch({type: constants.user.GET_PHOTOS_SUCCESS, payload: []});
                }
            })
            .catch((error) => {
                dispatch({type: constants.user.GET_PHOTOS_FAILED, payload: error});
            });
        }
    },

    updateDashboard: (data) => ({type: constants.user.UPDATE_DASHBOARD, payload: data}),

    removeDashboard: () => ({type: constants.user.REMOVE_DASHBOARD}),
};

export const admin = {
    getPhotosCount: () => {
        return (dispatch) => {
            return axios.get('admin/unapproved_photos')
            .then((response) => {
                // Dispatch a SUCESS action
                if (response.status === 200) {
                    dispatch({type: constants.admin.GET_PHOTOS_COUNT, payload: response.data.length});
                }
                else {
                    // Dispatch an action with empty payload to signify that no products were found!
                    dispatch({type: constants.admin.GET_PHOTOS_COUNT, payload: constants.flags.NO_CONTENT});
                }
            })
            .catch((error) => {
                // -1 signifies an error
                dispatch({type: constants.admin.GET_PHOTOS_COUNT, payload: constants.flags.ERROR});
            });
        }
    },

    getPhotos: () => {
        return (dispatch) => {
            return axios.get('admin/unapproved_photos')
            .then((response) => {
                // Dispatch a SUCESS action
                if (response.status === 200) {
                    dispatch({type: constants.admin.GET_PHOTOS_SUCCESS, payload: response.data});
                }
                else {
                    // Dispatch an action with empty payload to signify that no products were found!
                    dispatch({type: constants.admin.GET_PHOTOS_SUCCESS, payload: []});
                }
            })
            .catch((error) => {
                dispatch({type: constants.admin.GET_PHOTOS_FAILED, payload: error});
            });
        }
    },

    updateDashboard: (data) => ({type: constants.admin.UPDATE_DASHBOARD, payload: data}),

    removeDashboard: () => ({type: constants.admin.REMOVE_DASHBOARD}),
};

export const misc = {
    getCategories: () => {
        return (dispatch) => {
            axios.get('categories')
            .then((response) => {
                if (response.status === 200 && response.data) {
                    dispatch({type: constants.misc.GET_CATEGORIES_SUCCESS, payload: response.data});
                }
                else {
                    dispatch({type: constants.misc.GET_CATEGORIES_SUCCESS, payload: []});
                }
            })
            .catch((error) => {
                dispatch({type: constants.misc.GET_CATEGORIES_FAILED, payload: error});
            });
        }
    },

    getUserPlans: () => {
        return (dispatch) => {
            axios.get('user/plans')
            .then((response) => {
                if (response.status === 200 && response.data) {
                    dispatch({type: constants.misc.GET_USER_PLANS_SUCCESS, payload: response.data});
                }
                else {
                    dispatch({type: constants.misc.GET_USER_PLANS_SUCCESS, payload: []});
                }
            })
            .catch((error) => {
            });
        }
    },

    getPhotographerPlans: () => {
        return (dispatch) => {
            axios.get('photographer/plans')
            .then((response) => {
                if (response.status === 200 && response.data) {
                    dispatch({type: constants.misc.GET_PHOTOGRAPHER_PLANS_SUCCESS, payload: response.data});
                }
                else {
                    dispatch({type: constants.misc.GET_PHOTOGRAPHER_PLANS_SUCCESS, payload: []});
                }
            })
            .catch((error) => {
            });
        }
    }
};