/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, Redirect } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Scrollbar } from 'swiper';
import $ from 'jquery';
import 'swiper/swiper-bundle.min.css';
import { Link, Switch, Route, NavLink, HashRouter } from 'react-router-dom';
import { generatePagination, ResponsiveProductsGrid, Loading, reactSwal } from './utils';
import { GridRow, ColSM } from './bootstrap-grid';
import * as creators from '../redux/actions/creators';
import * as utils from './utils';

SwiperCore.use([Scrollbar]);

class Index extends Component {
    constructor(props){
        super(props);
        
        this.state = {
            categories: this.props.product_categories,
            photographer_plans: this.props.photographer_plans,
        };

        this.categorySwiperHovered = false;

        this.swipeCategoryWiper = this.swipeCategoryWiper.bind(this);
    }

    render() {
        let basicDeal, silverDeal, goldDeal, silverPrice, goldPrice;
        if (this.state.photographer_plans.length === 3) {
            const plans = this.state.photographer_plans;
            basicDeal = parseFloat(plans[0].rate) * 100;
            silverDeal = parseFloat(plans[1].rate) * 100;
            goldDeal = parseFloat(plans[2].rate) * 100;

            silverPrice = plans[1].price;
            goldPrice = plans[2].price;
        }

        return (
            <div className="index-container">
                <div className="cover-bg">
                    <div className="inner">
                        <h1 className="text-center header montserrat-font text-white"><span>P</span>hotoStock NG</h1>
                        <h1 className="text-center headline montserrat-font">
                            Best Nigerian Online Photo Store
                        </h1>
                    </div>
                </div>

                {this.state.categories.length === 0 ? '' : 
                    <Swiper className="category-swiper" loop={true} speed={300}
                        slidesPerView={3} spaceBetween={0} scrollbar={{ draggable: true, hide: false }}
                        onSwiper={(swiper) => this.categorySwiper = swiper}>
                            
                        {this.state.categories.map((category) => this.generateCategorySlide(category.cover_img, category.category))}
                    </Swiper>
                }

                <ConnectedTopProducts getNavBarHeight={this.props.getNavBarHeight}/>

                <div className="body-ad">
                    <div className="inner text-center">
                        <h1 className="text-white translucent-bg-40 w-100">Professional HD Quality Stock Photos</h1>
                        <Link to="/shop?category=All&sort=featured&keyword=">SHOP NOW</Link>
                    </div>
                </div>

                <div className="container info-container">
                    <GridRow>
                        <ColSM classes="text-center bordered mb-5 mb-md-0">
                            <div className="text-center jumbotron icon-container">
                                <span className="fas fa-phone"></span>
                            </div>
                            <h4 className="text">24/7 Dedicated Support</h4>
                        </ColSM>

                        <ColSM classes="text-center bordered mb-5 mb-md-0">
                            <div className="text-center jumbotron icon-container">
                                <span className="fas fa-gift"></span>
                            </div>
                            <h4 className="text">Money Back Gaurantee</h4>
                        </ColSM>

                        <ColSM classes="text-center">
                            <div className="text-center jumbotron icon-container">
                                <span className="fas fa-money-check"></span>
                            </div>
                            <h4 className="text">Member Discount</h4>
                        </ColSM>
                    </GridRow>
                </div>

                <div className="plan-container jumbotron container-fluid text-center">
                    {this.state.photographer_plans.length === 0 ? <Loading /> :
                        <div>
                            <h1 className="text-center montserrat-font title">Are you a Photographer?</h1>
                            <h4 className="text-center roboto-font sub-title">Photography sales made easy & affordable!</h4>

                            <div className="card-deck text-white">
                                <div className="card">
                                    <h3 className="plan p-3 text-center mb-0 montserrat-font"><span>Basic</span> Plan</h3>
                                    <h4 className="text-center p-3 discount mb-0 roboto-font">Now FREE!</h4>
                                    
                                    <div className="card-body text-dark">
                                        <h4 className="text-center roboto-font deal">You get back <span className="montserrat-font">{basicDeal}%</span> per sale!
                                        </h4>
                                    </div>

                                    <div className="card-footer">
                                        <Link to="photographer/register/basic" className="sub-link stretched-link">Register Now!</Link>
                                    </div>
                                </div>

                                <div className="card">
                                    <h3 className="plan p-3 text-center mb-0 montserrat-font"><span>Silver</span> Plan</h3>
                                    <h4 className="text-center p-3 discount mb-0 roboto-font">Starting At<strong className="ml-2">&#8358;{silverPrice}/mo*</strong></h4>

                                    <div className="card-body text-dark">
                                        <h4 className="text-center roboto-font deal">You get back <span className="montserrat-font">{silverDeal}%</span> per sale!</h4>
                                    </div>

                                    <div className="card-footer">
                                        <Link to="photographer/register/silver" className="sub-link stretched-link">Register Now!</Link>
                                    </div>
                                </div>

                                <div className="card">
                                    <h3 className="plan p-3 text-center mb-0 montserrat-font"><span>Gold</span> Plan</h3>
                                    <h4 className="text-center p-3 discount mb-0 roboto-font">Starting At<strong className="ml-2">&#8358;{goldPrice}/mo*</strong></h4>

                                    <div className="card-body text-dark">
                                        <h4 className="text-center roboto-font deal">You get back <span className="montserrat-font">{goldDeal}%</span> per sale!</h4>
                                    </div>

                                    <div className="card-footer">
                                        <Link to="photographer/register/gold" className="sub-link stretched-link">Register Now!</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }

    generateCategorySlide(imgSrc, category) {
        return (
            <SwiperSlide key={`cat-swiper-link-${Math.random() + Math.random()}`} className="img-fluid" style={{backgroundImage: `url(${imgSrc})`, backgroundSize: 'cover'}}>
                <div className="action-container text-center">
                    <Link to={`/shop?category=${category}&sort=ascending&keyword=`}>Explore</Link>
                </div>

                <div className="translucent-bg-50 title-container">
                    <h4 className="category mb-0">{category}</h4>
                </div>
            </SwiperSlide>
        );
    }

    componentDidMount(){
        this.categorySwiperInterval = setInterval(this.swipeCategoryWiper,
            3000);

        $('div.index-container .category-swiper').hover(
            () => this.categorySwiperHovered = true,
            () => this.categorySwiperHovered = false
        );
    }

    componentWillUnmount() {
        clearInterval(this.mainSwiperInterval);
        clearInterval(this.categorySwiperInterval);
    }

    componentDidUpdate() {
        if (this.state.categories.length === 0 && this.props.product_categories.length > 0) {
            this.setState({categories: this.props.product_categories});
        }
        else if (this.props.product_categories.length === 0)
            this.props.getCategories();

        if (this.state.photographer_plans.length === 0 && this.props.photographer_plans.length > 0) {
            this.setState({photographer_plans: this.props.photographer_plans});
        }
        else if (this.props.photographer_plans.length === 0)
            this.props.getPhotographerPlans();

        $('div.index-container .category-swiper').hover(
            () => this.categorySwiperHovered = true,
            () => this.categorySwiperHovered = false
        );
    }

    swipeCategoryWiper(){
        if ((this.categorySwiper !== undefined) && !this.categorySwiperHovered)
            this.categorySwiper.slideNext();
    }
}

class TopProducts extends Component {
    constructor(props) {
        super(props);

        this.productsPerTab = 50;
        this.tabContensTop = 0;
        this.tabContentsContainer = undefined;

        this.ReactSwal = reactSwal();

        this.state = {
            rerender: false,

            productsCount: 0,

            featuredOffset: 0,
            currentPageFeatured: 0,
            featuredProducts: [],
            featuredLoading: true,

            latestOffset: 0,
            currentPageLatest: 0,
            latestProducts: [],
            latestLoading: true,

            popularOffset: 0,
            currentPagePopular: 0,
            popularProducts: [],
            popularLoading: true,
        };

        this.loadFeatured = this.loadFeatured.bind(this);
        this.loadLatest = this.loadLatest.bind(this);
        this.loadPopular = this.loadPopular.bind(this);

        this.featuredPaginationClicked = this.featuredPaginationClicked.bind(this);
        this.latestPaginationClicked = this.latestPaginationClicked.bind(this);
        this.popularPaginationClicked = this.popularPaginationClicked.bind(this);

        this.scrollToTab = this.scrollToTab.bind(this);
        this.onAutoScrollToTab = this.onAutoScrollToTab.bind(this);

        this.showProductsModal = this.showProductsModal.bind(this);

        this.showFeaturedProductsModal = this.showFeaturedProductsModal.bind(this);
        this.showLatestProductsModal = this.showLatestProductsModal.bind(this);
        this.showPopularProductsModal = this.showPopularProductsModal.bind(this);

        this.redirectToLogin = this.redirectToLogin.bind(this);
        this.redirectToCreateAccount = this.redirectToCreateAccount.bind(this);
        this.isInCart = this.isInCart.bind(this);
        this.addProductToCart = this.addProductToCart.bind(this);
        this.downloadProduct = this.downloadProduct.bind(this);
        this.doDownloadAlt = this.doDownloadAlt.bind(this);
        this.collectProduct = this.collectProduct.bind(this);
        this.followPhotographer = this.followPhotographer.bind(this);
        this.viewPhotographer = this.viewPhotographer.bind(this);
    }

    featuredPaginationClicked(data) {
        const selected = data.selected;
        const offset = Math.ceil(selected * this.productsPerTab);
        const currentPage = selected;
        
        this.setState({ featuredOffset: offset, currentPageFeatured: currentPage, featuredLoading: true }, () => {
            this.props.getFeaturedProducts(this.state.featuredOffset, this.productsPerTab);
            this.scrollToTab();
        });
    }

    latestPaginationClicked(data) {
        const selected = data.selected;
        const offset = Math.ceil(selected * this.productsPerTab);
        const currentPage = selected;

        this.setState({ latestOffset: offset, currentPageLatest: currentPage, latestLoading: true }, () => {
            this.props.getLatestProducts(this.state.latestOffset, this.productsPerTab);
            this.scrollToTab();
        });
    }

    popularPaginationClicked(data) {
        const selected = data.selected;
        const offset = Math.ceil(selected * this.productsPerTab);
        const currentPage = selected;

        this.setState({ popularOffset: offset, currentPagePopular: currentPage, popularLoading: true }, () => {
            this.props.getPopularProducts(this.state.popularOffset, this.productsPerTab);
            this.scrollToTab();
        });
    }

    loadFeatured() {
        if (this.state.featuredProducts.length === 0) {
            this.setState({ featuredLoading: true }, () => {
                this.props.getFeaturedProducts(this.state.featuredOffset, this.productsPerTab);
                this.scrollToTab();
            });
        }
    }

    loadLatest() {
        if (this.state.latestProducts.length === 0) {
            this.setState({ latestLoading: true }, () => {
                this.props.getLatestProducts(this.state.latestOffset, this.productsPerTab);
                this.scrollToTab();
            });
        }
    }

    loadPopular() {
        if (this.state.popularProducts.length === 0) {
            this.setState({ popularLoading: true }, () => {
                this.props.getPopularProducts(this.state.popularOffset, this.productsPerTab);
                this.scrollToTab();
            });
        }
    }

    componentDidMount() {
        this.tabContensTop = this.tabContentsContainer.getBoundingClientRect().y;

        // Fetch the products size, if any is found, then fetch them
        this.props.getProductsCount();
    }

    componentDidUpdate(prevProps) {
        if (this.state.productsCount === 0 && this.props.productsCount > 0) {
            this.setState({productsCount: this.props.productsCount}, () => {
                const hash = this.props.location.hash;

                if (hash === '#/tab-featured') {
                    this.loadFeatured();
                }
                else if (hash === '#/tab-latest') {
                    this.loadLatest();
                }
                if (hash === '#/tab-popular') {
                    this.loadPopular();
                }
            });
        }

        if (this.state.featuredProducts !== this.props.featuredProducts) {
            this.setState({featuredProducts: this.props.featuredProducts, featuredLoading: false});
        }

        if (this.state.latestProducts !== this.props.latestProducts) {
            this.setState({latestProducts: this.props.latestProducts, latestLoading: false});
        }

        if (this.state.popularProducts !== this.props.popularProducts) {
            this.setState({popularProducts: this.props.popularProducts, popularLoading: false});
        }

        if (prevProps.location.hash !== this.props.location.hash) {
            if (this.state.productsCount > 0) {
                const hash = this.props.location.hash;

                if (hash === '#/tab-featured') {
                    this.loadFeatured();
                }
                else if (hash === '#/tab-latest') {
                    this.loadLatest();
                }
                else if (hash === '#/tab-popular') {
                    this.loadPopular();
                }
            }
        }

        this.scrollToTab();
    }

    scrollToTab() {
        utils.Scroll.scroller.scrollTo('tab-scroll', {
            duration: 700,
            delay: 0,
            smooth: true,
        });
    }

    onAutoScrollToTab() {
        this.scrollToTab();
    }

    showProductsModal(products, currentIndex) {
        utils.showProductsModal(products, currentIndex,
            this.doDownloadAlt, this.collectProduct, this.followPhotographer);
    }

    showFeaturedProductsModal(index) {
        if (this.state.featuredProducts.length > 0) {
            this.showProductsModal(this.state.featuredProducts, index);
        }
    }

    showLatestProductsModal(index) {
        if (this.state.latestProducts.length > 0) {
            this.showProductsModal(this.state.latestProducts, index);
        }
    }

    showPopularProductsModal(index) {
        if (this.state.popularProducts.length > 0) {
            this.showProductsModal(this.state.popularProducts, index);
        }
    }

    redirectToLogin() {
        this.ReactSwal.close();
        this.props.history.push('/user/login?redirect=/' + this.props.location.hash);
    };

    redirectToCreateAccount() {
        this.ReactSwal.close();
        this.props.history.push('/user/register');
    };

    isInCart(product) {
        for (let i = 0; i < this.props.cart.length; i++) {
            const p = this.props.cart[i];
            if (p.name === product.name)
                return true;
        }

        return false;
    }

    addProductToCart(product) {
        if (!this.isInCart(product)) {
            utils.verifyUserSession(this.props.authenticateUser, 
                () => {
                    utils.verifyProductInCollection(product,
                        () => {
                            utils.showInfo('This product is already in your collection!', '', 3000);
                        },
                        () => {
                            this.props.addToCart(product);
                            utils.showAddedToCart();
                        });
                },
                () => {
                    this.ReactSwal.fire({
                        title: 'You need to login first!',
                        icon: 'info',
                        showConfirmButton: false,
                        html: <utils.AuthComponent onLogin={this.redirectToLogin} 
                            onCreateAccount={this.redirectToCreateAccount}/>
                    });
                });
        }
        else {
            utils.showAlreadyInCart();
        }
    }

    downloadProduct(product) {
        utils.verifyUserSession(this.props.authenticateUser, 
            () => {
                utils.initiatePurchase(product, 
                    (collection_id) => {
                        this.doDownload(product, collection_id);
                    },
                    (collection_id) => {
                        this.doDownload(product, collection_id);
                    });
            },
            () => {
                this.ReactSwal.fire({
                    title: 'You need to login first!',
                    icon: 'info',
                    showConfirmButton: false,
                    html: <utils.AuthComponent onLogin={this.redirectToLogin} 
                        onCreateAccount={this.redirectToCreateAccount}/>
                });
            });
    }

    doDownload(product, collection_id, width = 0, height = 0) {
        const img = product.img_url;
        const filename = product.name + img.substring(img.indexOf('.'), img.length);
        utils.download(collection_id, filename, width, height);

        // Remove this product from cart if it is there
        this.props.removeFromCart(product);
    }

    doDownloadAlt(product, width, height) {
        utils.verifyUserSession(this.props.authenticateUser, 
            () => {
                utils.initiatePurchase(product, 
                    (collection_id) => {
                        this.doDownload(product, collection_id, width, height);
                    },
                    (collection_id) => {
                        this.doDownload(product, collection_id, width, height);
                    });
            },
            () => {
                this.ReactSwal.fire({
                    title: 'You need to login first!',
                    icon: 'info',
                    showConfirmButton: false,
                    html: <utils.AuthComponent onLogin={this.redirectToLogin} 
                        onCreateAccount={this.redirectToCreateAccount}/>
                });
            });
    }

    collectProduct(product) {
        utils.verifyUserSession(this.props.authenticateUser, 
            () => {
                utils.initiatePurchase(product, 
                    (collection_id) => {
                        utils.showSuccess('Photo added to collection!', '', 3000);

                        // Remove this product from cart if it is there
                        this.props.removeFromCart(product);
                    },
                    (collection_id) => {
                        utils.showInfo('This photo is already in your collection!', '', 3000);
                    });
            },
            () => {
                this.ReactSwal.fire({
                    title: 'You need to login first!',
                    icon: 'info',
                    showConfirmButton: false,
                    html: <utils.AuthComponent onLogin={this.redirectToLogin} 
                        onCreateAccount={this.redirectToCreateAccount}/>
                });
            });
    }

    followPhotographer(photographer_username) {
        utils.verifyUserSession(this.props.authenticateUser, 
            () => {
                utils.followPhotographer(photographer_username,
                    () => {
                        utils.showSuccess('You are now following ' + photographer_username, '', 3000);
                    },
                    () => {
                        utils.showInfo('You are already following ' + photographer_username, '', 3000);
                    });
            },
            () => {
                this.ReactSwal.fire({
                    title: 'You need to login first!',
                    icon: 'info',
                    showConfirmButton: false,
                    html: <utils.AuthComponent onLogin={this.redirectToLogin} 
                        onCreateAccount={this.redirectToCreateAccount}/>
                });
            });
    }

    viewPhotographer(username) {
        this.props.history.push('/@' + username);
    }

    render() {
        return (
            <HashRouter>
                <utils.Scroll.Element name="tab-scroll"/>
                <div className="top-products">
                    <h1 className="roboto-font text-center title" ref={(el) => {this.tabContentsContainer = el}}>Stock Photos</h1>
                    
                    <ul className="navigation list-group list-group-horizontal justify-content-center w-100 list-unstyled"
                        >
                        <li><NavLink className="list-group-item" to={`/tab-featured`} isActive={
                            (match, location) => {
                                if (location.pathname === '/')
                                    return true;

                                return match && match.isExact;
                            }
                        } >FEATURED</NavLink></li>
                        <li><NavLink className="list-group-item" to={`/tab-latest`}>LATEST</NavLink></li>
                        <li><NavLink className="list-group-item" to={`/tab-popular`}>POPULAR</NavLink></li>
                    </ul>

                    <div className="tab-contents" id="tab">
                        <Switch>
                            <Route path={`/tab-featured`}>
                                <div>
                                    {this.state.featuredLoading ? <div className="loading"><Loading/></div> :
                                        <ResponsiveProductsGrid key="tab-1" getNavBarHeight={this.props.getNavBarHeight} products={this.state.featuredProducts}
                                            onCardClicked={this.showFeaturedProductsModal} 
                                            eventHandlers={{'addToCart': this.addProductToCart, 'download': this.downloadProduct, 
                                                'viewPhotographer': this.viewPhotographer}}/>
                                    }
                                    
                                    { (this.state.productsCount === 0 || this.state.productsCount <= this.productsPerTab) ? '' :  
                                        generatePagination(Math.ceil(this.state.productsCount / this.productsPerTab), this.featuredPaginationClicked,
                                        2, 2, <span className="fas fa-chevron-left"></span>, <span className="fas fa-chevron-right"></span>,
                                        this.state.currentPageFeatured)
                                    }
                                </div>
                            </Route>
                            
                            <Route path={`/tab-latest`}>
                                <div>
                                    {this.state.latestLoading ? <div className="loading"><Loading/></div> :
                                        <ResponsiveProductsGrid key="tab-1" getNavBarHeight={this.props.getNavBarHeight} products={this.state.latestProducts}
                                            onCardClicked={this.showLatestProductsModal}
                                            eventHandlers={{'addToCart': this.addProductToCart, 'download': this.downloadProduct, 
                                                'viewPhotographer': this.viewPhotographer}}/>
                                    }
                                    
                                    { (this.state.productsCount === 0 || this.state.productsCount <= this.productsPerTab) ? '' :  
                                        generatePagination(Math.ceil(this.state.productsCount / this.productsPerTab), this.latestPaginationClicked,
                                        2, 2, <span className="fas fa-chevron-left"></span>, <span className="fas fa-chevron-right"></span>,
                                        this.state.currentPageFeatured)
                                    }
                                </div>
                            </Route>

                            <Route path={`/tab-popular`}>
                                <div>
                                    {this.state.popularLoading ? <div className="loading"><Loading/></div> :
                                        <ResponsiveProductsGrid key="tab-1" getNavBarHeight={this.props.getNavBarHeight} products={this.state.popularProducts}
                                            onCardClicked={this.showPopularProductsModal}
                                            eventHandlers={{'addToCart': this.addProductToCart, 'download': this.downloadProduct, 
                                                'viewPhotographer': this.viewPhotographer}}/>
                                    }
                                    
                                    { (this.state.productsCount === 0 || this.state.productsCount <= this.productsPerTab) ? '' :  
                                        generatePagination(Math.ceil(this.state.productsCount / this.productsPerTab), this.popularPaginationClicked,
                                        2, 2, <span className="fas fa-chevron-left"></span>, <span className="fas fa-chevron-right"></span>,
                                        this.state.currentPageFeatured)
                                    }
                                </div>
                            </Route>

                            <Route path={`/`}>
                                <Redirect to={`/tab-featured`} />
                            </Route>
                        </Switch>
                        
                    </div>
                </div>
            </HashRouter>
        )
    }
}

const mapStateToTopProductProps = (state) =>  {
    return {
        productsCount: state.indexReducer.productsCount,
        featuredProducts: [...state.indexReducer.featuredProducts],
        latestProducts: [...state.indexReducer.latestProducts],
        popularProducts: [...state.indexReducer.popularProducts],
        cart: [...state.appReducer.cart],
    };
};

/* Map state and dispatch */
const getCategories = creators.misc.getCategories;
const getPhotographerPlans = creators.misc.getPhotographerPlans;
const getProductsCount = creators.index.getProductsCount;
const getFeaturedProducts = creators.index.getFeaturedProducts;
const getLatestProducts = creators.index.getLatestProducts;
const getPopularProducts = creators.index.getPopularProducts;
const addToCart = creators.app.addToCart;
const removeFromCart = creators.app.removeFromCart;
const authenticateUser = creators.app.authenticate;

const mapStateToProps = (state) => {
    return {
        product_categories: [...state.miscReducer.categories],
        photographer_plans: [...state.miscReducer.photographer_plans],
    };
};

const mapDispatchToProps = {
    getCategories, getPhotographerPlans
}

const ConnectedTopProducts = connect(mapStateToTopProductProps, {
    getProductsCount, getFeaturedProducts, getLatestProducts,
    getPopularProducts, addToCart, removeFromCart, authenticateUser})(withRouter(TopProducts));
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Index))