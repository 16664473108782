/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, useEffect, useState } from 'react'
import { TopProductCard } from './cards';
import ReactPaginate from 'react-paginate';
import $ from 'jquery';
import { CSSTransition } from 'react-transition-group';
import { useLocation } from 'react-router';
import Axios from 'axios';
import { CommonLoading } from 'react-loadingg';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import fileDownload from 'js-file-download';
import * as ReactScroll from 'react-scroll';
import ReCAPTCHA from 'react-google-recaptcha';

export const Scroll = ReactScroll;
export const animateScroll = ReactScroll.animateScroll;

export const reactSwal = () => withReactContent(Swal);
export const ReactSwal = reactSwal();

export function axiosInstance() {
    const instance = Axios.create({
        baseURL: 'https://www.api.photostock.com.ng/', /*'http://localhost/PhotoStockAPI/',*/
        withCredentials: true,
        validateStatus: function (status) {
            return status >= 200 && status < 500;
        },
    });

    return instance;
}
export const axios = axiosInstance();

export const recaptchaKey = '6LfVl7kZAAAAADEHDsRgS8l1CofEPjJ8cwVeZauL';

export class ResponsiveProductsGrid extends Component {
    constructor(props) {
        super(props);

        this.state = {transition: false};
    }

    componentDidMount() {
        this.setState({transition: true})
    }

    render() {
        const products = this.props.products;
        if (!products || products.length === 0)
            return null;

        const perRow = 3;

        let remainder = products.length % perRow;

        let makeCards;

        if (products.length < perRow)
        {
            makeCards = () => {
                let cols = [];
                
                for (let i = 0; i < products.length; i++) {
                    const product = products[i];
                    cols.push(<ImageGridCol key={`prod-col-${i}`}><TopProductCard index={i} 
                        onCardClicked={this.props.onCardClicked} product={product} 
                            {...this.props.eventHandlers}/></ImageGridCol>)
                }

                return cols;
            };
        }
        else if (remainder === 0)
        {
            makeCards = () => {
                let cols = [[], [], []];
                
                for (let i = 0; i < products.length; i += perRow) {
                    
                    for (let j = 0; j < perRow; j++) {
                        const product = products[i+j];
                        cols[j].push(<TopProductCard index={i+j} 
                            onCardClicked={this.props.onCardClicked} product={product} {...this.props.eventHandlers}
                            key={`prod-${i+j}`}/>);
                    }
                }

                let rows = [];
                for (let i = 0; i < perRow; i++)
                    rows.push(<ImageGridCol key={`prod-col-${i}`}>{cols[i]}</ImageGridCol>);

                return rows;
            };
        }
        else
        {
            makeCards = () => {
                let cols = [[], [], []];
                const evenSize = products.length - remainder;
                
                for (let i = 0; i < evenSize; i += perRow) {
                    for (let j = 0; j < perRow; j++) {
                        const product = products[i+j];
                        cols[j].push(<TopProductCard index={i+j} 
                            onCardClicked={this.props.onCardClicked} product={product} {...this.props.eventHandlers}
                            key={`prod-${i+j}`}/>);
                    }
                }

                let rows = [];
                for (let i = 0; i < perRow; i++)
                    rows.push(<ImageGridCol key={`prod-col-${i}`}>{cols[i]}</ImageGridCol>);

                for (let i = evenSize; i < products.length; i++) {
                    const product = products[i];
                    rows.push(<ImageGridCol key={`prod-col-${i}`}><TopProductCard index={i} 
                        onCardClicked={this.props.onCardClicked} product={product} 
                        {...this.props.eventHandlers} /></ImageGridCol>)
                }

                return rows;
            };
        }

        return (
            <CSSTransition
                in={this.state.transition}
                timeout={1100}
                classNames="products-grid-transition">
                <ImageGridRow>{makeCards()}</ImageGridRow>
            </CSSTransition>
        )
    }
}

export class Pagination extends Component {
    constructor(props) {
        super(props);

        const items = this.props.items ? this.props.items : [];

        this.state = {'items': items};
    }

    render() {
        return (
            <h1>Paginate!</h1>
        );
    }
}

export class ScrollToTopOnMount extends Component {
    componentDidMount() {
        animateScroll.scrollToTop({
            duration: 700,
            delay: 0,
            smooth: true,
        });
    }
  
    render() {
      return null;
    }
}

export const ScrollToTopOnUpdate = () => {
    useEffect(() => {
        $('html, body').animate({
            scrollTop: 0
          }, 800)
    });
    
    return null;
}

export class ImageGridRow extends Component {
    render() {
        let classes = this.props.className ? this.props.className : '';
        classes += " img-grid-row";
        return <div className={classes}>{this.props.children}</div>
    }
}

export class ImageGridCol extends Component {
    render() {
        let classes = this.props.className ? this.props.className : '';
        classes += " img-grid-col";
        return <div className={classes}>{this.props.children}</div>
    }
}

export function makeTopProductObject(imgSrc, title = 'The Product Name Goes here...', price = 1000) {
    return {'imgSrc': imgSrc, 'title': title, 'price': price};
}

export function paginate(items, currentIndex, perPage = 6) {
    if (currentIndex >= items.length)
        return {'currentIndex': currentIndex, 'items': []};

    if (perPage > items.length) {
        const newCurrIndex = items.length - 1;

        return ({
            'currentIndex': newCurrIndex,
            'items': items.slice(currentIndex, newCurrIndex + 1),
        });
    }

    const remainder = items.length - currentIndex;

    if (remainder <= perPage) {
        const extra = perPage - remainder;
        const newCurrIndex = currentIndex + (perPage - extra - 1);
        
        return ({
            'currentIndex': newCurrIndex,
            'items': items.slice(currentIndex, newCurrIndex + 1),
        });
    }
    else {
        const newCurrIndex = currentIndex + perPage - 1;
        
        return ({
            'currentIndex': newCurrIndex,
            'items': items.slice(currentIndex, newCurrIndex + 1),
        });
    }
}

export function generatePagination(pages, pageChangedCallback = (data) => {}, pagesMargin = 2, pagesRange = 2, prevLabel = 'Previous', nxtLabel = 'Next',
    initialPage = 0) {
    return (
        <ReactPaginate key={`paginator-${Math.random() + Math.random()}`}
            previousLabel={prevLabel}
            nextLabel={nxtLabel}
            breakLabel={'...'}
            breakClassName={'page-item'}
            breakLinkClassName={'page-link'}
            pageCount={pages}
            marginPagesDisplayed={pagesMargin}
            pageRangeDisplayed={pagesRange}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            previousClassName={'page-item prev'}
            previousLinkClassName={'page-link prev-link'}
            nextClassName={'page-item next'}
            nextLinkClassName={'page-link next-link'}
            containerClassName={'pagination pagination-lg'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            onPageChange={pageChangedCallback}
            initialPage={initialPage}
            disableInitialCallback={true}
        />
    );
}

export function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export function searchParams() {
    console.log(window.location.href);
    return new URLSearchParams(window.location.href);
}

export const Loading = () => {
    return (
        <CommonLoading speed={1} color="rgb(199, 86, 42)" size="large" style={{position: 'relative', right: 'auto', 
            left: '50%', transform: 'translate(-50%, 0)'}}/>
    );
};

export const showError = (title, body) => {
    ReactSwal.fire({
        title: title,
        html: body,
        icon: 'error',
        confirmButtonText: 'Retry',
    });
};

export const showSuccess = (title, body = '', timeout = 2000) => {
    ReactSwal.fire({
        title: title,
        html: body,
        icon: 'success',
        timer: timeout,
    });
};

export const showInfo = (title, body = '', timeout = 2000) => {
    ReactSwal.fire({
        title: title,
        html: body,
        icon: 'info',
        timer: timeout,
    });
};

export const ProgressBar = ({progress}) => {
    return (
        <div className="progress mt-3">
            <div className="progress-bar progress-bar-striped progress-bar-animated bg-success"
            style={{width: `${progress}%`}}>{`${progress}%`}
            </div>
        </div>
    );
};

export const ProgressModal = ({task}) => {
    const [progress, setProgress] = useState(0);

    // Start the task
    useEffect(() => task(setProgress), [task]);

    // Render the modal
    return <ProgressBar progress={progress} />;
};

export const showLoading = (title = 'Please wait...') => {
    ReactSwal.fire({
        title: title,
        allowOutsideClick: false,
        allowEnterKey: false,
        allowEscapeKey: false,
        onOpen: () => {
            ReactSwal.showLoading();
        }
    });
};

export const showCartLoading = (title = 'Processing cart...') => {
    ReactSwal.fire({
        title: title,
        allowOutsideClick: false,
        allowEnterKey: false,
        allowEscapeKey: false,
        position: 'top-end',
        onOpen: () => {
            ReactSwal.showLoading();
        }
    });
};

export const showNetworkError = () => {
    ReactSwal.fire({
        title: 'Network Error!',
        text: 'A network error occured, please try again.',
        icon: 'error',
        confirmButtonText: 'Retry'
    });
};

export const showSubscriptionError = () => {
    ReactSwal.fire({
        title: 'Subscription Error!',
        text: 'The subscription process failed, please try again',
        icon: 'error',
        confirmButtonText: 'Retry'
    });
};

export const showPaymentError = () => {
    ReactSwal.fire({
        title: 'Payment Error!',
        text: 'The purchase failed, please try again',
        icon: 'error',
        confirmButtonText: 'Retry'
    });
};

export const showAddedToCart = () => {
    ReactSwal.fire({
        title: <strong>Added to cart!</strong>,
        icon: 'success',
        timer: 2000,
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
    });
};

export const showAlreadyInCart = () => {
    ReactSwal.fire({
        title: <strong>This product is already in your cart!</strong>,
        icon: 'info',
        timer: 2000,
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
    });
};

export const showToastSuccess = (message) => {
    ReactSwal.fire({
        title: <strong>{message}</strong>,
        icon: 'success',
        timer: 2000,
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
    });
};

export const showToastInfo = (message) => {
    ReactSwal.fire({
        title: <strong>{message}</strong>,
        icon: 'info',
        timer: 2000,
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
    });
};

export const dismissModal = () => {
    ReactSwal.close();
};

const DownloadOptions = ({product, width, height, onDownload}) => {
    const medium = {width: Math.round(width/2), height: Math.round(height/2)};
    const small = {width: Math.round(width/4), height: Math.round(height/4)};

    const [customWidth, setCustomWidth] = useState();
    const [customHeight, setCustomHeight] = useState();
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const onWidthChanged = e => {
        const newValue = e.target.value;
        if (newValue === '')
        {
            setCustomWidth(newValue);
            return;
        }

        if (newValue <= 0) {
            setHasError(true);
            setErrorMessage('Invalid dimension!');
        }
        else if (newValue > width) {
            setHasError(true);
            setErrorMessage('Too large!');
        }
        else {
            setHasError(false);
            setCustomWidth(newValue);
        }
    };

    const onHeightChanged = e => {
        const newValue = e.target.value;
        if (newValue === '')
        {
            setCustomWidth(newValue);
            return;
        }

        if (newValue <= 0) {
            setHasError(true);
            setErrorMessage('Invalid dimension!');
        }
        else if (newValue > height) {
            setHasError(true);
            setErrorMessage('Too large!');
        }
        else {
            setHasError(false);
            setCustomHeight(newValue);
        }
    };

    const onDownloadOriginal = e => {
        e.preventDefault();

        if (onDownload)
            onDownload(product, width, height);
    };

    const onDownloadMedium = e => {
        e.preventDefault();

        if (onDownload)
            onDownload(product, medium.width, medium.height);
    };

    const onDownloadSmaller = e => {
        e.preventDefault();

        if (onDownload)
            onDownload(product, small.width, small.height);
    };

    const onDownloadCustom = e => {
        e.preventDefault();

        if (hasError || customWidth === '' || customHeight === '') {
            return;
        }
        else {
            if (onDownload)
                onDownload(product, customWidth, customHeight);
        }
    };

    return (
        <div className="dropdown-menu">
            <a className="dropdown-item" href="#" onClick={onDownloadOriginal}>{`Original (${width} x ${height})`}</a>
            <hr className="w-10 bg-black mb-1 mt-1"/>
            <a className="dropdown-item" href="#" onClick={onDownloadMedium}>{`Medium (${medium.width} x ${medium.height})`}</a>
            <hr className="w-10 bg-black mb-1 mt-1"/>
            <a className="dropdown-item" href="#" onClick={onDownloadSmaller}>{`Smaller (${small.width} x ${small.height})`}</a>
            <hr className="w-10 bg-black mb-0"/>

            <div className="p-3 px-4">
                <h5>Custom Dimension</h5>
                
                {!hasError ? '' :
                    <div className="alert alert-danger roboto-font">
                        {errorMessage}
                    </div>
                }
                <form className="mt-3" onSubmit={onDownloadCustom}>
                    <div className="row">
                        <div className="col-sm mb-2">
                            <input type="number" className="form-control mb-2 mr-2" placeholder="Width" value={customWidth}
                                onChange={onWidthChanged} required/>
                        </div>
                        <div className="col-sm mb-2">
                            <input type="number" className="form-control mb-2 mr-2" placeholder="Height" value={customHeight}
                                onChange={onHeightChanged} required/>
                        </div>
                    </div>
                    <button type="submit" className="btn btn-dark btn-block mx-1">Resize</button>
                </form>
            </div>
        </div>
    );
};

export const createProductsModal = (products, currIndex, onDownload = (product, width, height) => ({}), 
    onCollect = (product) => ({}), onFollow = (photographer_username) => ({})) => {
    const exitModal = () => ReactSwal.close();

    const getItemClasses = (index) => index === currIndex ? 'carousel-item active' : 'carousel-item';
    
    return (
        <div className="jumbotron p-0 m-0">
            <div className="p-2 mb-4 text-left shadow-lg">
                <span className="fas fa-times text-dark" onClick={exitModal}></span>
            </div>

            <div id="photos-carousel" className="carousel slide mt-4" data-ride="carousel">
                <div className="carousel-inner">
                    {products.map((product, index) => {
               
                        return (
                            <div className={getItemClasses(index)} key={index}>
                                <div className="container-fluid px-3 pb-5 d-none d-lg-block">
                                    <div className="row">
                                        <div className="col">
                                            <div className="row">
                                                <div className="col-2 pr-2"><img src={product.photographer_avatar} className="img-fluid rounded" alt="Product" /></div>
                                                <div className="col-3 text-left px-0 pt-0 text-dark">
                                                    <h6>@{product.photographer_username}</h6>
                                                    <h6 className="roboto-font">{product.photographer}</h6>
                                                </div>
                                                <div className="col text-left p-0 text-dark">
                                                    <button className="btn btn-sm btn-light float-leftrounded rounded-lg mr-3 mt-2"
                                                        onClick={e => onFollow(product.photographer_username)}>
                                                        <span className="fas fa-user mr-2"></span>Follow
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col">
                                            <div className="row">
                                                <div className="col text-right p-0">
                                                    <button className="btn btn-light rounded rounded-lg mr-2">
                                                        <span className="far fa-heart mr-2"></span>{product.views} Views
                                                    </button>

                                                    <button className="btn btn-light rounded rounded-lg mr-2"
                                                        onClick={e => onCollect(product)}>
                                                    <span className="fas fa-plus-circle mr-2"></span>Collect
                                                    </button>
                                                </div>

                                                <div className="col text-right">
                                                    <div className="dropdown">
                                                        {product.price > 0 ? 
                                                            <button type="button" className="btn btn-block btn-dark dropdown-toggle pr-2 text-left"
                                                                data-toggle="dropdown">Download <span className="montserrat-font ml-2">&#8358;{product.price}</span>
                                                                <span className="fas fa-chevron-down float-right ml-4 mt-1"></span>
                                                            </button> 
                                                            : 
                                                            <button type="button" className="btn btn-block btn-dark dropdown-toggle pr-2 text-left"
                                                                data-toggle="dropdown">Download <span className="montserrat-font ml-2">FREE</span>
                                                                <span className="fas fa-chevron-down float-right ml-4 mt-1"></span>
                                                            </button> 
                                                        }

                                                        <DownloadOptions product={product} width={product.width} height={product.height} onDownload={onDownload}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="p-1 d-none d-lg-block">
                                    <img src={product.img_url} className="img-fluid w-50" style={{maxHeight: '80vh'}} alt="Product" />
                                </div>

                                <div className="p-1 d-lg-none">
                                    <img src={product.img_url} className="img-fluid" alt="Product" />
                                </div>

                                <div className="container-fluid px-3 pb-5 d-lg-none mt-5">
                                    <div className="row">
                                        <div className="col-md mb-3">
                                            <div className="row">
                                                <div className="col-2 pr-2"><img src={product.photographer_avatar} className="img-fluid rounded" alt="Product" /></div>
                                                <div className="col-5 text-left px-0 pt-0 text-dark">
                                                    <h6>@{product.photographer_username}</h6>
                                                    <h6 className="roboto-font text-sm">{product.photographer}</h6>
                                                </div>
                                                <div className="col text-left p-0 text-dark">
                                                    <button className="btn btn-sm btn-light float-leftrounded rounded-lg mr-3 mt-2"
                                                        onClick={e => onFollow(product.photographer_username)}>
                                                        <span className="fas fa-user mr-2"></span>Follow
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md">
                                            <div className="row">
                                                <div className="col-lg text-right p-0 mb-2">
                                                    <button className="btn btn-light rounded rounded-lg mr-2">
                                                        <span className="far fa-heart mr-2"></span>{product.views} Views
                                                    </button>

                                                    <button className="btn btn-light rounded rounded-lg mr-2"
                                                        onClick={e => onCollect(product)}>
                                                    <span className="fas fa-plus-circle mr-2"></span>Collect
                                                    </button>
                                                </div>

                                                <div className="col-lg text-right">
                                                    <div className="dropdown">
                                                        {product.price > 0 ? 
                                                            <button type="button" className="btn btn-block btn-dark dropdown-toggle pr-2 text-left"
                                                                data-toggle="dropdown">Download <span className="montserrat-font ml-2">&#8358;{product.price}</span>
                                                                <span className="fas fa-chevron-down float-right ml-4 mt-1"></span>
                                                            </button> 
                                                            : 
                                                            <button type="button" className="btn btn-block btn-dark dropdown-toggle pr-2 text-left"
                                                                data-toggle="dropdown">Download <span className="montserrat-font ml-2">FREE</span>
                                                                <span className="fas fa-chevron-down float-right ml-4 mt-1"></span>
                                                            </button> 
                                                        }

                                                        <DownloadOptions product={product} width={product.width} height={product.height} onDownload={onDownload}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>

                <div className="prev">
                    <a href="#photos-carousel" data-slide="prev">
                        <span className="fas fa-chevron-left translucent-bg-50 rounded fa-2x p-2"></span>
                    </a>
                </div>
                <div className="next">
                    <a href="#photos-carousel" data-slide="next">
                        <span className="fas fa-chevron-right translucent-bg-50 rounded fa-2x p-2"></span>
                    </a>
                </div>
            </div>
        </div>
    );
};

export const showProductsModal = (products, currIndex, onDownload = () => ({}),
    onCollect = (product) => ({}), onFollow = (photographer_username) => ({})) => {
    if (products.length > 0) {
        ReactSwal.fire({
            html: createProductsModal(products, currIndex, onDownload, onCollect, onFollow),
            padding: "0",
            showConfirmButton: false,
            position: 'top',
            customClass: {
                content: 'p-0',
                popup: 'products-popup',
            }
        });
    }
};

export const createProductsModalAlt = (products, currIndex, onDownload = (product, width, height) => ({})) => {
    const exitModal = () => ReactSwal.close();

    const getItemClasses = (index) => index === currIndex ? 'carousel-item active' : 'carousel-item';
    
    return (
        <div className="jumbotron p-0 m-0">
            <div className="p-2 mb-4 text-left shadow-lg">
                <span className="fas fa-times text-dark" onClick={exitModal}></span>
                
            </div>

            <div id="photos-carousel" className="carousel slide mt-4" data-ride="carousel">
                <div className="carousel-inner">
                    {products.map((product, index) => {
               
                        return (
                            <div className={getItemClasses(index)} key={index}>
                                <div className="container-fluid px-3 pb-2 text-right">
                                    <div className="dropdown">
                                        <button type="button" className="btn btn-block btn-dark dropdown-toggle pr-2 text-left"
                                            data-toggle="dropdown">Download Options<span className="fas fa-chevron-down float-right ml-4 mt-1"></span>
                                        </button>

                                        <DownloadOptions product={product} width={product.width} height={product.height} onDownload={onDownload}/>
                                    </div>
                                </div>

                                <div className="p-1 d-none d-lg-block">
                                    <img src={product.img_url} className="img-fluid w-50" style={{maxHeight: '80vh'}} alt="Product" />
                                </div>

                                <div className="p-1 d-lg-none">
                                    <img src={product.img_url} className="img-fluid" alt="Product" />
                                </div>
                            </div>
                        );
                    })}
                </div>

                <div className="prev">
                    <a href="#photos-carousel" data-slide="prev">
                        <span className="fas fa-chevron-left translucent-bg-50 rounded fa-2x p-2"></span>
                    </a>
                </div>
                <div className="next">
                    <a href="#photos-carousel" data-slide="next">
                        <span className="fas fa-chevron-right translucent-bg-50 rounded fa-2x p-2"></span>
                    </a>
                </div>
            </div>
        </div>
    );
};

export const showProductsModalAlt = (products, currIndex, onDownload = (product, width, height) => ({})) => {
    if (products.length > 0) {
        ReactSwal.fire({
            html: createProductsModalAlt(products, currIndex, onDownload),
            padding: "0",
            showConfirmButton: false,
            position: 'top',
            customClass: {
                content: 'p-0',
                popup: 'products-popup',
            }
        });
    }
};

export const createProductsModalPhotographer = (products, currIndex, onEdit = (product) => ({})) => {
    const exitModal = () => ReactSwal.close();

    const getItemClasses = (index) => index === currIndex ? 'carousel-item active' : 'carousel-item';
    
    return (
        <div className="jumbotron p-0 m-0">
            <div className="p-2 mb-4 text-left shadow-lg">
                <span className="fas fa-times text-dark" onClick={exitModal}></span>
            </div>

            <div id="photos-carousel" className="carousel slide mt-4" data-ride="carousel">
                <div className="carousel-inner">
                    {products.map((product, index) => {
               
                        return (
                            <div className={getItemClasses(index)} key={index}>
                                <div className="container-fluid px-3 pb-5">
                                    <div className="row">
                                        <div className="col">
                                            <h6 className="text-left mb-2 montserrat-font">{product.name}</h6>
                                            <h6 className="text-left mb-4 montserrat-font">&#8358;{product.price}</h6>
                                            <div className="w-100">
                                            <button type="button" className="btn btn-block btn-dark pr- text-left"
                                                onClick={e => onEdit(product)}>Edit Photo<span className="fas fa-edit float-right"></span>
                                            </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="p-1 d-none d-lg-block">
                                    <img src={product.img_url} className="img-fluid w-50" style={{maxHeight: '80vh'}} alt="Product" />
                                </div>

                                <div className="p-1 d-lg-none">
                                    <img src={product.img_url} className="img-fluid" alt="Product" />
                                </div>
                            </div>
                        );
                    })}
                </div>

                <div className="prev">
                    <a href="#photos-carousel" data-slide="prev">
                        <span className="fas fa-chevron-left translucent-bg-50 rounded fa-2x p-2"></span>
                    </a>
                </div>
                <div className="next">
                    <a href="#photos-carousel" data-slide="next">
                        <span className="fas fa-chevron-right translucent-bg-50 rounded fa-2x p-2"></span>
                    </a>
                </div>
            </div>
        </div>
    );
};

export const showProductsModalPhotographer = (products, currIndex, onEdit = (product) => ({})) => {
    if (products.length > 0) {
        ReactSwal.fire({
            html: createProductsModalPhotographer(products, currIndex, onEdit),
            padding: "0",
            showConfirmButton: false,
            position: 'top',
            customClass: {
                content: 'p-0',
                popup: 'products-popup',
            }
        });
    }
};

export const isUserLogged = () => {
    if (sessionStorage.getItem('user_data'))
        return true;

    return false;
};

export const AuthComponent = ({onLogin, onCreateAccount}) => {
    const onLoginEvent = e => {
        e.preventDefault();
        onLogin();
    };

    const onCreateAccountEvent = e => {
        e.preventDefault();
        onCreateAccount();
    };

    return (
        <div className="mt-4">
            <button className="btn btn-block btn-outline-info" onClick={onLoginEvent}>Login</button>
            <button className="btn btn-block btn-info" onClick={onCreateAccountEvent}>Create Account</button>
        </div>
    );
};

export const initiatePurchase = (product, onSuccessfull = () => ({}), 
    onProductExists = () => ({})) => {
    
    const addToUserCollection = (reference) => {
        // Remove any existing reference id
        sessionStorage.removeItem('purchase_prod_ref');

        // Add this product to the user's collection
        axios.post('user/collection', {reference: reference, id: product.id})
        .then((res) => {
            // If we get back a positive reponse (code 200), then callback
            if (res.status === 200) {
                dismissModal();
                onSuccessfull(res.data.id);
            }
            else {
                // We could not move the product
               dismissModal();
               showError('Oops!', 'We could not add the requested file to your collection. If you\'ve been debited for this transaction please contact us ASAP!');
            }
        })
        .catch((error) => {
            showNetworkError();
        });
    };

    const initCharge = () => {
        axios.post('user/purchase/init/' + product.id)
        .then((reponse) => {
            // eslint-disable-next-line no-undef
            let handler = PaystackPop.setup({
                ...reponse.data,
                onClose: function() {
                    // No transaction recorded because the user closed the popup
                    showPaymentError();
                },
                callback: (response) => {
                    // We have a response from paystack, let's verify the transaction before registering the user
                    // Save the reference first, in case something goes wrong, we can reuse to avoid repeated payment!
                    sessionStorage.setItem('purchase_prod_ref', response.reference);
                    axios.post('transaction/verify', {reference: response.reference})
                    .then((res) => {
                        // If we get back a positive reponse (code 200), then proceed and register the user
                        if (res.status === 200) {
                            addToUserCollection(response.reference);
                        }
                        else {
                            showPaymentError();
                        }
                    })
                    .catch((error) => {
                        showPaymentError();
                    })
                }
            });

            handler.openIframe();
        })
        .catch((error) => {
            showNetworkError();
        });
    };

    // Start the payment process
    showLoading();

    // If this product is already in the user's collection, call the onProductExists() callback
    axios.get('user/collection/verify/' + product.id)
    .then((res) => {
        // If we get back a positive reponse (code 200), then this product is already there
        if (res.status === 200) {
            // Remove any existing reference id
            sessionStorage.removeItem('purchase_prod_ref');
            dismissModal();

            // Let the caller know
            onProductExists(res.data.id);
        }
        else if (res.status === 404) {
            // The product is not existing in the collection, proceed with the payment

            // if the product is free, move it (no payment required)
            if (product.price === '0') {
                addToUserCollection('thIsIsaDummyRef');
            }
            else {
                // if there is an existing reference code, let's verify it
                if (sessionStorage.getItem('purchase_prod_ref')) {
                    const ref = sessionStorage.getItem('purchase_prod_ref');

                    // verify it
                    axios.post('transaction/verify', {reference: ref})
                    .then((res) => {
                        // If we get back a positive reponse (code 200), then proceed and add the product to collection
                        if (res.status === 200) {
                            addToUserCollection(ref);
                        }
                        else {
                            // The reference is invalid, remove it and init payment process
                            sessionStorage.removeItem('purchase_prod_ref');
                            initCharge();
                        }
                    })
                    .catch((error) => {
                        showNetworkError();
                    });
                }
                else {
                    initCharge();
                }
            }
        }
        else
            showNetworkError();
    })
    .catch((error) => {
        showNetworkError();
    });
};

export const initiateBulkPurchase = (products, totalPrice, onSuccessfull = () => ({})) => {
    
    const addToUserCollection = (reference) => {
        // Remove any existing reference id
        sessionStorage.removeItem('purchase_prod_ref');

        // Add these products to the user's collection
        axios.post('user/collection/bulk', {reference: reference, products: products})
        .then((res) => {
            // If we get back a positive reponse (code 200), then callback
            if (res.status === 200) {
                dismissModal();
                onSuccessfull(res.data.id);
            }
            else {
                // We could not move the products
               dismissModal();
               showError('Oops!', 'We could not add the requested photos to your collection. If you\'ve been debited for this transaction please contact us ASAP!');
            }
        })
        .catch((error) => {
            showNetworkError();
        });
    };

    const initCharge = () => {
        axios.post('user/purchase/bulk/init', {...products})
        .then((reponse) => {
            // eslint-disable-next-line no-undef
            let handler = PaystackPop.setup({
                ...reponse.data,
                onClose: function() {
                    // No transaction recorded because the user closed the popup
                    showPaymentError();
                },
                callback: (response) => {
                    // We have a response from paystack, let's verify the transaction before registering the user
                    // Save the reference first, in case something goes wrong, we can reuse to avoid repeated payment!
                    sessionStorage.setItem('purchase_prod_ref', response.reference);
                    axios.post('transaction/verify', {reference: response.reference})
                    .then((res) => {
                        // If we get back a positive reponse (code 200), then proceed and register the user
                        if (res.status === 200) {
                            addToUserCollection(response.reference);
                        }
                        else {
                            showPaymentError();
                        }
                    })
                    .catch((error) => {
                        showPaymentError();
                    })
                }
            });

            handler.openIframe();
        })
        .catch((error) => {
            showNetworkError();
        });
    };

    // Start the payment process
    showLoading();

    // if the products are free, move them (no payment required)
    if (totalPrice === 0) {
        addToUserCollection('thIsIsaDummyRef');
    }
    else {
        // if there is an existing reference code, let's verify it
        if (sessionStorage.getItem('purchase_prod_ref')) {
            const ref = sessionStorage.getItem('purchase_prod_ref');

            // verify it
            axios.post('transaction/verify', {reference: ref})
            .then((res) => {
                // If we get back a positive reponse (code 200), then proceed and add the product to collection
                if (res.status === 200) {
                    addToUserCollection(ref);
                }
                else {
                    // The reference is invalid, remove it and init payment process
                    sessionStorage.removeItem('purchase_prod_ref');
                    initCharge();
                }
            })
            .catch((error) => {
                showNetworkError();
            });
        }
        else {
            initCharge();
        }
    }
};

export const download = (collection_id, filename, width = 0, height = 0) => {
    const uploadTask = (updateProgress) => {
        axios.get('user/collection/get/' + collection_id, {
            responseType: 'blob',
            params: {
                width: width,
                height: height,
            },
            onDownloadProgress: (ev) => {
                const progress = ev.loaded / ev.total * 100;
                updateProgress(Math.round(progress));
            },
        })
        .then((res) => {
            // If we get back a positive reponse (code 200), then the file was successfully retrieved
            if (res.status === 200) {
                showSuccess('Download complete!');
                fileDownload(res.data, filename);
            }
            else {
                // We could not move the product
                dismissModal();
                showError('Oops!', 'Download failed');
            }
        })
        .catch((error) => {
            showNetworkError();
        });
    };

    ReactSwal.fire({
        title: 'Downloading...',
        html: <ProgressModal task={uploadTask}/>,
        allowOutsideClick: false,
        allowEnterKey: false,
        allowEscapeKey: false,
        showConfirmButton: false,
    });
};

export const verifyUserSession = (authDispatch, onVerified = () => ({}), onFail = () => ({}),
    shouldShowLoading = true) => {
    const clearData = () => {
        // destroy the user's session data from clientside
        sessionStorage.removeItem('user_data');

        // Clear any session data related to auth
        sessionStorage.removeItem('auth');

        // Dispatch an auth action
        authDispatch(false, '', '');

        // Make a network request to clear the current sesssion
        axios.delete('user/session');
        dismissModal();
        onFail();
    };

    if (shouldShowLoading === true)
        showLoading();

    if (isUserLogged) {
        const session_data = JSON.parse(sessionStorage.getItem('user_data'));

        axios.post('user/session', session_data)
        .then((response) => {
            dismissModal();

            if (response.status === 200 || response.status === 204) {
                // The session is still valid
                onVerified();
            }
            else {
                // Invalid session
                // Clear any session data related to auth
                clearData();
            }
        })
        .catch((error) => {
            clearData();
        });
    }
    else {
        clearData();
    }
};

export const followPhotographer = (photographer_username, onSuccessfull = () => ({}),
    onAlreadyFollowing = () => ({})) => {

    showLoading();

    axios.post('user/follow/' + photographer_username)
    .then((response) => {
        dismissModal();

        if (response.status === 204) {
            // Followed successfully
            onSuccessfull();
        }
        else if (response.status === 409) {
            // Already following
            onAlreadyFollowing();
        }
        else {
            showNetworkError();
        }
    })
    .catch((error) => {
        showNetworkError();
    });
};

export const verifyProductInCollection = (product, 
    onVerified = (collection_id) => ({}), onFail = () => ({})) => {

    showLoading();

    axios.get('user/collection/verify/' + product.id)
    .then((res) => {
        dismissModal();

        // If we get back a positive reponse (code 200), then this product is already there
        if (res.status === 200) {
            // Let the caller know
            onVerified(res.data.id);
        }
        else if (res.status === 404) {
            // The product is not existing in the collection, callback
            onFail();
        }
        else
            showNetworkError();
    })
    .catch((error) => {
        showNetworkError();
    });
};

export const verifyProductInCollectionDashboard = (product, 
    onVerified = (collection_id) => ({}), onFail = () => ({})) => {

    showLoading();

    axios.get('user/collection/verify/' + product.product_id)
    .then((res) => {
        dismissModal();

        // If we get back a positive reponse (code 200), then this product is already there
        if (res.status === 200) {
            // Let the caller know
            onVerified(res.data.id);
        }
        else if (res.status === 404) {
            // The product is not existing in the collection, callback
            onFail();
        }
        else
            showNetworkError();
    })
    .catch((error) => {
        showNetworkError();
    });
};

export const productViewed = (product) => {
    axios.post('view/' + product.id);
};

export const deleteProductFromCollection = (product, 
    onSuccessfull = () => ({}), onFail = () => ({})) => {

    showLoading();

    axios.delete('user/collection/' + product.id)
    .then((res) => {
        dismissModal();

        // If we get back a positive reponse (code 200), then the product was deleted
        if (res.status === 200) {
            // Let the caller know
            onSuccessfull();
        }
        else {
            // The product is was not deleted
            onFail();
        }
    })
    .catch((error) => {
        showNetworkError();
    });
};

export const deleteProductFromPortfolio = (product, 
    onSuccessfull = () => ({}), onFail = () => ({})) => {

    showLoading();

    axios.delete('photographer/photo/' + product.id)
    .then((res) => {
        dismissModal();

        // If we get back a positive reponse (code 200), then the product was deleted
        if (res.status === 200) {
            // Let the caller know
            onSuccessfull();
        }
        else {
            // The product is was not deleted
            onFail();
        }
    })
    .catch((error) => {
        showNetworkError();
    });
};

export const deleteProductFromUnapproved = (product, 
    onSuccessfull = () => ({}), onFail = () => ({})) => {

    showLoading();

    axios.delete('admin/unapproved_photo/' + product.id)
    .then((res) => {
        dismissModal();

        // If we get back a positive reponse (code 200), then the product was deleted
        if (res.status === 200) {
            // Let the caller know
            onSuccessfull();
        }
        else {
            // The product is was not deleted
            onFail();
        }
    })
    .catch((error) => {
        showNetworkError();
    });
};

export const showContactForm = () => {
    const ContactForm = () => {
        const [data, setData] = useState({
            email: '',
            subject: '',
            message: '',
        });
        const [recaptchaDone, setRecaptchaDone] = useState(false);

        const handleInputChanged = e => {
            const name = e.target.name;
            const value = e.target.value;
            data[name] = value;
            setData({...data});
        };

        const handleContact = e => {
            e.preventDefault();

            if (!recaptchaDone) {
                showError('', 'Please complete the reCaptcha challenge!');
                return;
            }

            showLoading();
            
            axios.post('contact', {
                email: data.email,
                subject: data.subject,
                message: data.message,
            })
            .then((response) => {
                if (response.status === 204) {
                    showSuccess('Message sent.')
                }
                else if (response.status === 400) { //Validation Error
                    ReactSwal.fire({
                        title: 'Validation Error!',
                        html: response.data.messages.error,
                        icon: 'error',
                        confirmButtonText: 'Retry'
                    });
                }
                else {
                    showNetworkError();
                }
            })
            .catch((error) => {
                showNetworkError();
            });
        };

        const onRecaptcha = status => {
            if (status) {
                setRecaptchaDone(true);
            }
            else {
                setRecaptchaDone(false);
            }
        };
        
        return (
            <div className="container mt-5 py-5">
                <div className="shadow-lg">
                    <h4 className="bg-dark text-white montserrat-font text-center p-3 mb-5">Contact Us</h4>

                    <form className="m-4 pb-5" onSubmit={handleContact}>
                        <div className="input-group">
                            <input type="email" className="form-control" placeholder="Your email address" name="email" value={data.email} 
                                onChange={handleInputChanged} maxLength="50" required/>
                        </div>
                        <div className="input-group mt-3">
                            <input type="text" className="form-control" placeholder="Subject" name="subject" value={data.subject} 
                                onChange={handleInputChanged} maxLength="50" required/>
                        </div>
                        <div className="input-group mt-3">
                            <textarea className="form-control" placeholder="Your message" name="message" 
                                onChange={handleInputChanged} maxLength="500" required>{data.message}</textarea>
                        </div>
                        
                        <div className="input-group row mt-3 text-left">
                            <div className="col">
                                <ReCAPTCHA 
                                sitekey={recaptchaKey}
                                onChange={onRecaptcha}/>
                            </div>
                        </div>

                        <div className="mt-5 text-left">
                            {recaptchaDone ? <button type="submit" className="btn btn-lg btn-dark">Send Message <span className="fas fa-arrow-right ml-5"></span></button> :
                            <button type="submit" className="btn btn-lg btn-dark disabled" disabled>Send Message <span className="fas fa-arrow-right ml-5"></span></button>}
                        </div>
                    </form>
                </div>
            </div>
        );
    };

    ReactSwal.fire({
        padding: "0",
        showConfirmButton: false,
        showCloseButton: true,
        customClass: {
            content: 'p-0',
            popup: 'products-popup',
        },
        html: <ContactForm />
    });
};