/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, Component } from 'react'
import { Link, useParams, Switch, Route, Redirect, HashRouter, NavLink, useLocation, useHistory } from 'react-router-dom';
import { ScrollToTopOnMount, useQuery, generatePagination, 
    axiosInstance, showError, showLoading, showNetworkError, showSubscriptionError,
    dismissModal, 
    ImageGridCol,
    ImageGridRow,
    Loading, ReactSwal} from './utils';
import $ from 'jquery';
import Swal from 'sweetalert2';
import * as creators from '../redux/actions/creators';
import { connect } from 'react-redux';
import * as constants from '../redux/actions/constants';
import { CSSTransition } from 'react-transition-group';
import * as utils from './utils';
import { animateScroll } from 'react-scroll';
import ReCAPTCHA from "react-google-recaptcha";
import passwordChecker from 'zxcvbn';

const Authorisation = {
    authenticate(onRespond, history, clearDashboardDispatcher) {
        onRespond(false);

      // Check if there is an existing session
      if (sessionStorage.getItem('user_data')) {
        // Validate the session data
        
        const session_data = JSON.parse(sessionStorage.getItem('user_data'));

        const axios = axiosInstance();
        axios.post('user/session', session_data)
        .then((response) => {
            if (response.status === 200 || response.status === 204) {
                // The session is still valid
                onRespond(true);
            }
            else {
                // Invalid session
                // Clear any session data related to auth
                dismissModal();
                sessionStorage.removeItem('auth');
                clearDashboardDispatcher();
                history.push('/user/login');
            }
        })
        .catch((error) => {
            // Clear any session data related to auth
            sessionStorage.removeItem('auth');
            clearDashboardDispatcher();

            Swal.fire({
                title: 'Authentication Error!',
                text: 'We could not validate the current session, please login again',
                icon: 'error',
                confirmButtonText: 'Login',
                timer: 2000,
                onAfterClose: () => {
                    history.push('/user/login');
                }
            });
        });
    }
    else {
        // If there is no current valid session, redirect to login page
        // Clear any session data related to auth
        dismissModal();
        sessionStorage.removeItem('auth');
        clearDashboardDispatcher();
        history.push('/user/login');
    }
    },
    logout() {
      // Clear the current session
      sessionStorage.removeItem('user_data');

      // Clear any session data related to auth
      sessionStorage.removeItem('auth');

      // Make a network request to clear the current sesssion
      const axios = axiosInstance();
      axios.delete('user/session');
    }
};

const Plan = ({ignoreScrollChange, user_plans, getUserPlans}) => {

    useEffect(() => {
        ignoreScrollChange();
        animateScroll.scrollToTop({
            duration: 700,
            delay: 0,
            smooth: true,
        });
    }, [ignoreScrollChange]);

    useEffect(() => {
        if (user_plans.length === 0)
            getUserPlans(); console.log(user_plans);
    });

    let basicDeal, silverDeal, goldDeal, silverPrice, goldPrice;
    if (user_plans.length === 3) {
        const plans = user_plans;
        basicDeal = (1 - parseFloat(plans[0].rate)) * 100;
        silverDeal = (1 - parseFloat(plans[1].rate)) * 100;
        goldDeal = (1 - parseFloat(plans[2].rate)) * 100;

        silverPrice = plans[1].price;
        goldPrice = plans[2].price;
    }

    return (
        <div className="roboto-font plan user-container">
            <ScrollToTopOnMount />

            <div className="plan-container jumbotron container-fluid text-center mt-5 mt-md-n1">
                {user_plans.length === 0 ? <Loading /> :
                    <div>
                        <h1 className="text-center montserrat-font title">Choose Subscription Plan</h1>
                        <h4 className="text-center roboto-font sub-title">Get access to high quality stock photos!</h4>

                        <div className="card-deck text-white">
                            <div className="card">
                                <h3 className="plan p-3 text-center mb-0 montserrat-font"><span>Basic</span> Plan</h3>
                                <h4 className="text-center p-3 discount mb-0 roboto-font">Now FREE!</h4>
                                
                                <div className="card-body text-dark">
                                    <h4 className="text-center roboto-font deal">You get <span className="montserrat-font">{basicDeal}%</span> discount!
                                    </h4>
                                </div>

                                <div className="card-footer">
                                    <Link to="register/basic" className="sub-link stretched-link">Register Now!</Link>
                                </div>
                            </div>

                            <div className="card">
                                <h3 className="plan p-3 text-center mb-0 montserrat-font"><span>Silver</span> Plan</h3>
                                <h4 className="text-center p-3 discount mb-0 roboto-font">Starting At<strong className="ml-2">&#8358;{silverPrice}/mo*</strong></h4>

                                <div className="card-body text-dark">
                                    <h4 className="text-center roboto-font deal">You get <span className="montserrat-font">{silverDeal}%</span> discount!</h4>
                                </div>

                                <div className="card-footer">
                                    <Link to="register/silver" className="sub-link stretched-link">Register Now!</Link>
                                </div>
                            </div>

                            <div className="card">
                                <h3 className="plan p-3 text-center mb-0 montserrat-font"><span>Gold</span> Plan</h3>
                                <h4 className="text-center p-3 discount mb-0 roboto-font">Starting At<strong className="ml-2">&#8358;{goldPrice}/mo*</strong></h4>

                                <div className="card-body text-dark">
                                    <h4 className="text-center roboto-font deal">You get <span className="montserrat-font">{goldDeal}%</span> discount!</h4>
                                </div>

                                <div className="card-footer">
                                    <Link to="register/gold" className="sub-link stretched-link">Register Now!</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export const Register = ({ignoreScrollChange}) => {
    
    useEffect(() => {
        ignoreScrollChange();
        
        animateScroll.scrollToTop({
            duration: 700,
            delay: 0,
            smooth: true,
        });
    }, [ignoreScrollChange]);

    const params = useParams();
    const [plan, setPlan] = useState(params.plan);
    const [tocChecked, setTocChecked] = useState(false);
    const [recaptchaDone, setRecaptchaDone] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState(0);
    const [passwordFeedback, setPasswordFeedback] = useState('Very weak password!');
    const [passwordStrengthColor, setPasswordStrengthColor] = useState('bg-danger');

    const history = useHistory();

    const [data, setData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        username: '',
        password: '',
    });

    const handlePlanChanged = e => {
        setPlan(e.target.value);
    };

    const handleInputChanged = e => {
        const name = e.target.name;
        const value = e.target.value;
        data[name] = value;
        setData({...data});

        if (name === 'password') {
            const score = passwordChecker(value).score;
            const progress = Math.round(score / 4 * 100);
            console.log(progress);
            setPasswordStrength(progress);

            if (progress < 20) {
                setPasswordFeedback('Very weak password!');
                setPasswordStrengthColor('bg-danger');
            }
            else if (progress < 50) {
                setPasswordFeedback('Weak password!');
                setPasswordStrengthColor('bg-danger');
            }
            else if (progress < 80) {
                setPasswordFeedback('Good password!');
                setPasswordStrengthColor('bg-warning');
            }
            else if (progress < 90) {
                setPasswordFeedback('Strong password!');
                setPasswordStrengthColor('bg-success');
            }
            else {
                setPasswordFeedback('Very Strong password!');
                setPasswordStrengthColor('bg-success');
            }
        }
    };

    const registerUser = (axios, reference = null) => {
        showLoading();
        
        const formData = new FormData();

        formData.append('first_name', data.first_name);
        formData.append('last_name', data.last_name);
        formData.append('email', data.email);
        formData.append('phone', data.phone);
        formData.append('username', data.username);
        formData.append('plan', plan);
        formData.append('password', data.password);
        
        if (reference)
            formData.append('reference', reference);

        axios.post('user/add', formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
        .then((response) => {
            if (response.status === 200) {
                sessionStorage.removeItem('user_sub_ref');

                ReactSwal.fire({
                    title: 'Account Created!',
                    text: 'Your account was created successfully. Please check your mail box to activate your account!',
                    icon: 'success',
                    confirmButtonText: 'Log In',
                    onAfterClose: () => history.push('/user/login'),
                });
            }
            else if (response.status === 400) { //Validation Error
                ReactSwal.fire({
                    title: 'Validation Error!',
                    html: response.data.messages.error,
                    icon: 'error',
                    confirmButtonText: 'Retry'
                });
            }
            else {
                showNetworkError();
            }
        })
        .catch((error) => {
            showNetworkError();
        });
    };

    const subscribeThenRegister = (axios) => {
        axios.post('user/sub/init', {'plan': plan, 'email': data.email})
        .then((reponse) => {
            // eslint-disable-next-line no-undef
            let handler = PaystackPop.setup({
                ...reponse.data,
                onClose: function() {
                    // No transaction recorded because the user closed the popup
                    showSubscriptionError();
                },
                callback: (response) => {
                    // We have a response from paystack, let's verify the transaction before registering the user
                    // Save the reference first, in case something goes wrong, we can reuse to avoid repeated payment!
                    sessionStorage.setItem('user_sub_ref', response.reference);
                    axios.post('transaction/verify', {reference: response.reference})
                    .then((res) => {
                        // If we get back a positive reponse (code 200), then proceed and register the user
                        if (res.status === 200) {
                            registerUser(axios, response.reference);
                        }
                        else {
                            showSubscriptionError();
                        }
                    })
                    .catch((error) => {
                        showSubscriptionError();
                    })
                }
            });

            handler.openIframe();
        })
        .catch((error) => {
            showNetworkError();
        });
    };

    const handleRegisteration = e => {
        e.preventDefault();

        // Make sure the TOC is accepted
        if (!tocChecked) {
            showError('', 'You have to accept the Terms of Service before you can register!');
            return;
        }

        if (!recaptchaDone) {
            showError('', 'Please complete the reCaptcha challenge!');
            return;
        }

        // If the password is weak, fail
        if (passwordStrength < 50) {
            showError('', 'Password is not strong enough!');
            return;
        }

        const axios = axiosInstance();

        if (plan === 'basic')
            registerUser(axios);
        else {
            showLoading();

            // Subscribe the user first

            // if there is an existing reference code, let's verify it
            if (sessionStorage.getItem('user_sub_ref')) {
                const ref = sessionStorage.getItem('user_sub_ref');

                // verify it
                axios.post('transaction/verify', {reference: ref})
                .then((res) => {
                    // If we get back a positive reponse (code 200), then proceed and register the user
                    if (res.status === 200) {
                        registerUser(axios, ref);
                    }
                    else {
                        // The reference is invalid, remove it and init subscription process
                        sessionStorage.removeItem('user_sub_ref');
                        subscribeThenRegister(axios);
                    }
                })
                .catch((error) => {
                    showNetworkError();
                });
            }
            else {
                subscribeThenRegister(axios);
            }
        }
    };

    const onRecaptcha = status => {
        if (status) {
            setRecaptchaDone(true);
        }
        else {
            setRecaptchaDone(false);
        }
    };

    return (
        <div className="roboto-font register photographer-container">
            <ScrollToTopOnMount />
            
            <div className="mb-5 w-100">
                <h5 className="d-md-inline mr-md-3">Are you a photographer?</h5>
                <Link to="/photographer/register" className="btn btn-dark">Create Photographer Account</Link>
            </div>

            <div className="container text-center shadow-lg px-0">
                <h3 className="header montserrat-font bg-dark text-white p-4 mb-0">Create User Account</h3>

                <div className="form-container jumbotron bg-white">
                <form onSubmit={handleRegisteration}>
                        <div className="input-group row mb-3">
                            <div className="col-sm mb-3 mb-md-0">
                                <input type="text" placeholder="First name" className="form-control" name="first_name" value={data.first_name} 
                                    onChange={handleInputChanged} maxLength="30" required/>
                            </div>
                            <div className="col-sm">
                                <input type="text" placeholder="Last name" className="form-control" name="last_name" value={data.last_name} 
                                    onChange={handleInputChanged} maxLength="30" required/>
                            </div>
                        </div>

                        <div className="input-group row mb-3">
                            <div className="col-sm mb-3 mb-md-0">
                                <input type="email" placeholder="Email" className="form-control" name="email" value={data.email} 
                                    onChange={handleInputChanged} maxLength="50" required/>
                            </div>
                            <div className="col-sm">
                                <input type="number" placeholder="Phone" className="form-control" name="phone" value={data.phone} 
                                    onChange={handleInputChanged} maxLength="15" required/>
                            </div>
                        </div>

                        <div className="input-group row mb-3">
                            <div className="col-sm mb-3 mb-md-0">
                                <input type="text" placeholder="Username" className="form-control" name="username" value={data.username} 
                                    onChange={handleInputChanged} maxLength="12" required/>
                            </div>
                            <div className="col-sm">
                                <input type="password" placeholder="Password" className="form-control" name="password" value={data.password} 
                                    onChange={handleInputChanged} required/>
                                {data.password ? 
                                <div className="mt-3">
                                    <div className="montserrat-font">{passwordFeedback}</div>
                                    <div className="progress mt-1">
                                        <div className={`progress-bar progress-bar-animated ${passwordStrengthColor}`}
                                            style={{width: `${passwordStrength}%`}}>
                                        </div>
                                    </div>
                                </div> : ''}
                            </div>
                        </div>

                        <div className="input-group row mb-5">
                            <div className="col-sm mb-3 mb-md-0">
                                <select value={plan} className="custom-select" onChange={handlePlanChanged}>
                                    <option value="basic">Basic Plan</option>
                                    <option value="silver">Silver Plan</option>
                                    <option value="gold">Gold Plan</option>
                                </select>
                            </div>
                        </div>

                        <div className="input-group row mb-4 text-left">
                            <div className="col">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="toc" name="toc" defaultChecked={tocChecked}
                                        onChange={e => setTocChecked(!tocChecked)}/>
                                    <label className="custom-control-label" htmlFor="toc">
                                        <div className="montserrat-font">Accept the PhotoStock NG Terms of Service</div>
                                        <p className="roboto-font mt-2 text-secondary">
                                            By accessing or using the PhotoStock NG platform, you agree to our <span className="text-primary montserrat-font"
                                            onClick={e => utils.showSuccess('Terms of Service not ready!')}>Terms of Use</span>.
                                            Please read and understand all applicable terms and policies before registering an account.
                                        </p>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="input-group row mb-3 text-left">
                            <div className="col">
                                <ReCAPTCHA 
                                sitekey={utils.recaptchaKey}
                                onChange={onRecaptcha}/>
                            </div>
                        </div>

                        <div className="mt-2 text-left">
                            {recaptchaDone ? <button type="submit" className="btn btn-lg btn-dark">Register <span className="fas fa-arrow-right ml-5"></span>
                            </button> :
                            <button type="submit" className="btn btn-lg btn-dark disabled" disabled>Register <span className="fas fa-arrow-right ml-5"></span>
                            </button>}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

const Login = ({ignoreScrollChange, authenticateUser, updateDashboard}) => {
    const queries = new useQuery();
    const history = useHistory();
    const [recaptchaDone, setRecaptchaDone] = useState(false);

    const redirect = queries.get('redirect') || '/user/dashboard';

    useEffect(() => {
        ignoreScrollChange();
        animateScroll.scrollToTop({
            duration: 700,
            delay: 0,
            smooth: true,
        });
    }, [ignoreScrollChange]);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    
    const handleLogin = e => {
        e.preventDefault();

        if (!recaptchaDone) {
            showError('', 'Please complete the reCaptcha challenge!');
            return;
        }

        showLoading();

        const axios = axiosInstance();
        axios.post('user/login', {}, {
            auth: {
                username: username,
                password: password,
            }
        })
        .then((response) => {
            dismissModal();
            
            if (response.status === 200) {
                sessionStorage.setItem('user_data', JSON.stringify(response.data));
                sessionStorage.removeItem('photographer_data');
                authenticateUser(true, '/user/dashboard', '/user/logout');
                updateDashboard(response.data);
                history.push(redirect);
            }
            else if (response.status === 404) { // Inactive account
                ReactSwal.fire({
                    title: 'Activation Error!',
                    text: 'Your account exist but is not activated yet. Please check your mail box for the activation link. We can resend the link if you have not seen it yet.',
                    icon: 'error',
                    confirmButtonText: 'Resend Link',
                    onAfterClose: () => history.push('/user/activate'),
                });
            }
            else {
                dismissModal();
                showError('Authentication Error!', 'Wrong username or password');
            }
        })
        .catch((error) => {
            dismissModal();
            showNetworkError();
        });
    };

    const onRecaptcha = status => {
        if (status) {
            setRecaptchaDone(true);
        }
        else {
            setRecaptchaDone(false);
        }
    };

    return (
        <div className="login roboto-font photographer-container">
            <ScrollToTopOnMount />

            <div className="mb-5">
                <Link to="/photographer/login" className="btn btn-lg btn-info"><span className="fas fa-arrow-left mr-3"></span>Photographer Login</Link>
            </div>

            <div className="container text-center shadow-lg px-0">
                <h3 className="header montserrat-font bg-dark text-white p-4 mb-0">User Login</h3>
                
                <div className="form-container jumbotron bg-light">
                    <form onSubmit={handleLogin}>
                        <div className="input-group row mb-3">
                            <div className="col-sm mb-3 mb-md-0">
                                <input type="text" placeholder="Username" className="form-control" value={username} 
                                    onChange={e => setUsername(e.target.value)} maxLength="12" required/>
                            </div>
                            <div className="col-sm">
                                <input type="password" placeholder="Password" className="form-control" 
                                    onChange={e => setPassword(e.target.value)} required/>
                            </div>
                        </div>

                        <div className="input-group row mb-3 text-left">
                            <div className="col">
                                <ReCAPTCHA 
                                sitekey={utils.recaptchaKey}
                                onChange={onRecaptcha}/>
                            </div>
                        </div>

                        <div className="mt-5 text-left">
                            {recaptchaDone ? <button type="submit" className="btn btn-lg btn-dark">Login <span className="fas fa-arrow-right ml-5"></span></button> :
                            <button type="submit" className="btn btn-lg btn-dark disabled" disabled>Login <span className="fas fa-arrow-right ml-5"></span></button>}
                            <Link to="/user/reset_password" className="ml-3 ml-md-5">Forgot password?</Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

const Logout = ({ authenticateUser, removeDashboard }) => {
    const history = useHistory();

    useEffect(() => {
        Authorisation.logout();
        authenticateUser(false, '', '');
        removeDashboard();
        history.push('/');
    });

    return null;
};

const Dashboard = ({ignoreScrollChange, dashboard, authenticateUser, removeDashboard, getNavBarHeight}) => {
    const history = useHistory();

    const [collectionsCategory, setCollectionsCategory] = useState('All');
    const [collectionsSort, setCollectionsSort] = useState('ascending');
    const [authenticated, setAuthenticated] = useState(false);
    const [userData, setUserData] = useState(null);
    
    const location = useLocation();

    const onCollectionsUpdate = (category, sort) => {
        setCollectionsCategory(category);
        setCollectionsSort(sort);
    };

    const showDropMenuOnHover = () => {
        const $dropdown = $(".dropdown");
        const $dropdownToggle = $("dropdown-toggle");
        const $dropdownMenu = $(".dropdown-menu");
        const showClass = "show";
        
        if (window.matchMedia("(min-width: 768px)").matches) {
            $dropdown.hover(
                function() {
                    const $this = $(this);
                    $this.addClass(showClass);
                    $this.find($dropdownToggle).attr("aria-expanded", "true");
                    $this.find($dropdownMenu).addClass(showClass);
                },
                function() {
                    const $this = $(this);
                    $this.removeClass(showClass);
                    $this.find($dropdownToggle).attr("aria-expanded", "false");
                    $this.find($dropdownMenu).removeClass(showClass);
                }
            );
        } else {
            $dropdown.off("mouseenter mouseleave");
        }
    };

    useEffect(() => {
        ignoreScrollChange();
    }, [ignoreScrollChange]);

    useEffect(() => {
        const onAuthenticate = (authorized) => {
            if (authorized) {
                dismissModal();
                setUserData(dashboard);
                authenticateUser(true, '/user/dashboard', '/user/logout');
            }
            else
                showLoading();
    
            setAuthenticated(authorized);
        };

        // Authenticate whenever there is a chnage in location
        Authorisation.authenticate(onAuthenticate, history, removeDashboard);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    useEffect(() => {
        setUserData(dashboard);
    }, [dashboard]);

    useEffect(() => {
        const navbarHeight = getNavBarHeight();
        $('div.dashboard-wrapper').css('marginTop', `${navbarHeight}px`);

        $('#sidebar-toggle').click(() => {
            $('#sidebar').toggleClass('open');
        });

        showDropMenuOnHover();
    });

    return !authenticated ? '' : (
        <HashRouter>
            <div className="roboto-font">
                
                <div className="dashboard-wrapper">
                    {/* Sidebar */}
                    <nav id="sidebar">
                        <div className="header text-white container-fluid">
                            <div className="row">
                                <div className="col">
                                    <h6 className="mb-0 name">{userData.first_name + ' ' + userData.last_name}</h6>
                                    <small className="username">@{userData.username}</small>
                                    <div className="plan mt-2">{userData.plan.toUpperCase()}  PLAN</div>
                                </div>
                            </div>
                        </div>

                        <div className="options">
                            <div className="links-container">
                                <h5 className="group-title">Photos</h5>
                                <div className="row link-container">
                                    <div className="col-2"><span className="fas fa-image"></span></div>
                                    <div className="col"><NavLink to={`collections?category=${collectionsCategory}&sort=${collectionsSort}`} className="link">My Collection</NavLink></div>
                                </div>
                            </div>

                            <div className="links-container">
                                <h5 className="group-title">Account</h5>
                                <div className="row link-container">
                                    <div className="col-2"><span className="fas fa-user"></span></div>
                                    <div className="col"><NavLink to="edit-profile" className="link">Edit Profile</NavLink></div>
                                </div>
                                <div className="row link-container">
                                    <div className="col-2"><span className="fas fa-users mr-3"></span></div>
                                    <div className="col"><span to="" className="link">Following</span></div>
                                    <div className="col-3"><span className="badge badge-danger">{userData.following !== 'null' ? userData.following : 0}</span></div>
                                </div>
                            </div>
                            
                            <div className="links-container">
                                <h5 className="group-title">Subscription</h5>
                                <div className="row link-container">
                                    <div className="col-2"><span className="fas fa-spinner mr-3"></span></div>
                                    <div className="col"><NavLink to="plans" className="link">Plans</NavLink></div>
                                </div>
                                <div className="row link-container">
                                    <div className="col-2"><span className="fas fa-arrow-down mr-3"></span></div>
                                    <div className="col"><NavLink to="downgrade" className="link">Downgrade</NavLink></div>
                                </div>
                            </div>
                        </div>
                    </nav>

                    {/* Dashboard Content */}
                    <div id="content">
                        <div className="title-bar">
                                <button className="sidebar-toggle btn btn-dark" id="sidebar-toggle"><span className="fas fa-list"></span></button>
                        </div>

                        <div className="main pb-5">
                            <Switch>
                                <Route path="/downgrade">
                                    <ConnectedDowngrade />
                                </Route>

                                <Route path="/plans">
                                    <ConnectedPlans />
                                </Route>

                                <Route path="/edit-profile">
                                    <ConnectedEditProfile />
                                </Route>

                                <Route path="/collections">
                                    <ConnectedCollections onUpdate={onCollectionsUpdate}/>
                                </Route>

                                <Route path="/">
                                   <Redirect to={`collections?category=${collectionsCategory}&sort=${collectionsSort}`} />
                                </Route>
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        </HashRouter>
    );
};

const Collections = ({ onUpdate, getPhotos, getPhotosCount, getCategories, product_categories, photos, photosCount }) => {
    const queries = new useQuery();
    const productsPerPage = 30;
    const history = useHistory();

    const [categories, setCategories] = useState(product_categories);
    const [category, setCategory] = useState(queries.get('category') || 'All');
    const [sort, setSort] = useState(queries.get('sort') || 'ascending');
    const [searchKeyword, setSearchKeyword] = useState('');
    const [products, setProducts] = useState([]);
    const [productsCount, setProductsCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [currentOffset, setCurrentOffset] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // Fetch the categories if it is not availaible
       if (!categories || categories.length === 0) {
           getCategories();
       }
    }, [categories, getCategories]);

    useEffect(() => {
        setCategories(product_categories);
    }, [product_categories]);

    useEffect(() => {
        const newCategory = queries.get('category') || 'All';
        const newSort = queries.get('sort') || 'ascending';
        setCategory(newCategory);
        setSort(newSort);
        onUpdate(newCategory, newSort);
    }, [queries, onUpdate]);

    useEffect(() => {
        setIsLoading(true);
        getPhotosCount(category, sort, searchKeyword);
    }, [category, sort, searchKeyword, getPhotosCount]);

    useEffect(() => {
        if (photosCount.value === constants.flags.ERROR) {
            setIsLoading(false);
            showError('Oops', 'An error occured, please try again');
        }
        else if (photosCount.value === constants.flags.NO_CONTENT) {
            setIsLoading(false);
            setProducts([]);
        }
        else {
            setProductsCount(photosCount.value);
        }
    }, [photosCount]);

    useEffect(() => {
        if (productsCount > 0) {
            getPhotos(category, sort, searchKeyword, currentOffset, productsPerPage);
        }
    }, [productsCount, isLoading, category, sort, searchKeyword, currentOffset, productsPerPage, getPhotos]);

    useEffect(() => {
        setProducts(photos.data);
        if (photos.data.length > 0)
            setIsLoading(false);
    }, [photos.data]);

    const paginatorClicked = data => {
        const selected = data.selected;
        const offset = Math.ceil(selected * productsPerPage);
        const currentPage = selected;

        setCurrentPage(currentPage);
        setCurrentOffset(offset);

        setIsLoading(true);
        getPhotos(category, sort, searchKeyword, currentOffset, productsPerPage);

        $('html, body').animate({
            scrollTop: 0
        }, 800);
    };

    const withCategory = theCategory => {
        return `?category=${theCategory}&sort=${sort}`;
    };

    const withSort = theSort => {
        return `?category=${category}&sort=${theSort}`;
    };

    const generateCategoryLink = (title) => {
        const uri = withCategory(title);
        return (
            <NavLink to={uri} key={`cat-link-${Math.random() + Math.random()}`} className="dropdown-item" isActive={
                (match, location) => {
                    if (location.search === uri.replace(location.pathname, ''))
                        return true;

                    return false;
                }
            }>{title}</NavLink>
        );
    };

    const generateSortLink = (link, title) => {
        const uri = withSort(link);
        return (
            <NavLink to={uri} className="dropdown-item" isActive={
                (match, location) => {
                    if (location.search === uri.replace(location.pathname, ''))
                        return true;

                    return false;
                }
            }>{title}</NavLink>
        );
    };

    const handleSearchKeywordChanged = e => {
        setSearchKeyword(e.target.value ? e.target.value : '');
    };

    const searchPhotos = e => {
        e.preventDefault();
        
        setIsLoading(true);
        getPhotosCount(category, sort, searchKeyword);
    };

    const onCardClicked = (currentIndex) => {
        if (products.length > 0) {
            utils.showProductsModalAlt(products, currentIndex, downloadProduct);
        }
    };

    const redirectToLogin = () => {
        ReactSwal.close();
        history.push('/user/login');
    };

    const redirectToCreateAccount = () => {
        ReactSwal.close();
        history.push('/user/register');
    };

    const downloadProduct = (product, width, height) => {
        utils.verifyUserSession(authenticateUser, 
            () => {
                utils.verifyProductInCollectionDashboard(product, 
                    (collection_id) => {
                        doDownload(product, collection_id, width, height);
                    },
                    () => {
                        // Product not found
                        showError('Oops!', 'This product is not in your collection!');
                    });
            },
            () => {
                ReactSwal.fire({
                    title: 'You need to login first!',
                    icon: 'info',
                    showConfirmButton: false,
                    html: <utils.AuthComponent onLogin={redirectToLogin} 
                        onCreateAccount={redirectToCreateAccount}/>
                });
            });
    };

    const doDownload = (product, collection_id, width = 0, height = 0) => {
        const img = product.img_url;
        const filename = product.name + img.substring(img.indexOf('.'), img.length);
        utils.download(collection_id, filename, width, height);
    };

    const doDelete = (product) => {
        utils.deleteProductFromCollection(product,
            () => {
                ReactSwal.fire({
                    icon: 'success',
                    title: 'Photo deleted!',
                    timer: 2000,
                    onAfterClose: () => {
                        setIsLoading(true);
                        getPhotosCount(category, sort, searchKeyword);
                    }
                });
            },
            () => showError('Oops!', 'The photo was not deleted, please try again')
        );
    };

    const onDelete = (product) => {
        ReactSwal.fire({
            icon: 'question',
            showConfirmButton: false,
            showCloseButton: true,
            html: (
                <div className="montserrat-font">
                    Do you really want to delete <span className="text-warning">{product.name}</span> from your collection?<br/><br/>
                    This cannot be reversed!

                    <button className="btn btn-block btn-danger mt-5" onClick={e => doDelete(product)}>Delete</button>
                </div>
            )
        });
    };

    return (
        <div>
            <div>
                <div className="jumbotron p-0">
                    <div className="filter-container container">
                        <div className="row">
                            <div className="col">
                                <div className="dropdown w-100 category">
                                        <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                            Category <span className="fas fa-chevron-down float-right"></span>
                                        </a>

                                        <div className="dropdown-menu roboto-font">
                                            {generateCategoryLink('All')}
                                            {!categories ? '' : categories.map((category) => generateCategoryLink(category.category))}
                                        </div>
                                    </div>
                            </div>

                            <div className="col">
                                <div className="dropdown w-100">
                                    <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                        Sort <span className="fas fa-chevron-down float-right"></span>
                                    </a>

                                    <div className="dropdown-menu roboto-font">
                                        {generateSortLink('featured', 'Featured')}
                                        {generateSortLink('price-ascending', 'Price, low to high')}
                                        {generateSortLink('price-descending', 'Price, high to low')}
                                        {generateSortLink('ascending', 'A - Z')}
                                        {generateSortLink('descending', 'Z - A')}
                                        {generateSortLink('date-descending', 'Date, new to old')}
                                        {generateSortLink('date-ascending', 'Date, old to new')}
                                        {generateSortLink('popular', 'Popular')}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <form onSubmit={searchPhotos}>
                            <div className="row search-bar">
                                <div className="col">
                                    <div className="input-group">
                                        <input type="search" className="form-control" placeholder="Search your photos" value={searchKeyword}
                                            onChange={handleSearchKeywordChanged}/>
                                        <button type="submit" className="btn btn-dark"><span className="fas fa-search"></span></button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="my-5 px-1">
                    {isLoading ? <Loading /> : <PhotosGrid onCardClicked={onCardClicked} key="shop-grid" products={products}
                        eventHandlers={{'onDelete': onDelete}}/>}
                    { (isLoading || productsCount === 0 || productsCount <= productsPerPage) ? '' :  
                        generatePagination(Math.ceil(productsCount / productsPerPage), paginatorClicked,
                            2, 2, <span className="fas fa-chevron-left"></span>, <span className="fas fa-chevron-right"></span>,
                            currentPage)
                    }
                </div>
            </div> 
        </div>
    );
};

const EditProfile = ({dashboard, updateDashboard}) => {
    const [data, setData] = useState({
        first_name: dashboard.first_name,
        last_name: dashboard.last_name,
        email: dashboard.email,
        phone: dashboard.phone,
    });

    const [passwordData, setPasswordData] = useState({
        old_password: '',
        new_password: '',
        confirm_password: '',
    });

    const handleInputChanged = e => {
        const name = e.target.name;
        const value = e.target.value;
        data[name] = value;
        setData({...data});
    };

    const handlePasswordInputChanged = e => {
        const name = e.target.name;
        const value = e.target.value;
        passwordData[name] = value;
        setPasswordData({...passwordData});
    };

    const handleEdit = e => {
        e.preventDefault();

        showLoading();
        
        const axios = axiosInstance();

        const formData = new FormData();

        formData.append('first_name', data.first_name);
        formData.append('last_name', data.last_name);
        formData.append('email', data.email);
        formData.append('phone', data.phone);
        formData.append('username', data.username);
        
        axios.post('user/update', formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
        .then((response) => {
            dismissModal();
            if (response.status === 200) {
                // Update this session
                const update = response.data;
                sessionStorage.setItem('user_data', JSON.stringify(update));
                updateDashboard(update);

                Swal.fire({
                    title: 'Profile Updated!',
                    icon: 'success',
                    timer: 2000,
                });
            }
            else if (response.status === 400) { //Validation Error
                Swal.fire({
                    title: 'Validation Error!',
                    html: response.data.messages.error,
                    icon: 'error',
                    confirmButtonText: 'Retry'
                });
            }
        })
        .catch((error) => {
            dismissModal();
            Swal.fire({
                title: 'Connection Error!',
                text: 'A connection error occured, please try again',
                icon: 'error',
                confirmButtonText: 'Retry'
            });
        });
    };

    const handleChangePassword = e => {
        e.preventDefault();
        
        if (passwordData.new_password !== passwordData.confirm_password) {
            showError('Validation Error!', 'Passwords do not match!');
            return;
        }

        showLoading();

        const axios = axiosInstance();

        const formData = new FormData();

        formData.append('old_password', passwordData.old_password);
        formData.append('new_password', passwordData.new_password);
        formData.append('confirm_password', passwordData.confirm_password);
        
        axios.post('user/password', formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
        .then((response) => {
            dismissModal();
            if (response.status === 204) {
                Swal.fire({
                    title: 'Password Updated!',
                    icon: 'success',
                    timer: 2000,
                });
            }
            else if (response.status === 400) { //Validation Error
                Swal.fire({
                    title: 'Validation Error!',
                    html: response.data.messages.error,
                    icon: 'error',
                    confirmButtonText: 'Retry'
                });
            }
        })
        .catch((error) => {
            dismissModal();
            showNetworkError();
        });
    };

    return (
        <div className="container">
            <ScrollToTopOnMount />

            <div className="form-container p-0 mt-5 shadow-lg">
                <h4 className="bg-dark text-white montserrat-font text-center p-3 mb-5">Edit Profile</h4>

                <form onSubmit={handleEdit} className="p-4">
                        <div className="input-group row mb-3">
                            <div className="col-sm mb-3 mb-md-0">
                                <input type="text" placeholder="First name" className="form-control" name="first_name" value={data.first_name} 
                                    onChange={handleInputChanged} maxLength="30" required/>
                            </div>
                            <div className="col-sm">
                                <input type="text" placeholder="Last name" className="form-control" name="last_name" value={data.last_name} 
                                    onChange={handleInputChanged} maxLength="30" required/>
                            </div>
                        </div>

                        <div className="input-group row mb-3">
                            <div className="col-sm mb-3 mb-md-0">
                                <input type="email" placeholder="Email" className="form-control" name="email" value={data.email} 
                                    onChange={handleInputChanged} maxLength="50" required/>
                            </div>
                            <div className="col-sm">
                                <input type="number" placeholder="Phone" className="form-control" name="phone" value={data.phone} 
                                    onChange={handleInputChanged} maxLength="15" required/>
                            </div>
                        </div>

                        <div className="mt-5 text-left">
                            <button type="submit" className="btn btn-lg btn-dark">Update <span className="fas fa-arrow-right ml-5"></span></button>
                        </div>
                    </form>
            </div>
        
            <div className="shadow-lg mt-5">
                <h4 className="bg-dark text-white montserrat-font text-center p-3 mb-5">Change Password</h4>

                <form className="m-4 pb-5" onSubmit={handleChangePassword}>
                    <div className="input-group">
                        <input type="password" className="form-control" placeholder="Old password" name="old_password" onChange={handlePasswordInputChanged} required/>
                    </div>
                    <div className="input-group mt-3">
                        <input type="password" className="form-control" placeholder="New password" name="new_password" onChange={handlePasswordInputChanged} required/>
                    </div>
                    <div className="input-group mt-3">
                        <input type="password" className="form-control" placeholder="Confirm password" name="confirm_password" onChange={handlePasswordInputChanged} required/>
                    </div>

                    <div className="mt-5 text-left">
                        <button type="submit" className="btn btn-lg btn-dark">Replace Password <span className="fas fa-arrow-right ml-5"></span></button>
                    </div>
                </form>
            </div>
        </div>
    );
};

const Plans = ({updateDashboard, user_plans, getUserPlans}) => {
    let userData = JSON.parse(sessionStorage.getItem('user_data'));

    const upgradeUser = (axios, plan, reference) => {
        axios.post('user/sub/up/' + plan, {}, {
            params: {
                reference: reference,
            }
        })
        .then((response) => {
            dismissModal();
            if (response.status === 200) {
                // Update this session
                const update = response.data;
                sessionStorage.setItem('user_data', JSON.stringify(update));
                updateDashboard(update);
                sessionStorage.removeItem('user_up_sub_ref');

                Swal.fire({
                    title: 'Subscription Upgraded!',
                    icon: 'success',
                    timer: 2000,
                });
            }
            else { //Unknown Error
                showError('Error', 'An unknown error occured');
            }
        })
        .catch((error) => {
            dismissModal();
            showNetworkError();
        });
    };

    const handleUpgrade = (plan) => {
        userData = JSON.parse(sessionStorage.getItem('user_data'));

        if (userData.plan === plan) {
            showError('Oops!', 'You are already using the ' + plan + ' plan');
            return;
        }

        showLoading();

        const axios = axiosInstance();

        // Subscribe the user first

        // if there is an existing reference code, let's verify it
        if (sessionStorage.getItem('user_up_sub_ref')) {
            const ref = sessionStorage.getItem('user_up_sub_ref');

            // verify it
            axios.post('transaction/verify', {reference: ref})
            .then((res) => {
                // If we get back a positive reponse (code 200), then proceed and upgrade the user
                if (res.status === 200) {
                    upgradeUser(axios, plan, ref);
                }
                else {
                    // The reference is invalid, remove it and init subscription process
                    sessionStorage.removeItem('user_up_sub_ref');
                    subscribeThenUpgrade(axios, plan);
                }
            })
            .catch((error) => {
                showNetworkError();
            });
        }
        else {
            subscribeThenUpgrade(axios, plan);
        }
    };

    const subscribeThenUpgrade = (axios, plan) => {
        axios.post('user/sub/init', {'plan': plan, 'email': userData.email})
        .then((reponse) => {
            // eslint-disable-next-line no-undef
            let handler = PaystackPop.setup({
                ...reponse.data,
                onClose: function() {
                    // No transaction recorded because the user closed the popup
                    showSubscriptionError();
                },
                callback: (response) => {
                    // We have a response from paystack, let's verify the transaction before updating the photographer
                    // Save the reference first, in case something goes wrong, we can reuse to avoid repeated payment!
                    sessionStorage.setItem('user_up_sub_ref', response.reference);
                    axios.post('transaction/verify', {reference: response.reference})
                    .then((res) => {
                        // If we get back a positive reponse (code 200), then proceed and register the photographer
                        if (res.status === 200) {
                            upgradeUser(axios, plan, response.reference);
                        }
                        else {
                            showSubscriptionError();
                        }
                    })
                    .catch((error) => {
                        showSubscriptionError();
                    })
                }
            });

            handler.openIframe();
        })
        .catch((error) => {
            showNetworkError();
        });
    };

    const handleSilverUpgrade = e => {
        e.preventDefault();
        handleUpgrade('silver');
    };

    const handleGoldUpgrade = e => {
        e.preventDefault();
        handleUpgrade('gold');
    };

    useEffect(() => {
        if (user_plans.length === 0)
            getUserPlans();
    });

    let basicDeal, silverDeal, goldDeal, silverPrice, goldPrice;
    if (user_plans.length === 3) {
        const plans = user_plans;
        basicDeal = (1 - parseFloat(plans[0].rate)) * 100;
        silverDeal = (1 - parseFloat(plans[1].rate)) * 100;
        goldDeal = (1 - parseFloat(plans[2].rate)) * 100;

        silverPrice = plans[1].price;
        goldPrice = plans[2].price;
    }

    return (
        <div className="container mt-5">
            <ScrollToTopOnMount />
    
            <div className="plan-container jumbotron container-fluid text-center mt-5 mt-md-n1">
                {user_plans.length === 0 ? <Loading /> :
                    <div>
                        <h1 className="text-center montserrat-font title">Choose Subscription Plan</h1>
                        <h4 className="text-center roboto-font sub-title">Get access to high quality stock photos!</h4>

                        <div className="card-deck text-white">
                            <div className="card">
                                <h3 className="plan p-3 text-center mb-0 montserrat-font"><span>Basic</span> Plan</h3>
                                <h4 className="text-center p-3 discount mb-0 roboto-font">Now FREE!</h4>
                                
                                <div className="card-body text-dark">
                                    <h4 className="text-center roboto-font deal">You get <span className="montserrat-font">{basicDeal}%</span> discount!
                                    </h4>
                                </div>

                                <div className="card-footer">
                                    <Link to="downgrade" className="sub-link stretched-link">Downgrade</Link>
                                </div>
                            </div>

                            <div className="card">
                                <h3 className="plan p-3 text-center mb-0 montserrat-font"><span>Silver</span> Plan</h3>
                                <h4 className="text-center p-3 discount mb-0 roboto-font">Starting At<strong className="ml-2">&#8358;{silverPrice}/mo*</strong></h4>

                                <div className="card-body text-dark">
                                    <h4 className="text-center roboto-font deal">You get <span className="montserrat-font">{silverDeal}%</span> discount!</h4>
                                </div>

                                <div className="card-footer">
                                    <a href="upgrade?plan=silver" className="sub-link stretched-link" onClick={handleSilverUpgrade}>Upgrade</a>
                                </div>
                            </div>

                            <div className="card">
                                <h3 className="plan p-3 text-center mb-0 montserrat-font"><span>Gold</span> Plan</h3>
                                <h4 className="text-center p-3 discount mb-0 roboto-font">Starting At<strong className="ml-2">&#8358;{goldPrice}/mo*</strong></h4>

                                <div className="card-body text-dark">
                                    <h4 className="text-center roboto-font deal">You get <span className="montserrat-font">{goldDeal}%</span> discount!</h4>
                                </div>

                                <div className="card-footer">
                                    <a href="upgrade?plan=gold" className="sub-link stretched-link" onClick={handleGoldUpgrade}>Upgrade</a>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

const Downgrade = ({updateDashboard}) => {
    const handleDowngrade = e => {
        const userData = JSON.parse(sessionStorage.getItem('user_data'));
        if (userData.plan === 'basic') {
            showError('Oops!', 'You are already using the basic plan!');
            return;
        }

        showLoading();

        const axios = axiosInstance();

        axios.patch('user/sub/down')
        .then((response) => {
            dismissModal();
            if (response.status === 200) {
                // Update this session
                const update = response.data;
                sessionStorage.setItem('user_data', JSON.stringify(update));
                updateDashboard(update);

                Swal.fire({
                    title: 'Subscription Downgraded!',
                    icon: 'success',
                    timer: 2000,
                });
            }
            else { //Unknown Error
                showError('Error', 'An unknown error occured');
            }
        })
        .catch((error) => {
            dismissModal();
            showNetworkError();
        });
    };

    return (
        <div className="container mt-5">
            <ScrollToTopOnMount />

            <div className="shadow-lg">
                <h4 className="bg-dark text-white montserrat-font text-center p-3 mb-0">Downgrade Plan</h4>

                <div className="jumbotron bg-light pb-3 text-center">
                    <h5>Do you really want to downgrade your plan? This will unsubscribe you from any paid subscription immediately!</h5>
                    <button className="btn btn-dark btn-lg mt-5" onClick={handleDowngrade}>Yes, Downgrade</button>
                </div>
            </div>
        </div>
    );
};

export const PasswordReset = ({ignoreScrollChange}) => {
    const history = useHistory();
    const queries = useQuery();
    const verification_code = queries.get('code') || null;
    
    const [email, setEmail] = useState('');
    const [passwordData, setPasswordData] = useState({
        new_password: '',
        confirm_password: '',
    });

    useEffect(() => {
        ignoreScrollChange();
        animateScroll.scrollToTop({
            duration: 700,
            delay: 0,
            smooth: true,
        });
    }, [ignoreScrollChange]);

    const handlePasswordInputChanged = e => {
        const name = e.target.name;
        const value = e.target.value;
        passwordData[name] = value;
        setPasswordData({...passwordData});
    };
    
    const resendCode = e => {
        dismissModal();
        
        if (email === '') {
            showError('Please enter a valid registered email');
            return;
        }

        showLoading();
        
        utils.axios.post('user/password_reset/resend', {
            email: email,
        })
        .then((response) => {
            if (response.status === 204) {
                utils.showSuccess('Verification code resent!');
            }
            else if (response.status === 400) { //Validation Error
                showError('Validation Error', 'There is no User password reset request associated with this email!');
            }
            else {
                showNetworkError();
            }
        })
        .catch((error) => {
            showNetworkError();
        });
    };

    const handleReset = e => {
        e.preventDefault();

        showLoading();

        utils.axios.post('user/password_reset/init', {
            email: email,
        })
        .then((response) => {
            if (response.status === 204) {
                utils.showSuccess('Verification code sent!');
            }
            else if (response.status === 409) {
                ReactSwal.fire({
                    title: 'Conflict Error!',
                    html: (
                        <div className="roboto-font">
                            A verification code has already been sent to your email. We can still resend the code if you have not seen it yet.

                            <button className="btn btn-info btn-block mt-4" onClick={resendCode}>Resend Code</button>
                        </div>
                    ),
                    icon: 'error',
                    showCloseButton: true,
                    showConfirmButton: false,
                });
            }
            else if (response.status === 400) { //Validation Error
                showError('Validation Error', response.data.messages.error);
            }
            else {
                showNetworkError();
            }
        })
        .catch((error) => {
            showNetworkError();
        });
    };

    const handleUpdatePassword = e => {
        e.preventDefault();

        if (passwordData.new_password !== passwordData.confirm_password) {
            showError('Validation Error!', 'Passwords do not match!');
            return;
        }

        showLoading();
        
        utils.axios.post('user/password_reset/finalize', {
            email: email,
            new_password: passwordData.new_password,
            confirm_password: passwordData.confirm_password,
            verification_code: verification_code,
        })
        .then((response) => {
            if (response.status === 204) {
                ReactSwal.fire({
                    title: 'Password updated successfully!',
                    icon: 'success',
                    timer: 3000,
                    onAfterClose: () => history.push('login')
                });
            }
            else if (response.status === 400) { //Validation Error
                showError('Validation Error', response.data.messages.error);
            }
            else {
                showNetworkError();
            }
        })
        .catch((error) => {
            showNetworkError();
        });
    };

    if (verification_code === null) {
        return (
            <div className="login roboto-font photographer-container">
                <ScrollToTopOnMount />
    
                <div className="mb-5">
                    <Link to="/user/login" className="btn btn-lg btn-info"><span className="fas fa-arrow-left mr-4"></span>Login</Link>
                </div>
    
                <div className="container text-center shadow-lg px-0">
                    <h3 className="header montserrat-font bg-dark text-white p-4 mb-0">User Password Reset</h3>
                    
                    <div className="form-container jumbotron bg-light">
                        <form onSubmit={handleReset}>
                            <div className="col-sm mb-3 mb-md-0">
                                <input type="email" placeholder="Enter registered email" className="form-control bg-light" value={email} 
                                    onChange={e => setEmail(e.target.value)} maxLength="50" required/>
                            </div>
    
                            <div className="mt-5 row">
                                <div className="col-sm mb-2">
                                    <button type="submit" className="btn btn-lg btn-dark btn-block">Reset Password<span className="fas fa-arrow-right ml-2 float-right mt-1"></span></button>
                                </div>
                                <div className="col-sm">
                                <button type="button" className="btn btn-lg btn-dark btn-block" onClick={resendCode}>Resend Code <span className="fas fa-spinner ml-2 float-right mt-1"></span></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
    else {
        return (
            <div className="login roboto-font photographer-container">
                <ScrollToTopOnMount />
    
                <div className="mb-5">
                    <Link to="/user/login" className="btn btn-lg btn-info"><span className="fas fa-arrow-left mr-4"></span>Login</Link>
                </div>
    
                <div className="container text-center shadow-lg px-0">
                    <h3 className="header montserrat-font bg-dark text-white p-4 mb-0">User Password Reset</h3>
                    
                    <div className="form-container jumbotron bg-light">
                        <form onSubmit={handleUpdatePassword}>
                            <div className="input-group">
                                <input type="email" placeholder="Enter registered email" className="form-control bg-light" value={email} 
                                    onChange={e => setEmail(e.target.value)} maxLength="50" required/>
                            </div>
                            <div className="input-group mt-3">
                                <input type="password" className="form-control" placeholder="New password" name="new_password" onChange={handlePasswordInputChanged} required/>
                            </div>
                            <div className="input-group mt-3">
                                <input type="password" className="form-control" placeholder="Confirm password" name="confirm_password" onChange={handlePasswordInputChanged} required/>
                            </div>

                            <div className="mt-5 row">
                                <div className="col-sm mb-2">
                                    <button type="submit" className="btn btn-lg btn-dark btn-block">Update Password<span className="fas fa-arrow-right ml-2 float-right mt-1"></span></button>
                                </div>
                                <div className="col-sm">
                                <button type="button" className="btn btn-lg btn-dark btn-block" onClick={resendCode}>Resend Code <span className="fas fa-spinner ml-2 float-right mt-1"></span></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
};

export const Activate = ({ignoreScrollChange}) => {
    const history = useHistory();
    const queries = useQuery();
    const verification_code = queries.get('code') || null;
    
    const [email, setEmail] = useState('');
    const [newEmail, setNewEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [recaptchaDone, setRecaptchaDone] = useState(false);

    useEffect(() => {
        ignoreScrollChange();
        animateScroll.scrollToTop({
            duration: 700,
            delay: 0,
            smooth: true,
        });
    }, [ignoreScrollChange]);
    
    useEffect(() => {
        if (verification_code) {
            // Finalize account activation

            showLoading();

            utils.axios.post('user/activate/finalize', {
                verification_code: verification_code,
            })
            .then((response) => {
                if (response.status === 204) {
                    ReactSwal.fire({
                        title: 'Account Activated!',
                        text: 'Your account was activated successfully. You can login now!',
                        icon: 'success',
                        confirmButtonText: 'Log In',
                        onAfterClose: () => history.push('/user/login'),
                    });
                }
                else if (response.status === 400) { //Validation Error
                    showError('Validation Error', 'Invalid activation code!');
                }
                else {
                    showNetworkError();
                }
            })
            .catch((error) => {
                showNetworkError();
            });
        }
    }, [verification_code, history]);
    
    const resendCode = e => {
        e.preventDefault();

        dismissModal();
        
        if (email === '') {
            showError('Please enter a valid registered email');
            return;
        }

        showLoading();
        
        utils.axios.post('user/activate/resend', {
            email: email,
        })
        .then((response) => {
            if (response.status === 204) {
                utils.showSuccess('Activation message resent!');
            }
            else if (response.status === 400) { //Validation Error
                showError('Validation Error', 'There is no User account activation request associated with this email!');
            }
            else {
                showNetworkError();
            }
        })
        .catch((error) => {
            showNetworkError();
        });
    };

    const handleChangeEmail = e => {
        e.preventDefault();

        if (!recaptchaDone) {
            showError('', 'Please complete the reCaptcha challenge!');
            return;
        }

        showLoading();

        const axios = axiosInstance();
        axios.post('user/activate/modify', {
            email: newEmail,
        }, {
            auth: {
                username: username,
                password: password,
            }
        })
        .then((response) => {
            if (response.status === 204) {
                utils.showSuccess('Activation message sent to new email!');
            }
            else if (response.status === 400) { //Validation Error
                ReactSwal.fire({
                    title: 'Validation Error!',
                    html: response.data.messages.error,
                    icon: 'error',
                    confirmButtonText: 'Retry'
                });
            }
            else {
                showError('Authentication Error!', 'Wrong username or password');
            }
        })
        .catch((error) => {
            dismissModal();
            showNetworkError();
        });
    };

    const onRecaptcha = status => {
        if (status) {
            setRecaptchaDone(true);
        }
        else {
            setRecaptchaDone(false);
        }
    };

    if (verification_code === null) {
        return (
            <div className="login roboto-font photographer-container">
                <ScrollToTopOnMount />
    
                <div className="mb-5">
                    <Link to="/user/login" className="btn btn-lg btn-info"><span className="fas fa-arrow-left mr-4"></span>Login</Link>
                </div>
    
                <div className="container text-center shadow-lg px-0">
                    <h3 className="header montserrat-font bg-dark text-white p-4 mb-0">Resend Activation Message</h3>
                    
                    <div className="form-container jumbotron bg-light">
                        <form onSubmit={resendCode}>
                            <div className="col-sm mb-3 mb-md-0">
                                <input type="email" placeholder="Enter registered email" className="form-control bg-light" value={email} 
                                    onChange={e => setEmail(e.target.value)} maxLength="50" required/>
                            </div>
    
                            <div className="mt-5 text-left">
                                <button type="submit" className="btn btn-lg btn-dark">Resend Message<span className="fas fa-arrow-right ml-2 float-right mt-1"></span></button>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="container text-center shadow-lg px-0">
                <h3 className="header montserrat-font bg-dark text-white p-4 mb-0">Change Email</h3>
                
                <div className="form-container jumbotron bg-light">
                    <form onSubmit={handleChangeEmail}>
                        <div className="input-group row mb-3 text-left">
                            <div className="col-sm mb-3 mb-md-0">
                                <input type="email" placeholder="Enter new email" className="form-control bg-light" value={newEmail} 
                                    onChange={e => setNewEmail(e.target.value)} maxLength="50" required/>
                            </div>
                        </div>

                        <div className="input-group row mb-3">
                            <div className="col-sm mb-3 mb-md-0">
                                <input type="text" placeholder="Username" className="form-control" value={username} 
                                    onChange={e => setUsername(e.target.value)} maxLength="12" required/>
                            </div>

                            <div className="col-sm">
                                <input type="password" placeholder="Password" className="form-control" 
                                    onChange={e => setPassword(e.target.value)} required/>
                            </div>
                        </div>

                        <div className="input-group row mb-3 text-left">
                            <div className="col">
                                <ReCAPTCHA 
                                sitekey={utils.recaptchaKey}
                                onChange={onRecaptcha}/>
                            </div>
                        </div>

                        <div className="mt-5 text-left">
                            {recaptchaDone ? <button type="submit" className="btn btn-lg btn-dark">Update Email <span className="fas fa-arrow-right ml-5"></span></button> :
                            <button type="submit" className="btn btn-lg btn-dark disabled" disabled>Update Email <span className="fas fa-arrow-right ml-5"></span></button>}
                        </div>
                    </form>
                </div>
            </div>
            </div>
        );
    }
    else {
        return null;
    }
};

export const UnfollowPhotographer = ({authenticateUser}) => {
    const {photographer} = useParams();
    const history = useHistory();

    useEffect(() => {
        const redirectToLogin = () => {
            ReactSwal.close();
            history.push('/user/login?redirect=/user/unfollow@' + photographer);
        };
    
        const redirectToCreateAccount = () => {
            ReactSwal.close();
            history.push('/user/register');
        };

        utils.verifyUserSession(authenticateUser, 
            () => {
                showLoading();

                utils.axios.delete('user/unfollow/' + photographer)
                .then((response) => {
                    if (response.status === 204) {
                        // Unfollowed successfully
                        utils.showSuccess('', 'You are no longer following ' + photographer)
                    }
                    else if (response.status === 409) {
                        // Not following
                        showError('', 'You are not currently following ' + photographer);
                    }
                    else {
                        showNetworkError();
                    }
                })
                .catch((error) => {
                    showNetworkError();
                });
            },
            () => {
                ReactSwal.fire({
                    title: 'You need to login first!',
                    icon: 'info',
                    showConfirmButton: false,
                    html: <utils.AuthComponent onLogin={redirectToLogin} 
                        onCreateAccount={redirectToCreateAccount}/>
                });
            });
    }, [photographer, authenticateUser, history]);

    return null;
};

class PhotosGrid extends Component {
    constructor(props) {
        super(props);

        this.state = {transition: false};
    }

    componentDidMount() {
        this.setState({transition: true})
    }

    render() {
        const products = this.props.products;
        if (!products || products.length === 0)
            return <h2 className="m-4 roboto-font text-center">Your collection is empty!</h2>;

        const perRow = 3;

        let remainder = products.length % perRow;

        let makeCards;

        if (products.length < perRow)
        {
            makeCards = () => {
                let cols = [];
                
                for (let i = 0; i < products.length; i++) {
                    const product = products[i];
                    cols.push(<ImageGridCol key={`prod-col-${i}`}><PhotoCard index={i} 
                        onCardClicked={this.props.onCardClicked} product={product} 
                            {...this.props.eventHandlers}/></ImageGridCol>)
                }

                return cols;
            };
        }
        else if (remainder === 0)
        {
            makeCards = () => {
                let cols = [[], [], []];
                
                for (let i = 0; i < products.length; i += perRow) {
                    
                    for (let j = 0; j < perRow; j++) {
                        const product = products[i+j];
                        cols[j].push(<PhotoCard index={i+j} 
                            onCardClicked={this.props.onCardClicked} product={product} {...this.props.eventHandlers}
                            key={`prod-${i+j}`}/>);
                    }
                }

                let rows = [];
                for (let i = 0; i < perRow; i++)
                    rows.push(<ImageGridCol key={`prod-col-${i}`}>{cols[i]}</ImageGridCol>);

                return rows;
            };
        }
        else
        {
            makeCards = () => {
                let cols = [[], [], []];
                const evenSize = products.length - remainder;
                
                for (let i = 0; i < evenSize; i += perRow) {
                    for (let j = 0; j < perRow; j++) {
                        const product = products[i+j];
                        cols[j].push(<PhotoCard index={i+j} 
                            onCardClicked={this.props.onCardClicked} product={product} {...this.props.eventHandlers}
                            key={`prod-${i+j}`}/>);
                    }
                }

                let rows = [];
                for (let i = 0; i < perRow; i++)
                    rows.push(<ImageGridCol key={`prod-col-${i}`}>{cols[i]}</ImageGridCol>);

                for (let i = evenSize; i < products.length; i++) {
                    const product = products[i];
                    rows.push(<ImageGridCol key={`prod-col-${i}`}><PhotoCard index={i} 
                        onCardClicked={this.props.onCardClicked} product={product} 
                        {...this.props.eventHandlers} /></ImageGridCol>)
                }

                return rows;
            };
        }

        return (
            <CSSTransition
                in={this.state.transition}
                timeout={1100}
                classNames="products-grid-transition">
                <ImageGridRow>{makeCards()}</ImageGridRow>
            </CSSTransition>
        )
    }
}

const PhotoCard = (props) => {
    const handleClick = (e) => {
        props.onCardClicked(props.index);
    };

    const onDelete = (e) => {
        e.preventDefault();
        if (props.onDelete)
            props.onDelete(props.product);
    };

    return (
        <div className="card product-card p-0">
            <img src={props.product.img_url} className="card-img-top image" alt="Product" />

            <div className="card-img-overlay text-center">
                <div className="w-100 h-100" onClick={handleClick}></div>
                <div className="options">
                    <span className="actions float-right text-white">
                        <a onClick={onDelete}><span className="fas fa-trash ml-3 align-bottom"></span></a>
                    </span>
                </div>
            </div>
        </div>
    );
}

/* Map States and Dispatchs To Props */
const getUserPlans = creators.misc.getUserPlans;
const getPhotosCount = creators.user.getPhotosCount;
const getPhotos = creators.user.getPhotos;
const getCategories = creators.misc.getCategories;
const authenticateUser = creators.app.authenticate;
const updateDashboard = creators.user.updateDashboard;
const removeDashboard = creators.user.removeDashboard;

// Dashboard
const mapStateToDashboardProps = (state) => {
    return {
        dashboard: {...state.userReducer.dashboard},
    };
};

const mapDispatchToDashboardProps = {
    authenticateUser, removeDashboard
};

// Collections
const mapStateToCollectionsProps = (state) => {
    return {
        product_categories: [...state.miscReducer.categories],
        photos: {...state.userReducer.photos},
        photosCount: {...state.userReducer.photosCount},
        dashboard: {...state.userReducer.dashboard},
    };
};

const mapDispatchToCollectionsProps = {
    getPhotosCount, getPhotos, getCategories,
};


// Login
const mapDispatchToLoginProps = {
    authenticateUser, updateDashboard, removeDashboard
};

// EditProfile
const mapStateToEditProfileProps = (state) => {
    return {
        dashboard: {...state.userReducer.dashboard},
    };
};

const mapDispatchToEditProfileProps = {
    updateDashboard,
};

const mapStateToProps = (state) => {
    return {
        user_plans: [...state.miscReducer.user_plans],
    };
};

const ConnectedPlans = connect(mapStateToProps, {...mapDispatchToLoginProps, getUserPlans})(Plans);
export const ConnectedPlan = connect(mapStateToProps, {getUserPlans})(Plan);
const ConnectedDowngrade = connect(null, mapDispatchToLoginProps)(Downgrade);
const ConnectedCollections = connect(mapStateToCollectionsProps, mapDispatchToCollectionsProps)(Collections);
const ConnectedEditProfile = connect(mapStateToEditProfileProps, mapDispatchToEditProfileProps)(EditProfile);
export const ConnectedLogin = connect(null, mapDispatchToLoginProps)(Login);
export const ConnectedUnfollowPhotographer = connect(null, mapDispatchToLoginProps)(UnfollowPhotographer);
export const ConnectedDashboard = connect(mapStateToDashboardProps, mapDispatchToDashboardProps)(Dashboard);
export const ConnectedLogout = connect(null, mapDispatchToLoginProps)(Logout);