import * as constants from '../actions/constants';

const initialState = {
    auth: {
        authenticated: false,
        dashboard: '',
        logout: '',
    },

    cart: [],
};

// Check for any session data
if (sessionStorage.getItem('auth')) {
    initialState.auth = JSON.parse(sessionStorage.getItem('auth'));
}

if (sessionStorage.getItem('cart')) {
    initialState.cart = JSON.parse(sessionStorage.getItem('cart'));
}
else {
    sessionStorage.setItem('cart', JSON.stringify(initialState.cart));
}

export const appReducer = (state = initialState, action) => {
    if (action.type === constants.app.AUTHENTICATE) {
        const newState = {...state};
        newState.auth = {...action.payload};

        // store the data in session (this caches the data in case the user refreshes the window)
        sessionStorage.setItem('auth', JSON.stringify(newState.auth));
        return newState;
    }

    if (action.type === constants.app.ADD_TO_CART) {
        const newState = {...state};
        newState.cart.push(action.payload);
        sessionStorage.setItem('cart', JSON.stringify(newState.cart));
        return newState;
    }

    if (action.type === constants.app.REMOVE_FROM_CART) {
        const newState = {...state};
        const index = getIndexIncart(action.payload, newState.cart)
        if (index !== -1) {
            newState.cart.splice(index, 1);
        }

        sessionStorage.setItem('cart', JSON.stringify(newState.cart));

        return newState;
    }

    if (action.type === constants.app.CLEAR_CART) {
        const newState = {...state};
        newState.cart = [];
        sessionStorage.setItem('cart', JSON.stringify(newState.cart));
        return newState;
    }

    return state;
};

const getIndexIncart = (product, cart) => {
    for (let i = 0; i < cart.length; i++) {
        const p = cart[i];
        if (p.name === product.name)
            return i;
    }

    return -1;
}