import { combineReducers } from 'redux';
import { appReducer } from './app';
import { indexReducer } from './index';
import { photographerReducer } from './photographer';
import { userReducer } from './user';
import { miscReducer } from './misc';
import { shopReducer } from './shop';
import { adminReducer } from './admin';

export const rootReducer = combineReducers(
    {appReducer, indexReducer, shopReducer, photographerReducer, userReducer, adminReducer, miscReducer}
);