import React, { Component } from 'react'

export class Col extends Component {
    render() {
        let size = '';
        if (this.props.size)
            size = this.props.size;

        let classes = 'col ';
        if (size !== '')
            classes = `col-${size} `;

        if (this.props.classes)
            classes += this.props.classes;

        return (
            <div className={classes}>
                {this.props.children}
            </div>
        );
    }
}

export class ColSM extends Component {
    render() {
        let size = '';
        if (this.props.size)
            size = this.props.size;

        let classes = 'col-sm ';
        if (size !== '')
            classes = `col-sm-${size} `;

        if (this.props.classes)
            classes += this.props.classes;

        return (
            <div className={classes}>
                {this.props.children}
            </div>
        );
    }
}

export class ColMD extends Component {
    render() {
        let size = '';
        if (this.props.size)
            size = this.props.size;

        let classes = 'col-md ';
        if (size !== '')
            classes = `col-md-${size} `;

        if (this.props.classes)
            classes += this.props.classes;

        return (
            <div className={classes}>
                {this.props.children}
            </div>
        );
    }
}

export class ColLG extends Component {
    render() {
        let size = '';
        if (this.props.size)
            size = this.props.size;

        let classes = 'col-lg ';
        if (size !== '')
            classes = `col-lg-${size} `;

        if (this.props.classes)
            classes += this.props.classes;

        return (
            <div className={classes}>
                {this.props.children}
            </div>
        );
    }
}

export class GridRow extends Component {
    render() {
        let classes = 'row ';
        if (this.props.classes)
            classes += this.props.classes;

        return (
            <div className={classes}>
                {this.props.children}
            </div>
        );
    }
}