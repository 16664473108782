/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { productViewed } from './utils';

export const TopProductCard = (props) => {
    const handleClick = (e) => {
        props.onCardClicked(props.index);

        // Let the backend know that this product was viewed
        productViewed(props.product);
    };

    const onAddTocart = (e) => {
        e.preventDefault();
        if (props.addToCart)
            props.addToCart(props.product);
    };

    const onDownload = (e) => {
        e.preventDefault();
        if (props.download)
            props.download(props.product);
    };

    const onViewPhotographer = (e) => {
        e.preventDefault();
        if (props.viewPhotographer)
            props.viewPhotographer(props.product.photographer_username);
    };

    return (
        <div className="card product-card p-0">
            <img src={props.product.img_url} className="card-img-top image" alt="Product" />

            <div className="card-img-overlay text-center">
                <div className="w-100 h-100" onClick={handleClick}></div>
                <div className="options">
                    <a href="/" className="float-left photographer-link" onClick={onViewPhotographer}>
                        <img src={props.product.photographer_avatar} alt="Photographer" className="float-left" style={{maxHeight: '50px'}}/>
                    </a>
                    <span className="actions float-right text-white">
                        <a onClick={onDownload}><span className="fas fa-download align-bottom"></span></a>
                        <a onClick={onAddTocart}><span className="fas fa-shopping-basket ml-3 align-bottom"></span></a>
                    </span>
                </div>
            </div>
        </div>
    );
}
