import * as actions from '../actions/constants';

const initialState = {
    categories: [],
    user_plans: [],
    photographer_plans: [],
};

export const miscReducer = (state = initialState, action) => {
    if (action.type === actions.misc.GET_CATEGORIES_SUCCESS) {
        const newState = {...state};
        newState.categories = action.payload;
        return newState;
    }

    if (action.type === actions.misc.GET_CATEGORIES_FAILED) {
        const newState = {...state};
        newState.categories = [];
        return newState;
    }

    if (action.type === actions.misc.GET_USER_PLANS_SUCCESS) {
        const newState = {...state};
        newState.user_plans = action.payload;
        return newState;
    }

    if (action.type === actions.misc.GET_PHOTOGRAPHER_PLANS_SUCCESS) {
        const newState = {...state};
        newState.photographer_plans = action.payload;
        return newState;
    }

    return state;
};