/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, Component } from 'react'
import {NavLink, useHistory } from 'react-router-dom';
import { useQuery, generatePagination, showError,
    Loading,
    ImageGridRow,
    ImageGridCol,
    ReactSwal,
    ScrollToTopOnMount} from './utils';
import $ from 'jquery';
import * as creators from '../redux/actions/creators';
import { connect } from 'react-redux';
import * as constants from '../redux/actions/constants';
import { productViewed } from './utils';
import { CSSTransition } from 'react-transition-group';
import * as utils from './utils';

const Shop = ({ getNavBarHeight, ignoreScrollChange, getPhotos, getPhotosCount, getCategories, product_categories, photos, photosCount,
    cart, addToCart, removeFromCart, authenticateUser }) => {
    const queries = new useQuery();
    const productsPerPage = 30;
    
    const [categories, setCategories] = useState(product_categories);
    const [category, setCategory] = useState(queries.get('category') || 'All');
    const [sort, setSort] = useState(queries.get('sort') || 'featured');
    const [searchKeyword, setSearchKeyword] = useState(queries.get('keyword') || '');
    const [products, setProducts] = useState([]);
    const [productsCount, setProductsCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [currentOffset, setCurrentOffset] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const history = useHistory();

    useEffect(() => {
        ignoreScrollChange();

        // Fetch the categories if it is not availaible
       if (!categories || categories.length === 0) {
           getCategories();
       }
    }, [categories, getCategories, ignoreScrollChange]);

    useEffect(() => {
        setCategories(product_categories);
    }, [product_categories]);

    useEffect(() => {
        const newCategory = queries.get('category') || 'All';
        const newSort = queries.get('sort') || 'featured';
        setCategory(newCategory);
        setSort(newSort);
        
    }, [queries]);

    useEffect(() => {
        setIsLoading(true);
        getPhotosCount(category, sort, searchKeyword);
    }, [category, sort, searchKeyword, getPhotosCount]);

    useEffect(() => {
        if (photosCount.value === constants.flags.ERROR) {
            setIsLoading(false);
            showError('Oops', 'An error occured, please try again');
        }
        else if (photosCount.value === constants.flags.NO_CONTENT) {
            setIsLoading(false);
            setProducts([]);
        }
        else {
            setProductsCount(photosCount.value);
        }
    }, [photosCount]);

    useEffect(() => {
        if (productsCount > 0) {
            getPhotos(category, sort, searchKeyword, currentOffset, productsPerPage);
        }
    }, [productsCount, isLoading, category, sort, searchKeyword, currentOffset, productsPerPage, getPhotos]);

    useEffect(() => {
        setProducts(photos.data);
        if (photos.data.length > 0)
            setIsLoading(false);
    }, [photos.data]);

    useEffect(() => {
        const navbarHeight = getNavBarHeight();
        $('.shop-container').css('marginTop', `${navbarHeight}px`);

        showDropMenuOnHover();
    });

    const showDropMenuOnHover = () => {
        const $dropdown = $(".dropdown");
        const $dropdownToggle = $("dropdown-toggle");
        const $dropdownMenu = $(".dropdown-menu");
        const showClass = "show";
        
        if (window.matchMedia("(min-width: 768px)").matches) {
            $dropdown.hover(
                function() {
                    const $this = $(this);
                    $this.addClass(showClass);
                    $this.find($dropdownToggle).attr("aria-expanded", "true");
                    $this.find($dropdownMenu).addClass(showClass);
                },
                function() {
                    const $this = $(this);
                    $this.removeClass(showClass);
                    $this.find($dropdownToggle).attr("aria-expanded", "false");
                    $this.find($dropdownMenu).removeClass(showClass);
                }
            );
        } else {
            $dropdown.off("mouseenter mouseleave");
        }
    };

    const paginatorClicked = data => {
        const selected = data.selected;
        const offset = Math.ceil(selected * productsPerPage);
        const currentPage = selected;

        setCurrentPage(currentPage);
        setCurrentOffset(offset);

        setIsLoading(true);
        getPhotos(category, sort, searchKeyword, currentOffset, productsPerPage);

        $('html, body').animate({
            scrollTop: 0
        }, 800);
    };

    const withCategory = theCategory => {
        return `?category=${theCategory}&sort=${sort}&keyword=${searchKeyword}`;
    };

    const withSort = theSort => {
        return `?category=${category}&sort=${theSort}&keyword=${searchKeyword}`;
    };

    const generateCategoryLink = (title) => {
        const uri = withCategory(title);
        return (
            <NavLink to={uri} key={`cat-link-${Math.random() + Math.random()}`} className="dropdown-item" isActive={
                (match, location) => {
                    if (location.search === uri.replace(location.pathname, ''))
                        return true;

                    return false;
                }
            }>{title}</NavLink>
        );
    };

    const generateSortLink = (link, title) => {
        const uri = withSort(link);
        return (
            <NavLink to={uri} className="dropdown-item" isActive={
                (match, location) => {
                    if (location.search === uri)
                        return true;

                    return false;
                }
            }>{title}</NavLink>
        );
    };

    const handleSearchKeywordChanged = e => {
        setSearchKeyword(e.target.value ? e.target.value : '');
    };

    const searchPhotos = e => {
        e.preventDefault();
        
        setIsLoading(true);
        getPhotosCount(category, sort, searchKeyword);
    };

    const onCardClicked = (currentIndex) => {
        if (products.length > 0) {
            utils.showProductsModal(products, currentIndex,
                doDownloadAlt, collectProduct, followPhotographer);
        }
    };

    const addProductToCart = (product) => {
        if (!isInCart(product)) {
            utils.verifyUserSession(authenticateUser, 
                () => {
                    utils.verifyProductInCollection(product,
                        () => {
                            utils.showInfo('This product is already in your collection!', '', 3000);
                        },
                        () => {
                            addToCart(product);
                            utils.showAddedToCart();
                        });
                },
                () => {
                    ReactSwal.fire({
                        title: 'You need to login first!',
                        icon: 'info',
                        showConfirmButton: false,
                        html: <utils.AuthComponent onLogin={redirectToLogin} 
                            onCreateAccount={redirectToCreateAccount}/>
                    });
                });
        }
        else {
            utils.showAlreadyInCart();
        }
    };

    const downloadProduct = (product) => {
        utils.verifyUserSession(authenticateUser, 
            () => {
                utils.initiatePurchase(product, 
                    (collection_id) => {
                        doDownload(product, collection_id);
                    },
                    (collection_id) => {
                        doDownload(product, collection_id);
                    });
            },
            () => {
                ReactSwal.fire({
                    title: 'You need to login first!',
                    icon: 'info',
                    showConfirmButton: false,
                    html: <utils.AuthComponent onLogin={redirectToLogin} 
                        onCreateAccount={redirectToCreateAccount}/>
                });
            });
    };

    const doDownload = (product, collection_id, width = 0, height = 0) => {
        const img = product.img_url;
        const filename = product.name + img.substring(img.indexOf('.'), img.length);
        utils.download(collection_id, filename, width, height);

        // Remove this product from cart if it is there
        removeFromCart(product);
    };

    const doDownloadAlt = (product, width, height) => {
        utils.verifyUserSession(authenticateUser, 
            () => {
                utils.initiatePurchase(product, 
                    (collection_id) => {
                        doDownload(product, collection_id, width, height);
                    },
                    (collection_id) => {
                        doDownload(product, collection_id, width, height);
                    });
            },
            () => {
                ReactSwal.fire({
                    title: 'You need to login first!',
                    icon: 'info',
                    showConfirmButton: false,
                    html: <utils.AuthComponent onLogin={redirectToLogin} 
                        onCreateAccount={redirectToCreateAccount}/>
                });
            });
    };

    const collectProduct = (product) => {
        utils.verifyUserSession(authenticateUser, 
            () => {
                utils.initiatePurchase(product, 
                    (collection_id) => {
                        utils.showSuccess('Photo added to collection!', '', 3000);

                        // Remove this product from cart if it is there
                        removeFromCart(product);
                    },
                    (collection_id) => {
                        utils.showInfo('This photo is already in your collection!', '', 3000);
                    });
            },
            () => {
                ReactSwal.fire({
                    title: 'You need to login first!',
                    icon: 'info',
                    showConfirmButton: false,
                    html: <utils.AuthComponent onLogin={redirectToLogin} 
                        onCreateAccount={redirectToCreateAccount}/>
                });
            });
    };

    const followPhotographer = (photographer_username) => {
        utils.verifyUserSession(authenticateUser, 
            () => {
                utils.followPhotographer(photographer_username,
                    () => {
                        utils.showSuccess('You are now following ' + photographer_username, '', 3000);
                    },
                    () => {
                        utils.showInfo('You are already following ' + photographer_username, '', 3000);
                    });
            },
            () => {
                ReactSwal.fire({
                    title: 'You need to login first!',
                    icon: 'info',
                    showConfirmButton: false,
                    html: <utils.AuthComponent onLogin={redirectToLogin} 
                        onCreateAccount={redirectToCreateAccount}/>
                });
            });
    };

    const viewPhotographer = (username) => {
        history.push('/@' + username);
    };

    const redirectToLogin = () => {
        ReactSwal.close();
        history.push('/user/login?redirect=/shop');
    };

    const redirectToCreateAccount = () => {
        ReactSwal.close();
        history.push('/user/register');
    };

    const isInCart = (product) => {
        for (let i = 0; i < cart.length; i++) {
            const p = cart[i];
            if (p.name === product.name)
                return true;
        }

        return false;
    }

    return (
        <div className="shop-container">
            <ScrollToTopOnMount />
            <div>
                <div className="jumbotron p-4 custom-bg-light">
                    <div className="filter-container container mx-auto">
                        <div className="row">
                            <div className="col-sm">
                                <div className="dropdown w-100">
                                        <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                            Category <span className="fas fa-chevron-down float-right"></span>
                                        </a>

                                        <div className="dropdown-menu roboto-font">
                                            {generateCategoryLink('All')}
                                            {!categories ? '' : categories.map((category) => generateCategoryLink(category.category))}
                                        </div>
                                    </div>
                            </div>

                            <div className="col-sm mt-4 mt-md-0">
                                <div className="dropdown w-100">
                                    <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                        Sort <span className="fas fa-chevron-down float-right"></span>
                                    </a>

                                    <div className="dropdown-menu roboto-font">
                                        {generateSortLink('featured', 'Featured')}
                                        {generateSortLink('price-ascending', 'Price, low to high')}
                                        {generateSortLink('price-descending', 'Price, high to low')}
                                        {generateSortLink('ascending', 'A - Z')}
                                        {generateSortLink('descending', 'Z - A')}
                                        {generateSortLink('date-descending', 'Date, new to old')}
                                        {generateSortLink('date-ascending', 'Date, old to new')}
                                        {generateSortLink('popular', 'Popular')}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <form onSubmit={searchPhotos}>
                            <div className="row search-bar">
                                <div className="col">
                                    <div className="input-group">
                                        <input type="search" className="form-control bg-light shadow-lg" placeholder="Search your photos"
                                            onChange={handleSearchKeywordChanged}/>
                                        <button type="submit" className="btn btn-dark"><span className="fas fa-search"></span></button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="my-5 px-md-3">
                    {isLoading ? <Loading /> : <PhotosGrid onCardClicked={onCardClicked}  
                        eventHandlers={{'addToCart': addProductToCart, 'download': downloadProduct, 
                            'viewPhotographer': viewPhotographer}} key="shop-grid" products={products}/>}
                    { (isLoading || productsCount === 0 || productsCount <= productsPerPage) ? '' :  
                        generatePagination(Math.ceil(productsCount / productsPerPage), paginatorClicked,
                            2, 2, <span className="fas fa-chevron-left"></span>, <span className="fas fa-chevron-right"></span>,
                            currentPage)
                    }
                </div>
            </div> 
        </div>
    );
};

class PhotosGrid extends Component {
    constructor(props) {
        super(props);

        this.state = {transition: false};
    }

    componentDidMount() {
        this.setState({transition: true})
    }

    render() {
        const products = this.props.products;
        if (!products || products.length === 0)
            return <h2 className="m-4 roboto-font">No photos found!</h2>;

        const perRow = 3;

        let remainder = products.length % perRow;

        let makeCards;

        if (products.length < perRow)
        {
            makeCards = () => {
                let cols = [];
                
                for (let i = 0; i < products.length; i++) {
                    const product = products[i];
                    cols.push(<ImageGridCol key={`prod-col-${i}`}><PhotoCard index={i} 
                        onCardClicked={this.props.onCardClicked} product={product} 
                            {...this.props.eventHandlers}/></ImageGridCol>)
                }

                return cols;
            };
        }
        else if (remainder === 0)
        {
            makeCards = () => {
                let cols = [[], [], []];
                
                for (let i = 0; i < products.length; i += perRow) {
                    
                    for (let j = 0; j < perRow; j++) {
                        const product = products[i+j];
                        cols[j].push(<PhotoCard index={i+j} 
                            onCardClicked={this.props.onCardClicked} product={product} {...this.props.eventHandlers}
                            key={`prod-${i+j}`}/>);
                    }
                }

                let rows = [];
                for (let i = 0; i < perRow; i++)
                    rows.push(<ImageGridCol key={`prod-col-${i}`}>{cols[i]}</ImageGridCol>);

                return rows;
            };
        }
        else
        {
            makeCards = () => {
                let cols = [[], [], []];
                const evenSize = products.length - remainder;
                
                for (let i = 0; i < evenSize; i += perRow) {
                    for (let j = 0; j < perRow; j++) {
                        const product = products[i+j];
                        cols[j].push(<PhotoCard index={i+j} 
                            onCardClicked={this.props.onCardClicked} product={product} {...this.props.eventHandlers}
                            key={`prod-${i+j}`}/>);
                    }
                }

                let rows = [];
                for (let i = 0; i < perRow; i++)
                    rows.push(<ImageGridCol key={`prod-col-${i}`}>{cols[i]}</ImageGridCol>);

                for (let i = evenSize; i < products.length; i++) {
                    const product = products[i];
                    rows.push(<ImageGridCol key={`prod-col-${i}`}><PhotoCard index={i} 
                        onCardClicked={this.props.onCardClicked} product={product} 
                        {...this.props.eventHandlers} /></ImageGridCol>)
                }

                return rows;
            };
        }

        return (
            <CSSTransition
                in={this.state.transition}
                timeout={1100}
                classNames="products-grid-transition">
                <ImageGridRow>{makeCards()}</ImageGridRow>
            </CSSTransition>
        )
    }
}

const PhotoCard = (props) => {
    const handleClick = (e) => {
        props.onCardClicked(props.index);

        // Let the backend know that this product was viewed
        productViewed(props.product);
    };

    const onAddTocart = (e) => {
        e.preventDefault();
        if (props.addToCart)
            props.addToCart(props.product);
    };

    const onDownload = (e) => {
        e.preventDefault();
        if (props.download)
            props.download(props.product);
    };

    const onViewPhotographer = (e) => {
        e.preventDefault();
        if (props.viewPhotographer)
            props.viewPhotographer(props.product.photographer_username);
    };

    return (
        <div className="card product-card p-0">
            <img src={props.product.img_url} className="card-img-top image" alt="Product" />

            <div className="card-img-overlay text-center">
                <div className="w-100 h-100" onClick={handleClick}></div>
                <div className="options">
                    <a href="/" className="float-left photographer-link" onClick={onViewPhotographer}>
                        <img src={props.product.photographer_avatar} alt="Photographer" className="float-left" style={{maxHeight: '50px'}}/>
                    </a>
                    <span className="actions float-right text-white">
                        <a onClick={onDownload}><span className="fas fa-download align-bottom"></span></a>
                        <a onClick={onAddTocart}><span className="fas fa-shopping-basket ml-3 align-bottom"></span></a>
                    </span>
                </div>
            </div>
        </div>
    );
}

/* Map States and Dispatchs To Props */
const getPhotosCount = creators.shop.getPhotosCount;
const getPhotos = creators.shop.getPhotos;
const getCategories = creators.misc.getCategories;
const addToCart = creators.app.addToCart;
const removeFromCart = creators.app.removeFromCart;
const authenticateUser = creators.app.authenticate;

const mapStateToProps = (state) => {
    return {
        product_categories: [...state.miscReducer.categories],
        photos: {...state.shopReducer.photos},
        photosCount: {...state.shopReducer.photosCount},
        cart: [...state.appReducer.cart],
    };
};

const mapDispatchToProps = {
    getPhotosCount, getPhotos, getCategories, addToCart, removeFromCart, authenticateUser
};

export default connect(mapStateToProps, mapDispatchToProps)(Shop)
